import { Link } from "react-router-dom";
import styles from "./MultiImageBanner.module.scss";
const MultiImageBanner = (props: {
  title: string;
  sections: {
    src: string;
    to: string;
    title: string;
    secondaryTitle: string;
  }[];
}) => {
  return (
    <section className={styles["container"]}>
      <h3> {props.title}</h3>
      <div className={styles["row"]}>
        {props.sections.map((section, index) => (
          <Link to={section.to} key={index}>
            <div className={styles["card"]}>
              <div className={styles["imageHolder"]}>
                <img src={section.src} alt=""></img>
              </div>
              <h4>{section.title}</h4>
              <h5>{section.secondaryTitle}</h5>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default MultiImageBanner;
