import { gql } from "@shane32/graphql";
import { ICheckoutCart } from "./CheckoutMutation";
import { IPaymentType } from "./AddPaymentMutation";

export const CheckoutExternalPaymentMutation = gql`
  mutation (
    $email: String
    $nonce: String!
    $shippingAddress: AddressInputGraph!
    $billingAddress: AddressInputGraph!
    $cart: ShoppingCartInputGraph!
    $deviceData: String!
    $paymentType: PaymentTypeEnum!
    $captchaResponse: String
  ) {
    v1 {
      cart {
        placeOrderExternalPayment(
          email: $email
          nonce: $nonce
          shippingAddress: $shippingAddress
          billingAddress: $billingAddress
          cart: $cart
          deviceData: $deviceData
          paymentType: $paymentType
          captchaResponse: $captchaResponse
        )
      }
    }
  }
`;

export interface ICheckoutExternalPaymentMutationResult {
  v1: {
    cart: {
      placeOrderExternalPayment: string;
    };
  };
}

export interface ICheckoutExternalPaymentMutationVariables {
  cart: ICheckoutCart;
  email?: string;
  shippingAddress: ICheckoutAddress;
  billingAddress: ICheckoutAddress;
  nonce: string;
  deviceData: string;
  paymentType: IPaymentType;
  captchaResponse: string | null;
}

export interface ICheckoutAddress {
  countryId: string;
  firstName: string;
  lastName: string;
  company?: string | null;
  address1: string;
  address2?: string | null;
  city: string;
  stateProvinceId?: string | null;
  zipPostalCode: string;
  phoneNumber: string;
  emailAddress?: string | null;
}
