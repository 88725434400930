import { gql } from "@shane32/graphql";
import { ShoppingCartPartialQuery, IShoppingCart } from "../queries/ShoppingCartQuery";

export const CartSetDestinationMutation = gql`mutation($countryId:ID!, $stateProvinceId:ID, $postalCode:String) {
  v1 {
    cart {
      setDestination(countryId: $countryId, stateProvinceId: $stateProvinceId, postalCode: $postalCode) ${ShoppingCartPartialQuery()}
    }
  }
}`;

export interface ICartSetDestinationResult {
  v1: {
    cart: {
      setDestination: IShoppingCart;
    };
  };
}

export interface ICartSetDestinationVariables {
  countryId: string;
  stateProvinceId: string | null;
  postalCode: string | null;
}
