import styles from "./SideBarPopOut.module.scss";

const SideBarPopOut = (props: { open: boolean; close: () => void; children: React.ReactNode; onlyMobile?: boolean }) => {
  return (
    <div
      className={
        (props.onlyMobile && styles["mobileOnly"]) + " " + (props.open ? styles["slide-Out"] : styles["slide-In"]) + " " + styles["sideBar"]
      }
    >
      <button onClick={props.close} className={styles["close-icon-container"]}>
        <div className={styles["close-icon"]}></div>
      </button>
      <div className={styles["body"]}>{props.children}</div>
    </div>
  );
};

export default SideBarPopOut;
