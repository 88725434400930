import { gql } from "@shane32/graphql";

// using non-authenticated link

export const ProductPageReviewsQuery = gql`
  query ($productId: ID) {
    v1 {
      product(id: $productId) {
        reviews {
          totalCount
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          items {
            createdOnUtc
            helpfulNoTotal
            helpfulYesTotal
            id
            rating
            replyText
            reviewText
            title
          }
        }
      }
    }
  }
`;

export interface IProductPageReviewsQuery {
  v1: {
    product: {
      reviews: IReviews;
    } | null;
  };
}

interface IReviews {
  totalCount: number;
  pageInfo: IPageInfo;
  items: IReview[];
}

interface IPageInfo {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor: string;
  endCursor: string;
}

export interface IReview {
  createdOnUtc: string;
  helpfulNoTotal: number;
  helpfulYesTotal: number;
  id: string;
  rating: number;
  replyText: string;
  reviewText: string;
  title: string;
}

export interface IProductPageReviewsQueryVariables {
  productId: string;
}
