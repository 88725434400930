import * as React from "react";
import GlobalContext, { IHeaderType } from "../contexts/GlobalContext";

let priority: number = 0;
const useHeader = (headerType: IHeaderType) => {
  const globalContext = React.useContext(GlobalContext);
  const localPriority = React.useMemo(() => priority++, []);
  React.useEffect(() => {
    return globalContext.setHeaderType(headerType, localPriority);
  }, [headerType]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useHeader;
