import { gql } from "@shane32/graphql";

export const SetMachinePictureMutation = gql`
  mutation ($garageId: ID!, $url: String, $base64PictureData: String, $mimeType: String) {
    v1 {
      garage {
        setPicture(garageId: $garageId, url: $url, base64PictureData: $base64PictureData, mimeType: $mimeType) {
          id
          picture {
            id
            imageUrl
            mimeType
            seoFilename
            size
            thumbnail(size: 100) {
              size
              url
            }
          }
        }
      }
    }
  }
`;

export interface ISetMachinePictureResult {
  v1: {
    garage: {
      setPicture: ISetMachinePictureMutationInterface;
    };
  };
}

export interface ISetMachinePictureMutationInterface {
  id: string;
  picture: IMachinePicture;
}

export interface IMachinePicture {
  id: string;
  imageUrl: string;
  mimeType: string;
  seoFilename: string;
  size: number;
  thumbnail: {
    size: string;
    url: string;
  };
}

export interface ISetMachinePictureMutationVariables {
  garageId: string;
  url: string | null;
  base64PictureData: string | null;
  mimeType: string | null;
}
