import { useMutation } from "@shane32/graphql";
import { IAddMachineMutationResult, IAddMachineMutationVariables, AddMachineMutation } from "./AddMachineMutation";
import { ICheckFitmentMutationResult, ICheckFitmentMutationVariables, CheckFitmentMutation } from "./CheckFitment";
import { ClearMachineMutation, IClearMachineMutationResult, IClearMachineMutationVariables } from "./ClearMachinePictureMutation";
import { IDeleteMachineMutationResult, IDeleteMachineMutationVariables, DeleteMachineMutation } from "./DeleteMachineMutation";
import { IEditMachineMutationResult, IEditMachineMutationVariables, EditMachineMutation } from "./EditMachineMutation";
import { ISetMachinePictureMutationVariables, ISetMachinePictureResult, SetMachinePictureMutation } from "./SetMachinePictureMutation";
import { ISetMachineMutationResult, ISetMachineMutationVariables, SetMachineMutation } from "./SetSelectedMachineMutation";

export const FleetMutations = {
  useAddMachine: function () {
    return useMutation<IAddMachineMutationResult, IAddMachineMutationVariables>(AddMachineMutation);
  },

  useEditMachine: function () {
    return useMutation<IEditMachineMutationResult, IEditMachineMutationVariables>(EditMachineMutation);
  },

  useDeleteMachine: function () {
    return useMutation<IDeleteMachineMutationResult, IDeleteMachineMutationVariables>(DeleteMachineMutation);
  },

  useSetSelectedMachine: function () {
    return useMutation<ISetMachineMutationResult, ISetMachineMutationVariables>(SetMachineMutation);
  },

  useSetMachinePicture: function () {
    return useMutation<ISetMachinePictureResult, ISetMachinePictureMutationVariables>(SetMachinePictureMutation);
  },

  useClearMachinePicture: function () {
    return useMutation<IClearMachineMutationResult, IClearMachineMutationVariables>(ClearMachineMutation);
  },

  useFitmentCheck: function () {
    return useMutation<ICheckFitmentMutationResult, ICheckFitmentMutationVariables>(CheckFitmentMutation);
  },
};
