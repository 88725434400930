import { gql } from "@shane32/graphql";

export const ClearMachineMutation = gql`
  mutation ($garageId: ID!) {
    v1 {
      garage {
        clearPicture(garageId: $garageId) {
          id
          name
          picture {
            id
          }
        }
      }
    }
  }
`;

export interface IClearMachineMutationResult {
  v1: {
    garage: {
      clearPicture: IClearPicture | null;
    };
  };
}

export interface IClearPicture {
  id: string;
  name: string;
  picture: {
    id: string;
  };
}

export interface IClearMachineMutationVariables {
  garageId: string;
}
