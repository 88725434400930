import { gql, useQuery } from "@shane32/graphql";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ISeenProducts } from "../../pages/productpage/ProductController";
import { ISearchFilter } from "../../queries/SearchFilters";
import styles from "./RecentlyViewed.module.scss";
interface IProducts {
  v1: {
    searchResults: {
      productsConnection: {
        items: {
          id: string;
          seoName: string;
          picture: {
            imageUrl: string;
          };
        }[];
      };
    };
  };
}

const Query = gql`
  query ($filters: [IdAndValuesGraph!]) {
    v1 {
      searchResults(filters: $filters) {
        productsConnection {
          items {
            id
            name
            seoName
            picture {
              imageUrl
            }
          }
        }
      }
    }
  }
`;
const RecentlyViewed = () => {
  const pagesSeenSTR = window.localStorage.getItem("pagesSeen");
  const { width } = useWindowDimensions();
  const useFive = width > 970;
  var pagesSeen: ISeenProducts[] = [];
  if (pagesSeenSTR) {
    pagesSeen = JSON.parse(pagesSeenSTR);
  }
  const vars = {
    filters: [{ id: "id", values: pagesSeen.map((x) => x.id) }],
  };
  //make graphql request using the ids sort by date

  // const { data, error, errors, loading } = useQuery<IProducts, ISearchFilter>(Query,
  const { data } = useQuery<IProducts, ISearchFilter>(Query, {
    variables: vars,
  });

  const items = data?.v1.searchResults.productsConnection.items;

  return (
    //create element regardless of data
    items && items.length > 0 ? (
      <div className={styles["container"]}>
        <div className={styles["body"]}>
          <h2>Recently Viewed</h2>
          <div className={styles["productHolder"] + " " + (useFive ? styles["useFive"] : "")}>
            {items
              .filter((x) => x.picture?.imageUrl) //only use products with images
              .sort(
                (
                  x,
                  a //sort by date descending
                ) =>
                  new Date(pagesSeen.filter((t) => t.id === a.id)[0].date).getTime() -
                  new Date(pagesSeen.filter((t) => t.id === x.id)[0].date).getTime()
              )
              .slice(0, useFive ? 5 : 4)
              .map((x, i) => (
                <Link key={i} to={GenerateLink.ForProduct(x.id, x.seoName)}>
                  <img src={x.picture.imageUrl} alt={""} />
                </Link>
              ))}
          </div>
        </div>
      </div>
    ) : (
      <div className={styles["container"] + " " + styles["hide"]}>
        <div className={styles["body"]}>
          <h2>b</h2>
          <div className={styles["productHolder"]}></div>
        </div>
      </div>
    )
  );
};

export default RecentlyViewed;
