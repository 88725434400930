import React, { useContext, useState } from "react";
import { useCurrentRefinements, useNumericMenu, UseNumericMenuProps, useRefinementList } from "react-instantsearch-hooks-web";
import Radio from "../../components/inputs/Radio";
import ToolTip from "../../components/tooltip/ToolTip";
import { FleetContext } from "../../contexts/FleetContext";
import { MachineName } from "../../queries/FleetQuery";
import styles from "./Filters.module.scss";

const AlgoliaSearchFilters = () => {
  return (
    <div className="pl-10 pr-20">
      <Filter name="Machine">
        <CurrentFitmentRefinements />
        <FleetRefinementListSearchable />
      </Filter>
      {/* <SearchableCheckBoxFilter Title="Belt Type" Attribute="type" AttributeImage={{ "Cogged" : true}} /> */}
      <SearchableCheckBoxFilter Title="Brand" Attribute="brand" />
      <SearchableCheckBoxFilter Title="Belt Type" Attribute="type" />
      <SearchableCheckBoxFilter Title="Width" Attribute="widthAttribute" Image="width" />
      <SearchableCheckBoxFilter Title="Thickness" Attribute="thickness" Image="thickness" />
      <SearchableCheckBoxFilter Title="Length" Attribute="lengthAttribute" Image="length" />
      <SearchableCheckBoxFilter
        Title="Trade Size"
        Attribute="tradeSize"
        Image="beltTradeSize"
        toolTip="Belt Trade Size # is often printed on the outside of the belt."
      />
      <SearchableCheckBoxFilter Title="Material" Attribute="material" />
      <SearchableCheckBoxFilter Title="Number of Bands" Attribute="numberOfBands" Image="numberofbands" />
      <SearchableCheckBoxFilter Title="Application" Attribute="application" />
      <SearchableCheckBoxFilter Title="Color" Attribute="color" />

      <Filter name="Price">
        <NumericMenu
          attribute="price"
          items={[
            { label: "All" },
            { label: "Less than $200", end: 200 },
            { label: "Between $200 - $500", start: 200, end: 500 },
            { label: "More than $500", start: 500 },
          ]}
        />
      </Filter>
      {/* ADD BACK IN ONCE API IS SET UP for multi index attribute search  */}
      {/* <Filter name="Sort By">
                <SortBy
                    classNames={{
                        root: `${selectStyles.container} mb-16`,
                        select: `${selectStyles.select} form-control`,
                    }}
                    items={[
                        {
                            label: "feature",
                            value:
                                process.env.REACT_APP_ALGOLIA_MAIN_INDEX ?? "",
                        },
                        {
                            label: "price (desc)",
                            value:
                                process.env
                                    .REACT_APP_ALGOLIA_PRICE_DESC_INDEX ?? "",
                        },
                        {
                            label: "price (asc)",
                            value:
                                process.env.REACT_APP_ALGOLIA_PRICE_ASC_INDEX ??
                                "",
                        },
                        {
                            label: "A - Z",
                            value:
                                process.env.REACT_APP_ALGOLIA_TITLE_AZ_INDEX ??
                                "",
                        },
                        {
                            label: "Z - A",
                            value:
                                process.env.REACT_APP_ALGOLIA_TITLE_ZA_INDEX ??
                                "",
                        },
                    ]}
                />
            </Filter> */}
    </div>
  );
};

const SearchableCheckBoxFilter = (props: {
  Title: string;
  Attribute: string;
  AttributeImage?: { [key: string]: boolean };
  Image?: string;
  toolTip?: string;
}) => {
  const max = 100;
  const scrollStart = 8;
  const [search, setsearch] = useState("");

  const { items, refine, searchForItems, canRefine, canToggleShowMore, isShowingMore, toggleShowMore } = useRefinementList({
    attribute: props.Attribute,
    limit: max,
    showMoreLimit: 500,
    showMore: true,
    sortBy: ["name:asc"],
  });

  const onClick = (val: string) => {
    refine(val);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    var val = e.target.value;
    if (val) {
      searchForItems(val);
      setsearch(val);
    } else {
      searchForItems("");
      setsearch("");
    }
  };

  // if(!canRefine && search.trim() === "") return null;
  return (
    <div className={styles["parent"] + " " + (!canRefine && search.trim() === "" ? styles["hide"] : "")}>
      <input name={props.Attribute} id={props.Attribute} type="checkbox" />
      <label htmlFor={props.Attribute} className={styles["title"]}>
        <div>
          {props.Title}
          {props.toolTip && (
            <ToolTip message={props.toolTip}>
              <div className="image-help ml-5" />
            </ToolTip>
          )}
        </div>
        <div className={styles["Carrot"]} />
      </label>

      <div className={styles["search-refinements"]}>
        {props.Image && <div className={styles["filterImage"] + " " + styles[props.Image]} />}
        {search || canToggleShowMore || items.length > scrollStart ? (
          <input placeholder={`search ${props.Title}`} className="form-control mb-10 mt-10" onChange={onSearch} />
        ) : null}
        {items.length > 0 ? (
          <div className={styles["scroll-container"]}>
            <ul className={styles["search-refinements-style"] + " " + styles["scrollable"]}>
              {/* {items
                                .filter((x) => x.isRefined)
                                .map((x, i) => (
                                    <FilerItem2
                                        key={i}
                                        value={x.value}
                                        onClick={onClick}
                                        attributeImage={props.AttributeImage}
                                        isRefined={x.isRefined}
                                    />
                                ))} */}
              {items.map((x, i) => (
                <FilerItem2 key={i} value={x.value} onClick={onClick} attributeImage={props.AttributeImage} isRefined={x.isRefined} />
              ))}
              {canToggleShowMore && (
                <button className={styles["show-more-button"]} onClick={toggleShowMore}>
                  {isShowingMore ? "Show Less" : "Show More"}
                </button>
              )}
            </ul>
          </div>
        ) : (
          <div>No Results</div>
        )}
      </div>
    </div>
  );
};

const FilerItem2 = (x: {
  key: any;
  onClick: (val: string) => void;
  attributeImage?: { [key: string]: boolean };
  isRefined: boolean;
  value: string;
}) => {
  return (
    <li key={x.key} className={`${styles["search-refinements-item"]} ${x.isRefined ? styles["search-refinements-selected"] : ""}`}>
      <label className={styles["search-refinements-label"]}>
        <input
          className={styles["search-refinements-checkbox"]}
          checked={x.isRefined}
          onChange={() => x.onClick(x.value)}
          type="checkbox"
          value={x.value}
        />
        <span className={styles["search-refinements-labeltext"]}> {x.value} </span>
        {x.attributeImage != null && x.attributeImage[x.value] ? (
          <div className={styles["attributeImage"] + " " + styles[x.value]}></div>
        ) : null}
      </label>
    </li>
  );
};

const CurrentFitmentRefinements = () => {
  const fleetContext = useContext(FleetContext);
  const { items, refine: removeRefine } = useCurrentRefinements();
  const { refine } = useRefinementList({
    operator: "or",
    sortBy: ["isRefined:desc"],
    attribute: "fitment",
  });
  const selected = MachineName(fleetContext.selectedMachine);
  const selItem = fleetContext.selectedMachine
    ? items["fitment"]
      ? items["fitment"].refinements.filter((x: any) => x.label === selected)
      : null
    : null;
  const fitmnetList = items.filter((x) => x.attribute === "fitment")[0];

  return (
    <>
      {fleetContext.fleet && (
        <>
          {fleetContext.fleet?.map((x, i) => {
            const label = MachineName(x);
            const filteringByItem = fitmnetList?.refinements.filter((x) => x.label === label);
            const filteringBy = filteringByItem?.length > 0;
            return filteringBy ? null : (
              <FilterItem
                Key={i + label}
                OnClick={(e: any) => {
                  refine(label);
                }}
                Label={label}
                Checked={filteringBy ? true : false}
                Count={filteringByItem ? filteringByItem[0]?.count : null}
              />
            );
          })}
        </>
      )}
      {fitmnetList?.refinements && (
        <>
          {selItem && <FilterItem Label={selItem.label} Count={selItem.count} Key="-2" OnClick={() => {}} Checked={true} />}
          {fitmnetList.refinements.map((x, i: any) => {
            return (
              <FilterItem
                Key={i + x.label}
                OnClick={(e: any) => {
                  removeRefine(x);
                }}
                Label={x.label}
                Checked={true}
                Count={x.count}
              />
            );
          })}
        </>
      )}
    </>
  );
};

const FleetRefinementListSearchable = () => {
  const { items, refine, searchForItems } = useRefinementList({
    operator: "or",
    sortBy: ["isRefined:desc"],
    attribute: "fitment",
  });
  const fleetContext = useContext(FleetContext);

  return (
    <ul className={styles["search-refinements-style"]}>
      <input placeholder="search machines" className="form-control mb-10 mt-10" onChange={(e) => searchForItems(e.target.value)}></input>
      {items
        .filter((x) => !x.isRefined && x.label !== MachineName(fleetContext.selectedMachine))
        .map((x: any, i: any) => {
          const key = i + x.label;
          return (
            <FilterItem
              Key={key}
              OnClick={(e: any) => {
                refine(x.label);
              }}
              Label={x.label}
              Checked={x.isRefined}
              Count={x.count}
            />
          );
        })}
    </ul>
  );
};

function FilterItem(props: { OnClick: (e: any) => void; Count?: any; Label: string; Checked: boolean; Key: string }) {
  return (
    <li
      key={props.Key}
      className={styles["search-refinements-item"] + " " + (props.Checked ? styles["search-refinements-selected"] : null)}
    >
      <label className={styles["search-refinements-label"]}>
        <input
          onChange={() => {}}
          className={styles["search-refinements-checkbox"]}
          checked={props.Checked}
          value={props.Label}
          onClick={props.OnClick}
        />
        <span className={styles["search-refinements-labeltext"]}>{props.Label}</span>
        {props.Count && props.Count.length !== 0 ? <span className={styles["search-refinements-count"]}>{props.Count}</span> : null}
      </label>
    </li>
  );
}

function NumericMenu(props: UseNumericMenuProps) {
  const { items, refine } = useNumericMenu(props);
  return (
    <ul className={styles["search-refinements-style"]}>
      {items.map((item: any, i: any) => (
        <Radio name="" value={item.value} checked={item.isRefined} key={i} onChange={() => refine(item.value)}>
          {item.label}
        </Radio>
      ))}
    </ul>
  );
}

function Filter({ children, name }: React.PropsWithChildren<{ name?: React.ReactNode }>) {
  return (
    <div>
      {name && <h3>{name}</h3>}
      <div className={styles["search-refinements"]}>{children}</div>
    </div>
  );
}

export default AlgoliaSearchFilters;
