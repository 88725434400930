import { gql } from "@shane32/graphql";

export const SetDefaultAddressMutation = gql`
  mutation ($addressId: ID!) {
    v1 {
      shippingAddress {
        setDefault(id: $addressId)
      }
    }
  }
`;

export interface ISetDefaultAddressMutationResult {
  v1: {
    shippingAddress: {
      setDefault: boolean;
    };
  };
}

export interface ISetDefaultAddressMutationVariables {
  addressId: string;
}
