import { AuthContext } from "@zboxglobal/zboxauth";
import * as React from "react";
import Rounding from "../helpers/Rounding";
import { Mutations } from "../mutations/Mutations";
import Queries from "../queries/Queries";
import { IShoppingCartDestination, IShoppingCart as IShoppingCartFromQuery, IShoppingCartItem } from "../queries/ShoppingCartQuery";
import { IShoppingCartShippingMethod } from "../queries/ShoppingCartShippingMethodsQuery";
import useCartShippingHelper from "./helpers/CartShippingHelper";

//default cart context rejects all mutations and returns a null cart
export const CartContext = React.createContext<ICartContext>({
  client: {
    AddProduct: () => Promise.reject(),
    RemoveProduct: () => Promise.reject(),
    ChangeQuantity: () => Promise.reject(),
    MoveProductToCart: () => Promise.reject(),
    MoveProductToWishList: () => Promise.reject(),
    Refresh: () => Promise.reject(),
    SetDestination: () => Promise.reject(),
    SetLineReference: () => Promise.reject(),
    SetShippingMethod: () => Promise.reject(),
    SetDisplayReferences: () => {},
    SetIsLoading: () => {},
  },
  cart: null,
  loading: false,
});

export interface IShoppingCart {
  destination: IShoppingCartDestination;
  errors: string[];
  estimatedItemTaxRate: number | null;
  estimatedShippingTaxRate: number | null;
  itemCount: number;
  poNumber: string | null;
  warnings: string[];
  productGroups: IShoppingCartShipment[];
  savedForLaterItems: IShoppingCartItem[];
  subTotal: number | null;
  shippingTotal: number | null;
  taxTotal: number | null;
  grandTotal: number | null;
  canCheckout: boolean;
  displayReferences: boolean;
}

export interface IShoppingCartShipment {
  shippingProfileId: string;
  vendorId: string;
  warehouseId: string;
  backordered: boolean;
  items: IShoppingCartItem[];
  shippingMethods: IShoppingCartShippingMethod[] | null; // null means loading the shipping methods
  selectedMethod: IShoppingCartShippingMethod | null;
}

//after a mutation, isLoading will become false, while cartDataState will be set until the next refresh of the query
type ICartState = {
  isLoading: boolean;
  cartDataState: IShoppingCartFromQuery | null;
};

// CartContainer is used so our context has state and brains
export const CartContainer = (props: { children?: React.ReactNode }) => {
  const authContext = React.useContext(AuthContext);
  const shippingHelper = useCartShippingHelper();

  //state just to toggle showing reference fields
  const [displayReferences, setDisplayReferences] = React.useState<boolean>(false);

  //temporary cart state for preparing updated cart before it has finished loading
  //also stores if there is an inflight operation (query/mutation)
  const [cartState, setCartState] = React.useState<ICartState>({
    isLoading: false,
    cartDataState: null,
  });
  const setIsLoading = (value: boolean, cartDataState?: IShoppingCartFromQuery | null) => {
    setCartState((oldState) => {
      if (oldState.isLoading === value && cartDataState === undefined) return oldState; //don't update state
      return {
        isLoading: value,
        cartDataState: cartDataState === undefined ? cartState.cartDataState : cartDataState,
      };
    });
  };
  const setResult = (data?: IShoppingCartFromQuery | null | false) => {
    setCartState((oldState) => {
      if (!oldState.isLoading && !data) return oldState; //don't update state
      return {
        isLoading: false,
        cartDataState: data || cartState.cartDataState,
      };
    });
  };

  //retrieve the shopping cart
  const { loading: cartLoading, error: cartError, data: cartDataRaw, refetch: cartRefetch } = Queries.useQueryShoppingCart();
  const newCartData = (authContext.status.loggedIn && !cartLoading && !cartError && cartDataRaw?.v1.my?.shoppingCart) || null;
  const refresh = () => {
    setIsLoading(true);
    return cartRefetch()
      .catch(() => {})
      .then(() => {
        setIsLoading(false, null);
      });
  };

  //current cart before adding shipping methods
  const cartDataTemp = cartState.cartDataState || newCartData;

  //add shipping methods when done running ajax queries against the database
  const cartData: IShoppingCart | null = cartDataTemp
    ? {
        ...cartDataTemp,
        productGroups: cartDataTemp.productGroups.map<IShoppingCartShipment>((group) => ({
          ...group,
          shippingMethods: /* group.shippingMethods ? group.shippingMethods : */ shippingHelper.getShippingMethods(
            cartDataTemp.destination,
            group,
            cartState.isLoading
          ),
          selectedMethod: null,
          backordered: group.backordered,
        })),
        subTotal: null,
        shippingTotal: null,
        taxTotal: null,
        grandTotal: null,
        canCheckout: false,
        displayReferences: displayReferences,
      }
    : null;

  //set totals & canCheckout & selectedMethod
  if (cartData) {
    //set selected method
    for (let i = 0; i < cartData.productGroups.length; i++) {
      cartData.productGroups[i].selectedMethod = cartData.productGroups[i].shippingMethods?.filter((x) => x.selected)[0] || null;
    }

    //set order subtotal
    cartData.subTotal = 0;
    cartData.productGroups.forEach((products) => {
      products.items.forEach((item) => {
        if (item.product.priceAndAvailability.price === null || cartData.subTotal === null) cartData.subTotal = null;
        else cartData.subTotal += Rounding.EvenRound(item.product.priceAndAvailability.price * item.quantity, 2);
      });
    });

    //set shipping total
    cartData.shippingTotal = 0 as number | null;
    cartData.productGroups.forEach((products) => {
      if (products.selectedMethod && cartData.shippingTotal !== null) {
        cartData.shippingTotal += products.selectedMethod.price;
      } else {
        cartData.shippingTotal = null;
      }
    });

    cartData.shippingTotal = cartData.shippingTotal && Rounding.EvenRound(cartData.shippingTotal);

    //set tax total
    if (
      cartData.subTotal !== null &&
      cartData.shippingTotal !== null &&
      cartData.estimatedItemTaxRate !== null &&
      cartData.estimatedShippingTaxRate !== null
    ) {
      const taxRate = cartData.estimatedItemTaxRate;
      let taxTotal = 0;
      cartData.productGroups.forEach((products) => {
        products.items.forEach((item) => {
          if (!item.product.isTaxExempt && item.product.priceAndAvailability.price !== null)
            taxTotal += Rounding.EvenRound(Rounding.EvenRound(item.product.priceAndAvailability.price * item.quantity) * taxRate);
        });
      });
      cartData.taxTotal = taxTotal + Rounding.EvenRound(cartData.shippingTotal * cartData.estimatedShippingTaxRate);
    }

    //set grand total
    cartData.grandTotal =
      cartData.subTotal === null || cartData.shippingTotal === null || cartData.taxTotal === null
        ? null
        : cartData.subTotal + cartData.shippingTotal + cartData.taxTotal;

    //set canCheckout
    cartData.canCheckout = cartData.grandTotal !== null && cartData.grandTotal !== 0;
  }

  //get a handle to the mutations and shipping query
  const [mutationAddProduct] = Mutations.useAddProductToCartFromCatalog();
  const [mutationRemoveProduct] = Mutations.useRemoveProductFromCart();
  const [mutationMoveToCart] = Mutations.useMoveToCart();
  const [mutationSaveForLater] = Mutations.useSaveForLaterProduct();
  const [mutationSetDestination] = Mutations.useSetDestination();
  const [mutationChangeQuantity] = Mutations.useChangeQuantityInCart();
  const [mutationSetLineReference] = Mutations.useSetLineReferenceInCart();

  //=============publicly accessible members=============

  //use React.useCallback so that these references don't change every time
  //  (only works if the mutations provide stable callbacks)

  const addProduct = (productId: string, qty: number) => {
    //mark as loading
    setIsLoading(true);
    //call the mutation
    return mutationAddProduct({
      productId: productId,
      qty: qty,
      shoppingCartTypeId: "1",
    }).then(
      (data) => {
        //when the mutation completes, update the cart, and mark as loaded
        setResult(!data.errors && data.data?.v1.cart.add);
        return data;
        //then return a success to the caller
      },
      (ret) => {
        setIsLoading(false);
        console.log("Failed to add product to cart", ret);
        return Promise.reject("Failed to add product to cart");
      }
    );
  };

  //ditto for each of the other functions
  const removeProduct = (shoppingCartItemId: string, shoppingCartTypeId: string) => {
    //proactively remove the data from the cart
    if (cartData) {
      let newGroups = cartData.productGroups;
      let removedItems = 0;
      newGroups = [];
      for (let i = 0; i < cartData.productGroups.length; i++) {
        const group = cartData.productGroups[i];
        let newGroup: IShoppingCartShipment = { ...group };
        newGroup.items = [];
        for (let j = 0; j < group.items.length; j++) {
          if (group.items[j].id !== shoppingCartItemId) {
            newGroup.items.push(group.items[j]);
          } else {
            removedItems += group.items[j].quantity;
          }
        }
        if (newGroup.items.length > 0) newGroups.push(newGroup);
      }
      setIsLoading(true, {
        ...cartData,
        itemCount: cartData.itemCount - removedItems,
        productGroups: newGroups,
      });
    } else {
      setIsLoading(true);
    }
    return mutationRemoveProduct({
      shoppingCartItemId: shoppingCartItemId,
      shoppingCartTypeId: shoppingCartTypeId,
    }).then(
      (data) => {
        setResult(!data.errors && data.data?.v1.cart.remove);
      },
      (ret) => {
        console.log("Failed to remove product from cart", ret);
        setIsLoading(false, cartData);
        return Promise.reject("Failed to remove product from cart");
      }
    );
  };

  const moveProductToWishList = (shoppingCartItemId: string) => {
    setIsLoading(true);
    return mutationSaveForLater({
      shoppingCartItemId: shoppingCartItemId,
    }).then(
      (data) => {
        setResult(!data.errors && data.data?.v1.cart.saveForLater);
      },
      (ret) => {
        setIsLoading(false);
        console.log("Failed to move product to wish list", ret);
        return Promise.reject("Failed to move product to wish list");
      }
    );
  };

  const moveProductToCart = (shoppingCartItemId: string) => {
    setIsLoading(true);
    return mutationMoveToCart({
      shoppingCartItemId: shoppingCartItemId,
    }).then(
      (data) => {
        setResult(!data.errors && data.data?.v1.cart.moveToCart);
      },
      (ret) => {
        setIsLoading(false);
        console.log("Failed to move product to the cart", ret);
        return Promise.reject("Failed to move product to the cart");
      }
    );
  };

  const changeQuantity = (shoppingCartItemId: string, qty: number) => {
    //proactively change the quantity
    if (cartData) {
      let cartData2 = cartData;
      for (let i = 0; i < cartData2.productGroups.length; i++) {
        const group = cartData2.productGroups[i];
        for (let j = 0; j < group.items.length; j++) {
          if (group.items[j].id === shoppingCartItemId) {
            const newItem = { ...group.items[j], quantity: qty };
            const newItems = [...group.items];
            newItems[j] = newItem;
            const newGroup: IShoppingCartShipment = {
              ...group,
              items: newItems,
            };
            const newGroups = [...cartData2.productGroups];
            newGroups[i] = newGroup;
            cartData2 = {
              ...cartData2,
              productGroups: newGroups,
              itemCount: cartData.itemCount - group.items[j].quantity + newItem.quantity,
            };
          }
        }
      }
      setIsLoading(true, cartData2);
    } else {
      setIsLoading(true);
    }
    return mutationChangeQuantity({
      shoppingCartItemId: shoppingCartItemId,
      qty: qty,
      shoppingCartTypeId: "1",
    }).then(
      (data) => {
        setResult(!data.errors && data.data?.v1.cart.changeQuantity);
      },
      (ret) => {
        setIsLoading(false, cartData);
        console.log("Failed to change the quantity", ret);
        return Promise.reject("Failed to change the quantity");
      }
    );
  };

  const setLineReference = (productId: string, reference: string) => {
    //proactively change the references
    if (cartData) {
      let cartData2 = cartData;
      for (let i = 0; i < cartData2.productGroups.length; i++) {
        const group = cartData2.productGroups[i];
        for (let j = 0; j < group.items.length; j++) {
          if (group.items[j].product.id === productId) {
            const newItem = {
              ...group.items[j],
              reference: reference,
            };
            const newItems = [...group.items];
            newItems[j] = newItem;
            const newGroup = {
              ...group,
              items: newItems,
            };
            const newGroups = [...cartData2.productGroups];
            newGroups[i] = newGroup;
            cartData2 = {
              ...cartData2,
              productGroups: newGroups,
            };
          }
        }
      }
      setIsLoading(true, cartData2);
    } else {
      setIsLoading(true);
    }
    return mutationSetLineReference({
      productId: productId,
      reference: reference,
      shoppingCartTypeId: "1",
    }).then(
      (data) => {
        setResult(!data.errors && data.data?.v1.cart.setLineReference);
      },
      (ret) => {
        setIsLoading(false, cartData);
        console.log("Failed to change the references", ret);
        return Promise.reject("Failed to change the references");
      }
    );
  };

  const setDestination = (countryId: string, stateProvinceId?: string | null, postalCode?: string | null) => {
    //allow undefined or null values to be passed in, but coerce to null
    stateProvinceId = stateProvinceId || null;
    //city = city || null;
    postalCode = postalCode || null;
    //proactively set the destination
    if (cartData) {
      setIsLoading(true, {
        ...cartData,
        destination: {
          city: null,
          countryId: countryId,
          provinceId: stateProvinceId,
          postalCode: postalCode,
        },
        estimatedItemTaxRate: null,
        estimatedShippingTaxRate: null,
      });
    } else {
      setIsLoading(true);
    }
    return mutationSetDestination({
      countryId: countryId,
      stateProvinceId: stateProvinceId,
      postalCode: postalCode,
    }).then(
      (data) => {
        setResult(!data.errors && data.data?.v1.cart.setDestination);
      },
      (ret) => {
        setIsLoading(false, cartData);
        console.log("Failed to set the destination", ret);
        // ignore errors
        //return Promise.reject("Failed to set the destination");
      }
    );
  };

  const setShippingMethod = (group: IShoppingCartShipment, methodId: string) => {
    if (!cartData) return Promise.reject();
    setIsLoading(true);
    return shippingHelper.setShippingMethod(cartData.destination, group, methodId).then(
      () => {
        setIsLoading(false);
      },
      (ret) => {
        setIsLoading(false);
        console.log("Failed to set the shipping method", ret);
        // ignore errors
        //return Promise.reject("Failed to set the shipping method");
      }
    );
  };

  //Refresh cart upon signing in or signing out
  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.status.loggedIn, authContext.status.isGuest, authContext.status.userInfo?.id]);

  const contextState: ICartContext = {
    client: {
      AddProduct: addProduct,
      RemoveProduct: removeProduct,
      ChangeQuantity: changeQuantity,
      MoveProductToCart: moveProductToCart,
      MoveProductToWishList: moveProductToWishList,
      Refresh: refresh,
      SetDestination: setDestination,
      SetLineReference: setLineReference,
      SetShippingMethod: setShippingMethod,
      SetDisplayReferences: setDisplayReferences,
      SetIsLoading: setIsLoading,
    },
    cart: cartData,
    loading: cartState.isLoading,
  };

  //set the context and continue
  return <CartContext.Provider value={contextState}>{props.children}</CartContext.Provider>;
};

export interface ICartClient {
  AddProduct: (productId: string, qty: number) => Promise<any>;
  RemoveProduct: (shoppingCartItemId: string, shoppingCartTypeId: string) => Promise<any>;
  ChangeQuantity: (shoppingCartItemId: string, qty: number) => Promise<any>;
  MoveProductToWishList: (productId: string) => Promise<any>;
  MoveProductToCart: (productId: string) => Promise<any>;
  Refresh: () => Promise<any>;
  SetDestination: (countryId: string, stateProvinceId?: string | null, postalCode?: string | null, city?: string | null) => Promise<any>;
  SetLineReference: (productId: string, reference: string) => Promise<any>;
  SetShippingMethod: (group: IShoppingCartShipment, methodId: string) => Promise<any>;
  SetDisplayReferences: (value: boolean) => void;
  SetIsLoading: (value: boolean) => void;
}

export interface ICartContext {
  client: ICartClient;
  cart: IShoppingCart | null;
  loading: boolean;
}
