import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import styles from "./Banner.module.scss";
export type BannerType = "fleet" | "search" | "shipping" | "code" | "newProducts";

const Banner = (props: { type: BannerType }) => {
  const onClick = () => {
    switch (props.type) {
      case "fleet":
        return GenerateLink.ForMyFleet();
      //   return () => {};
      case "search":
        return "";
      default:
        return "";
      //   case "shipping":
      //     return () => {};
      //     case "newProducts":
      //   return () => {};
      //   case "code":
      //   return () => {};
    }
  };
  return (
    <div className={styles["container"] + " " + styles[props.type]}>
      <div className={styles["leftbg"]} />
      <div className={styles["rightbg"]} />

      <div className={styles["leftImg"]} />
      <div className={styles["body"]}>
        <p> </p>
        <Link className="btn " to={onClick}>
          <span>{/* Get Started */}</span>
          <div></div>
        </Link>
      </div>
      <div className={styles["rightImg"]} />
    </div>
  );
};

export default Banner;
