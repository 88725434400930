import { gql } from "@shane32/graphql";

export const EmailNotificationsQuery = gql`
  {
    isAuthenticated
    v1 {
      my {
        customer {
          email
          id
        }
      }
    }
  }
`;

export interface IEmailNotificationsQuery {
  isAuthenticated: boolean;
  v1: {
    my: {
      customer: {
        email: string;
        id: string;
      };
    };
  };
}

export interface IEmailNotificationsQueryVariables {}
