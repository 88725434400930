import algoliasearch from "algoliasearch";
import { SearchClient } from "instantsearch.js/es/types/algoliasearch";
import { isTest } from "../../globalVariables";

//export const searchClient = algoliasearch(`${process.env.REACT_APP_ALGOLIA_ID}`, `${process.env.REACT_APP_ALGOLIA_KEY}`);
const algoliaClient = algoliasearch(`${process.env.REACT_APP_ALGOLIA_ID}`, `${process.env.REACT_APP_ALGOLIA_KEY}`);

//when istest.value true algolia will not make any external requests only use the data supplied in the promise
export const getSearchClient = () =>
  isTest.value
    ? ({
        ...algoliaClient,
        search(requests: any) {
          if (requests.every(({ params }: any) => true)) {
            return Promise.resolve({
              results: requests.map(() => ({
                hits: [],
                nbHits: 0,
                nbPages: 0,
                page: 0,
                processingTimeMS: 0,
                hitsPerPage: 0,
                exhaustiveNbHits: false,
                query: "",
                params: "",
              })),
            });
          }

          return algoliaClient.search(requests);
        },
      } as SearchClient)
    : algoliaClient;
