import DefaultSuspense from "../../components/loading/DefaultSuspense";
import Home from "./Home";

const HomeController = () => {
  return (
    <DefaultSuspense>
      <Home></Home>
    </DefaultSuspense>
  );
};

export default HomeController;
