import { gql } from "@shane32/graphql";

export const CategoriesQuery = gql`
  {
    v1 {
      info {
        version
        zipCodeTemplate
      }
      categories {
        displayOrder
        numberOfProducts
        id
        name
        seoName
        parentCategoryId
        showOnHomePage
        picture {
          thumbnail(size: 100) {
            url
          }
        }
      }
    }
  }
`;

export interface ICategoryAndPicture {
  displayOrder: number;
  numberOfProducts: number;
  id: string;
  name: string;
  seoName: string | null;
  parentCategoryId: string | null;
  showOnHomePage: boolean;
  picture: {
    thumbnail: {
      url: string;
    };
  } | null;
}

export interface ICategoriesQuery {
  v1: {
    categories: ICategoryAndPicture[];
    info: {
      version: string;
      zipCodeTemplate: string;
    };
  };
}
