import { gql } from "@shane32/graphql";

export const DeleteMachineMutation = gql`
  mutation ($garageId: ID!) {
    v1 {
      garage {
        delete(garageId: $garageId)
      }
    }
  }
`;

export interface IDeleteMachineMutationResult {
  v1: {
    garage: {
      delete: boolean;
    };
  };
}

export interface IDeleteMachineMutationVariables {
  garageId: string;
}
