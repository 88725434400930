import * as React from "react";
import styles from "./Sidebar.module.scss";

interface IProps {
  header?: string;
  showArrow?: boolean;
}

const Sidebar = (props: React.PropsWithChildren<IProps>) => {
  return (
    <div className={styles.container + (!props.showArrow ? " " + styles.hidearrow : "")}>
      <h2>{props.header}</h2>
      <div>{props.children}</div>
    </div>
  );
};

export default Sidebar;
