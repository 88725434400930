import { AuthContext } from "@zboxglobal/zboxauth";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { CartContext } from "../../contexts/CartContext";
import GlobalContext from "../../contexts/GlobalContext";
import { shoppingCartRef } from "../../contexts/RefContextStore";
import GenerateLink from "../../helpers/GenerateLink";
import useTrackers from "../../hooks/useTrackers";
import styles from "./AccountTab.module.scss";

type IColor = "white" | "grey";
type ILoggedInDisplay = "Email" | "Account" | "None" | "EmailHi";
const AccountTab = (props: { color: IColor; loggedInDisplay?: ILoggedInDisplay; accountClick?: () => void }) => {
  const history = useHistory();
  const globalContext = React.useContext(GlobalContext);
  const context = React.useContext(AuthContext);
  const cartContext = React.useContext(CartContext);
  const cartRef = React.useContext(shoppingCartRef);
  const signOut = () => {
    context.client.Logout();
  };

  const globalClickHandler = () => {
    globalContext.closeOverlay();
  };

  const goToFleet = () => {
    context.client.EnsureGuest(true).then((x) => {
      history.push(GenerateLink.ForMyFleet());
    });
  };

  const redirectToLogin = () => {
    history.push(GenerateLink.ForSignIn());
  };

  const tracker = useTrackers();

  return (
    <div className={styles["account"] + " " + styles[props.color]}>
      <button className={`anchor-btn ${styles["rig-icon"]}`} onClick={goToFleet} />
      <a
        href={`tel://${process.env.REACT_APP_PHONE_NUMBER}`}
        onClick={() => {
          tracker.trackEvent(`${process.env.TRACK_OPEN_PHONE_NAME}`, `${process.env.TRACK_OPEN_PHONE_VALUE}`);
        }}
      >
        <span className={styles["mobile-phone-icon"]}></span>
      </a>
      <button
        className={styles["my-account-container"]}
        onClick={
          context.status.loggedIn && !context.status.isGuest
            ? props.accountClick != null
              ? props.accountClick
              : globalContext.toggleAccount
            : () => {
                redirectToLogin();
                globalClickHandler();
              }
        }
      >
        <div>
          {/* <div className={styles["person-icon"]}  /> */}
          {context.status.loggedIn && !context.status.isGuest ? (
            <>
              {(() => {
                switch (props.loggedInDisplay) {
                  case "Account":
                    return (
                      <div className={styles["name-container"]}>
                        <div>Account</div>
                      </div>
                    );
                  case "None":
                    return null;
                  default:
                  case "EmailHi" || "Email":
                    return (
                      <div className={styles["name-container"]}>
                        {props.loggedInDisplay === "EmailHi" && <div>Hi,&nbsp;</div>}
                        <div>{context.status.userInfo.name || context.status.userInfo.email}</div>
                      </div>
                    );
                }
              })()}
              {props.loggedInDisplay !== "None" && <div className={styles["down-arrow"]} />}
            </>
          ) : (
            <div className={styles["name-container"]}>
              <div>Log in</div>{" "}
            </div>
          )}
        </div>
      </button>
      {globalContext.isAccountOpen && (
        <div className={styles["account-links"]}>
          <Link onClick={globalContext.toggleAccount} to={GenerateLink.ForAccount()}>
            Your Account
          </Link>
          <Link onClick={globalContext.toggleAccount} to={GenerateLink.ForOrders()}>
            Your Orders
          </Link>
          <Link onClick={globalContext.toggleAccount} to={GenerateLink.ForMyFleet()}>
            Your Fleet
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              signOut();
              globalContext.toggleAccount();
            }}
          >
            Sign Out
          </Link>
        </div>
      )}
      <div ref={cartRef} id="shoppingCart">
        <Link to={GenerateLink.ForCart()} className={styles["shoppingCartLink"]}>
          <label className={styles["label-text"]}>
            &nbsp;
            {cartContext.cart ? cartContext.cart.itemCount : "0"}
          </label>
          <button onClick={globalClickHandler} className={styles["shopping-cart-button"]}></button>
        </Link>
      </div>
    </div>
  );
};
export default AccountTab;
