import * as React from "react";
import useUniqueId from "../../hooks/useUniqueId";
import styles from "./Radio.module.scss";
import { RadioContainerContext } from "./RadioContainer";

interface IProps {
  id?: string;
  name?: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  children?: any;
}

const Radio = (props: IProps) => {
  const context = React.useContext(RadioContainerContext);

  const myId = useUniqueId();

  const onChangeRadio = (e: React.FormEvent<HTMLInputElement>) => {
    if (context.onChange) context.onChange(e);
  };
  //generate an id if none given
  const id = props.id || myId;

  //1. if checked is not undefined, use the value (true or false)
  //2. if we have context.values, return true or false depending on if it's in the list
  //3. otherwise return undefined
  const checked = props.checked === undefined ? context.value === props.value : props.checked;

  //use props.name if it is defined, otherwise context.name
  const name = props.name || context.name;

  //1. if props.onChange, then use it
  //2. if context.onChange, then use onChange
  //3. else return undefined
  const onChange = props.onChange || (context.onChange && onChangeRadio);

  const disabled = props.disabled || context.disabled;

  return (
    <div className={styles.container}>
      <input
        className={`${styles.radio} form-control`}
        id={id}
        value={props.value}
        type="radio"
        required={context.required}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        name={name}
      />
      <label htmlFor={id}>{props.children}</label>
    </div>
  );
};

export default Radio;
