import styles from "./DesktopHeader.module.scss";

const DesktopHeader = () => {
  return (
    <div className={styles["header"]}>
      <div className={styles["main-text"]}>Zbox Parts Book</div>
      <div className={styles["sub-text"]}>A better way to buy parts.</div>
      <div className={styles["description"]}>
        Shop confidently with free and accurate parts information, straight from the source.
        <br />
        Constantly updated with new machines and part info.
      </div>
      <div className={styles["find-parts"]}>Find your parts today!</div>
      <div className={styles["devices"]} />
    </div>
  );
};

export default DesktopHeader;
