import ZboxLogo from "../images/logo/Zbox-logo.png";

interface IMetaImg {
  url: string;
  width: number;
  height: number;
}

interface IMetaData {
  title?: string;
  description?: string | null;
  path?: string;
  image?: IMetaImg;
}

export default class GenerateMeta {
  // Initialize Metadata
  static Init() {
    this.createTag("meta", "property", "en_US", "og:locale");
    this.createTag("meta", "property", "website", "og:type");
    this.createTag("meta", "name", "summary", "twitter:card");
  }

  static Data(meta: IMetaData) {
    this.setTitle(meta.title || process.env.REACT_APP_TAGLINE || "");
    this.setDescription(meta.description || process.env.REACT_APP_DESCRIPTION || "");
    this.setUrl(meta.path);
    this.setImage(meta.image);
  }

  // Create metadata tags
  static createTag(elem: string, type: string, content: string, property?: string) {
    let tag: any;
    // Create meta or link tag
    switch (elem) {
      case "meta":
        if (!property) return;
        tag = document.createElement("meta");
        tag.setAttribute(type, property || "");
        tag.content = content;
        break;
      case "link":
        tag = document.createElement("link");
        tag.rel = type;
        tag.href = content;
        break;
    }
    // If tag is created update DOM
    if (tag) document.getElementsByTagName("head")[0].appendChild(tag);
  }

  // Remove new lines and tabs from data
  static sanitize = (dirty: string) => dirty.replace("\r", "").replace("\n", "").trim();

  static setTitle(title: string) {
    title = this.sanitize(title);
    // Update DOM
    document.title = title;
    document.querySelector('meta[name="title"]')
      ? document.querySelector('meta[name="title"]')?.setAttribute("content", title)
      : this.createTag("meta", "name", title, "title");
    document.querySelector('meta[property="og:title"]')
      ? document.querySelector('meta[property="og:title"]')?.setAttribute("content", title)
      : this.createTag("meta", "property", title, "og:title");
    document.querySelector('meta[name="twitter:title"]')
      ? document.querySelector('meta[name="twitter:title"]')?.setAttribute("content", title)
      : this.createTag("meta", "name", title, "twitter:title");
  }

  static setDescription(description: string) {
    description = this.sanitize(description);
    // Update DOM
    document.querySelector('meta[name="description"]')
      ? document.querySelector('meta[name="description"]')?.setAttribute("content", description)
      : this.createTag("meta", "name", description, "description");
    document.querySelector('meta[property="og:description"]')
      ? document.querySelector('meta[property="og:description"]')?.setAttribute("content", description)
      : this.createTag("meta", "property", description, "og:description");
    document.querySelector('meta[name="twitter:description"]')
      ? document.querySelector('meta[name="twitter:description"]')?.setAttribute("content", description)
      : this.createTag("meta", "name", description, "twitter:description");
  }

  static setUrl(url: string = "/") {
    // Ensure correct URL
    if (!url.startsWith("http://") || !url.startsWith("https://")) {
      if (url.charAt(0) !== "/") url = `/${url}`;
      url = `${process.env.REACT_APP_PUBLIC_URL}${url}`;
    }
    // Update DOM
    document.querySelector('link[rel="canonical"]')
      ? document.querySelector('link[rel="canonical"]')?.setAttribute("href", url)
      : this.createTag("link", "canonical", url);
    document.querySelector('meta[property="og:url"]')
      ? document.querySelector('meta[property="og:url"]')?.setAttribute("content", url)
      : this.createTag("meta", "property", url, "og:url");
    document.querySelector('meta[name="twitter:url"]')
      ? document.querySelector('meta[name="twitter:url"]')?.setAttribute("content", url)
      : this.createTag("meta", "name", url, "twitter:url");
  }

  static setImage(metaImage: IMetaImg | undefined) {
    if (metaImage === undefined) {
      metaImage = {
        url: `${process.env.REACT_APP_PUBLIC_URL}${ZboxLogo}`,
        width: parseInt(process.env.REACT_APP_IMG_WIDTH || ""),
        height: parseInt(process.env.REACT_APP_IMG_HEIGHT || ""),
      };
    }

    // Update DOM
    document.querySelector('meta[name="twitter:image"]')
      ? document.querySelector('meta[name="twitter:image"]')?.setAttribute("content", metaImage.url)
      : this.createTag("meta", "name", metaImage.url, "twitter:image");
    document.querySelector('meta[property="og:image"]')
      ? document.querySelector('meta[property="og:image"]')?.setAttribute("content", metaImage.url)
      : this.createTag("meta", "property", metaImage.url, "og:image");
    document.querySelector('meta[property="og:image:width"]')
      ? document.querySelector('meta[property="og:image:width"]')?.setAttribute("content", metaImage.width.toString())
      : this.createTag("meta", "property", metaImage.width.toString(), "og:image:width");
    document.querySelector('meta[property="og:image:height"]')
      ? document.querySelector('meta[property="og:image:height"]')?.setAttribute("content", metaImage.height.toString())
      : this.createTag("meta", "property", metaImage.height.toString(), "og:image:height");
  }
}
