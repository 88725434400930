import { gql } from "@shane32/graphql";

// using non-authenticated link

export const ValidateProductIdQuery = gql`
  query ($productId: ID) {
    v1 {
      product(id: $productId) {
        id
      }
    }
  }
`;

export interface IValidateProductIdQuery {
  v1: {
    product: {
      id: string;
    };
  };
}
