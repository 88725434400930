import { gql } from "@shane32/graphql";
import { ShoppingCartPartialQuery, IShoppingCart } from "../queries/ShoppingCartQuery";

export const MoveToCartMutation = gql`mutation($shoppingCartItemId:ID!) {
  v1 {
    cart {
      moveToCart(shoppingCartItemId: $shoppingCartItemId) ${ShoppingCartPartialQuery()}
    }
  }
}`;

export interface IMoveToCartResult {
  v1: {
    cart: {
      moveToCart: IShoppingCart;
    };
  };
}

export interface IMoveToCartVariables {
  shoppingCartItemId: string;
}
