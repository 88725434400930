import { gql } from "@shane32/graphql";

// using non-authenticated link

export const ProductPageQuery = gql`
  query ($productId: ID) {
    v1 {
      product(id: $productId) {
        allowCustomerReviews
        approvedRatingSum
        approvedTotalReviews
        categories {
          category {
            id
            name
            seoName
          }
        }
        fullDescription
        gtin
        height
        id
        sku
        length
        machineModelFitment {
          id
          name
          machineManufacturer {
            name
          }
          machineType {
            name
          }
        }
        manufacturerPartNumber
        manufacturers {
          displayOrder
          manufacturer {
            description
            name
            picture {
              thumbnail(size: 100) {
                url
              }
              imageUrl
            }
          }
        }
        metaDescription
        metaTitle
        metaKeywords
        name
        seoName
        partInterchange
        pictures {
          displayOrder
          picture {
            altAttribute
            id
            imageUrl
            mimeType
            seoFilename
            size
            thumbnail(size: 100) {
              size
              url
            }
            thumbnails(sizes: [100, 500, 1600]) {
              size
              url
            }
            titleAttribute
          }
        }
        priceAndAvailability {
          allowedQuantities
          backorderAvailabilityRange
          canNotify
          oldPrice
          orderMaximumQuantity
          orderMinimumQuantity
          preorderAvailabilityStartDateTime
          price
          status
          stock
        }
        seoName
        shortDescription
        specificationAttributes {
          showOnProductPage
          customValue
          displayOrder
          specificationAttributeOption {
            id
            colorSquaresRgb
            name
            specificationAttribute {
              id
              name
            }
          }
        }
        vendor {
          address {
            address1
            address2
            city
            company
            country {
              id
            }
            createdOnUtc
            email
            firstName
            id
            lastName
            phoneNumber
            stateProvince {
              id
            }
            zipPostalCode
          }
          description
          displayOrder
          email
          id
          metaDescription
          metaKeywords
          metaTitle
          name
          picture {
            id
          }
        }
        weight
        width
        youtubeEmbedCode
      }
      diagrams(productId: $productId) {
        id
        machineSection {
          id
          seoName
          title
          machineModel {
            id
            machineManufacturer {
              name
            }
            name
          }
        }
        imageUrl
      }
    }
  }
`;

export interface IProductPageQuery {
  v1: {
    product: IProduct | null;
    diagrams: IDiagram[];
  };
}

export interface IDiagram {
  id: string;
  machineSection: {
    id: string;
    seoName: string | null;
    title: string;
    machineModel: {
      id: string;
      machineManufacturer: {
        name: string;
      };
      name: string;
    };
  };
  imageUrl: string | null;
}

export interface IProduct {
  allowCustomerReviews: boolean;
  approvedRatingSum: number;
  approvedTotalReviews: number;
  categories: ICategoryWithDisplayOrder[];
  fullDescription: string | null;
  gtin: string | null;
  height: number;
  id: string;
  sku: string;
  length: number;
  machineModelFitment: IMachineModelFitment[];
  manufacturerPartNumber: string | null;
  manufacturers: IManufacturerItem[];
  metaDescription: string | null;
  metaTitle: string | null;
  metaKeywords: string | null;
  name: string;
  partInterchange: string[];
  pictures: IPictureItem[];
  priceAndAvailability: IPriceAndAvailability;
  seoName: string | null;
  shortDescription: string | null;
  specificationAttributes: ProductSpecificationAttribute[];
  vendor: IVendor | null;
  weight: number;
  width: number;
  youtubeEmbedCode: string | null;
}

export interface IMachineModelFitment {
  name: string;
  id: string;
  machineManufacturer: {
    name: string;
  };
  machineType: {
    name: string;
  };
}

interface IAddress {
  address1: string | null;
  address2: string | null;
  city: string | null;
  company: string | null;
  county: string | null;
  country: {
    id: string | null;
  };
  createdOnUtc: string;
  // customAttributes: string | null;
  email: string | null;
  firstName: string | null;
  id: string;
  lastName: string | null;
  phoneNumber: string | null;
  stateProvince: {
    id: string | null;
  };
  zipPostalCode: string | null;
}

interface IVendor {
  address: IAddress | null;
  //adminComment: string | null;
  description: string | null;
  displayOrder: number;
  email: string | null;
  id: string;
  metaDescription: string | null;
  metaKeywords: string | null;
  metaTitle: string | null;
  name: string;
  //pageSize: number;
  //pageSizeOptions: string | null;
  picture: {
    id: string;
  };
}

export interface ProductSpecificationAttribute {
  showOnProductPage: boolean;
  customValue: string | null;
  displayOrder: number;
  specificationAttributeOption: SpecificationAttributeOption;
}

interface SpecificationAttributeOption {
  id: string;
  colorSquaresRgb: string | null;
  name: string;
  specificationAttribute: SpecificationAttribute;
}

interface SpecificationAttribute {
  id: string;
  name: string;
}

export interface IManufacturerItem {
  displayOrder: number;
  manufacturer: {
    description: string | null;
    name: string;
    picture: {
      thumbnail: {
        url: string;
      };
      imageUrl: string;
    };
  };
}

export interface ICategoryWithDisplayOrder {
  //isFeaturedProduct: boolean;
  //displayOrder: number;
  category: ICategory;
}

interface ICategory {
  id: string;
  name: string;
  seoName: string;
}

export interface IPictureItem {
  displayOrder: number;
  picture: IPicture;
}

export interface IPicture {
  altAttribute: string;
  id: string;
  imageUrl: string;
  mimeType: string;
  seoFilename: string;
  size: number;
  thumbnail: {
    size: number;
    url: string;
  };
  thumbnails: {
    size: number;
    url: string;
  }[];
  titleAttribute: string;
}

export interface IProductPageQueryVariables {
  productId: string;
}

export interface IPriceAndAvailability {
  allowedQuantities: string | null;
  backorderAvailabilityRange: string | null;
  canNotify: boolean;
  oldPrice: number | null;
  orderMinimumQuantity: number;
  orderMaximumQuantity: number;
  preorderAvailabilityStartDateTime: string | null;
  price: number | null;
  status: AvailabilityStatusEnum;
  stock: number | null;
}

export enum AvailabilityStatusEnum {
  Preorder = "PREORDER",
  InStock = "IN_STOCK",
  LowStock = "LOW_STOCK",
  CanBackorder = "CAN_BACKORDER",
  Backordered = "BACKORDERED" /* not returned from server; used within cart client-side to indicate that a product is backordered */,
  ContactSeller = "CONTACT_SELLER",
  CallForPrice = "CALL_FOR_PRICE",
  CallToOrder = "CALL_TO_ORDER",
  BuildToOrder = "BUILD_TO_ORDER",
  NotAvailable = "NOT_AVAILABLE",
  Discontinued = "DISCONTINUED",
  DropShip = "DROP_SHIP",
}
