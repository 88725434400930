import * as React from "react";
import useUniqueId from "../../hooks/useUniqueId";
import Validation from "../inputs/Validation";
import { InputContainerContext } from "./InputContainerContext";
import styles from "./Select.module.scss";

interface IProps {
  id?: string;
  value?: number | string | null;
  name?: string;
  title?: string;
  inlineLabel?: boolean;
  required?: boolean;
  disabled?: boolean;
  validationText?: string;
  onChange?: (e: React.FormEvent<HTMLSelectElement>) => void;
  children: any;
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
}

const Select = (props: IProps) => {
  const context = React.useContext(InputContainerContext);

  const myId = useUniqueId();

  //generate an id if none given
  const id = props.id || myId;

  const title = props.title || context.title;

  return (
    <div className={styles.container}>
      {props.inlineLabel ? (
        <div className={styles["horizontal-label"]}>
          <label
            className={
              title === undefined ? (props.disabled ? `${styles.disabled} hide` : "hide") : props.disabled ? `${styles.disabled}` : ""
            }
            htmlFor={id}
          >
            {title}
          </label>
          <div>
            <select
              className={`${styles.select} form-control`}
              name={props.name}
              id={id}
              value={props.value === null ? undefined : props.value}
              disabled={props.disabled}
              required={props.required}
              onChange={props.onChange}
              autoComplete={props.autoComplete}
              autoFocus={props.autoFocus}
            >
              {props.children}
            </select>
            <Validation text={props.validationText} />
          </div>
        </div>
      ) : (
        <>
          <label
            className={
              title === undefined ? (props.disabled ? `${styles.disabled} hide` : "hide") : props.disabled ? `${styles.disabled}` : ""
            }
            htmlFor={id}
          >
            {title}
          </label>
          <select
            className={`${styles.select} form-control ${props.className}`}
            name={props.name}
            id={id}
            value={props.value === null ? undefined : props.value}
            disabled={props.disabled}
            required={props.required}
            onChange={props.onChange}
            autoFocus={props.autoFocus}
          >
            {props.children}
          </select>
          <Validation text={props.validationText} />
        </>
      )}
    </div>
  );
};

export default Select;
