import { gql } from "@shane32/graphql";

export const MachineModelsQuery = gql`
  query ($manufacturerId: ID, $machineTypeId: ID) {
    v1 {
      machineModels(machineManufacturerId: $manufacturerId, machineTypeId: $machineTypeId) {
        id
        name
        baseName
        machineEngines {
          id
          name
        }
        machineManufacturer {
          id
          name
        }
        machineType {
          id
          name
        }
      }
    }
  }
`;

export interface IMachineModelsQuery {
  v1: {
    machineModels: IMachineModel[];
  };
}

export interface IMachineModel {
  id: string;
  baseName: string | null;
  name: string;
  machineEngines: IMachineEngine[];
  machineManufacturer: IMachineManufacturer;
  machineType: IMachineType;
}

export interface IMachineType {
  id: string;
  name: string;
}

export interface IMachineManufacturer {
  id: string;
  name: string;
}

export interface IMachineEngine {
  id: string;
  name: string;
}

export interface IMachineModelsQueryVariables {
  manufacturerId: string | null;
  machineTypeId: string | null;
}
