import { gql } from "@shane32/graphql";
import { AvailabilityStatusEnum } from "./ProductPageQuery";

export const ThankYouPageQuery = gql`
  query ($clubOrderGuid: Guid!) {
    v1 {
      order(clubOrderGuid: $clubOrderGuid) {
        billingAddress {
          address1
          address2
          city
          company
          country {
            id
            name
          }
          email
          firstName
          lastName
          phoneNumber
          stateProvince {
            id
            name
            abbreviation
          }
          zipPostalCode
        }
        canRegister
        cardName
        cardType
        customOrderNumber
        estArrivalDate
        orderGuid
        orderItems {
          backorderedWhenOrdered
          quantity
          reference
          unitPriceExclTax
          product {
            id
            manufacturers {
              manufacturer {
                name
              }
            }
            name
            seoName
            picture {
              thumbnail(size: 100) {
                url
              }
            }
            priceAndAvailability {
              price
              status
            }
            vendor {
              name
            }
          }
        }
        maskedCreditCardNumber
        orderShippingExclTax
        orderSubtotalExclTax
        orderTax
        orderTotal
        purchaseOrder
        shippingAddress {
          address1
          address2
          city
          company
          country {
            id
            name
          }
          email
          firstName
          lastName
          phoneNumber
          stateProvince {
            id
            name
            abbreviation
          }
          zipPostalCode
        }
        zboxShippingMethod
      }
    }
  }
`;

export interface IThankYouPageQuery {
  v1: {
    order: IThankYouShipment[];
  };
}

export interface IThankYouShipment {
  billingAddress: IAddress;
  cardName: string | null;
  cardType: string;
  canRegister: boolean;
  customOrderNumber: string | null;
  estArrivalDate: string | null;
  maskedCreditCardNumber: string | null;
  orderGuid: string;
  orderItems: IOrderItem[];
  orderShippingExclTax: number;
  orderSubtotalExclTax: number;
  orderTax: number;
  orderTotal: number;
  purchaseOrder: string | null;
  shippingAddress: IAddress;
  zboxShippingMethod: string | null;
}

export interface IOrderItem {
  backorderedWhenOrdered: boolean;
  quantity: number;
  reference: string | null;
  unitPriceExclTax: number;
  product: IProduct;
}

export interface IProduct {
  manufacturers: IManufacturer[];
  id: string;
  name: string;
  seoName: string | null;
  picture: IPicture | null;
  priceAndAvailability: IPriceAndAvailability;
  vendor: IVendor | null;
}

export interface IManufacturer {
  manufacturer: {
    name: string;
  };
}

export interface IPicture {
  thumbnail: {
    url: string;
  };
}

export interface IPriceAndAvailability {
  price: number;
  status: AvailabilityStatusEnum;
}

export interface IVendor {
  name: string;
}

export interface IAddress {
  address1: string | null;
  address2: string | null;
  city: string | null;
  company: string | null;
  country: {
    id: string;
    name: string;
  };
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  } | null;
  zipPostalCode: string | null;
}

export interface IThankYouPageQueryVariables {
  clubOrderGuid: string;
}
