import { gql } from "@shane32/graphql";

export const MachineManufacturersQuery = gql`
  query ($machineTypeId: ID, $diagramsRequired: Boolean) {
    v1 {
      machineManufacturers(machineTypeId: $machineTypeId, diagramsRequired: $diagramsRequired) {
        id
        name
      }
    }
  }
`;

export interface IMachineManufacturersQuery {
  v1: {
    machineManufacturers: IMachineManufacturer[];
  };
}

export interface IMachineManufacturer {
  id: string;
  name: string;
}

export interface IMachineManufacturersQueryVariables {
  machineTypeId: string | null;
  diagramsRequired: boolean | null;
}
