import styles from "./Loading.module.scss";

interface IProps {
  mobileSm?: boolean;
  shoppingCart?: boolean;
  hostedField?: boolean;
}

const Loading = (props: IProps) => (
  <div
    className={
      props.hostedField
        ? `${styles.container} ${styles["hosted-field"]}`
        : props.mobileSm
        ? props.shoppingCart
          ? `${styles.container} ${styles.cart} ${styles["mobile-sm"]}`
          : `${styles.container} ${styles["mobile-sm"]}`
        : props.shoppingCart
        ? `${styles.container} ${styles.cart} loading`
        : `${styles.container} loading`
    }
  />
);

export default Loading;
