import React from "react";
import Banner from "../../components/Banner/Banner";
import MultiImageBanner from "../../components/Banner/MultiImageBanner";
import AlgoliaCollection from "../../components/Collection/AlgoliaCollection";
import Collection, { ICollectionData } from "../../components/Collection/Collection";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import NavSidebar from "../../components/navbar/NavSideBar";
import Overlay from "../../components/overlay/Overlay";
import RecentlyViewed from "../../components/RecentlyViewed/RecentlyViewed";
import SeeAllTitle from "../../components/SeeAll/SeeAllTitle";
import { FleetContext } from "../../contexts/FleetContext";
import GenerateLink from "../../helpers/GenerateLink";
import useHeader from "../../hooks/useHeader";
import Belts from "../../images/home/Belt@.png";
import AirFilters from "../../images/home/Filter@.png";
import Glass from "../../images/home/Glass.png";
import SealKits from "../../images/home/SealKits.png";
import Queries from "../../queries/Queries";
import FarmConstruction from "./FarmConstruction";
import styles from "./Home.module.scss";

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
const Home = () => {
  useHeader("ScrollNavBar");
  const ordersQuery = Queries.useQueryPreviouslyOrderedProducts();
  const fleetContext = React.useContext(FleetContext);

  const machine = fleetContext.fleet?.length ? fleetContext.fleet[getRandomInt(fleetContext.fleet.length - 1)] : null;

  let prevouslyBought: ICollectionData[] | null = null;
  if (ordersQuery.data?.v1.my?.orders.items) {
    const tttt = ordersQuery.data.v1.my.orders.items
      // .sort((a, b) => a.createdOnUtc.getTime() - b.createdOnUtc.getTime())
      .map((x) =>
        x.orderItems.map((oi) => {
          const g: ICollectionData = {
            id: oi.product.id,
            name: oi.product.name,
            seoName: oi.product.seoName,
            picture: oi.product.picture,
            priceAndAvailability: oi.product.priceAndAvailability,
            warehouse: null,
            approvedRatingSum: oi.product.approvedRatingSum,
            approvedTotalReviews: 0,
          };
          return g;
        })
      );
    prevouslyBought = [];
    prevouslyBought = prevouslyBought.concat.apply([], tttt).slice(0, 5);
  }
  const machineCollection = machine
    ? {
        query: `${machine.machineModel.machineManufacturer.name} ${machine.machineModel.name}`,
        title: (
          <>
            Fits your: {machine.machineModel.machineManufacturer.name} {machine.machineModel.name}
          </>
        ),
      }
    : {
        query: "Case 580",
        title: (
          <>
            Fits: <span className="h1r">Case 580 </span>
          </>
        ),
      };

  const youMayAlsoLike = "cool stuff";

  return (
    <div className={styles["container"]}>
      <Overlay />
      <NavSidebar />
      <div className={styles["landing"]}>
        <Navbar />
        <RecentlyViewed />
        <FarmConstruction />
      </div>
      <MultiImageBanner
        title={"Browse our popular categories"}
        sections={[
          {
            src: Belts,
            title: "Belts",
            to: GenerateLink.ForSearch("belts"),
            secondaryTitle: "see more",
          },
          {
            src: Glass,
            title: "Glass",
            to: GenerateLink.ForSearch("glass"),
            secondaryTitle: "see more",
          },
          {
            src: SealKits,
            title: "Seal Kits",
            to: GenerateLink.ForSearch("seal kits"),
            secondaryTitle: "see more",
          },
          {
            src: AirFilters,
            title: "Air Filters",
            to: GenerateLink.ForSearch("air filters"),
            secondaryTitle: "see more",
          },
        ]}
      />
      <div className={styles["body"]}>
        <AlgoliaCollection
          layout="Carousel"
          seeMoreTo={GenerateLink.ForSearch(machineCollection.query)}
          query={machineCollection.query}
          displayAmount={11}
          title={<SeeAllTitle to={GenerateLink.ForSearch(machineCollection.query)}>{machineCollection.title}</SeeAllTitle>}
        />
        <Banner type="fleet"></Banner>

        {prevouslyBought && prevouslyBought.length > 0 ? (
          <Collection
            seeMoreTo={GenerateLink.ForOrders()}
            layout={"Vertical"}
            products={prevouslyBought}
            title={<SeeAllTitle to={GenerateLink.ForOrders()}>Previously Bought</SeeAllTitle>}
          />
        ) : (
          <AlgoliaCollection
            seeMoreTo={GenerateLink.ForSearch(youMayAlsoLike)}
            displayAmount={5}
            query={youMayAlsoLike}
            layout="Vertical"
            title={<SeeAllTitle to={GenerateLink.ForSearch(youMayAlsoLike)}>You May Also Like</SeeAllTitle>}
          />
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
