import * as React from "react";
import Loading from "../../components/loading/Loading";
import useTimer from "../../hooks/useTimer";
import Queries from "../../queries/Queries";
import CategoryDisplay from "./CategoryDisplay";

const Category = () => {
  const { data, error } = Queries.useCategories();
  const [displayError, setDisplayError] = React.useState<boolean>();
  const [timerEnabled, setTimer] = useTimer(() => setDisplayError(true));

  React.useEffect(() => {
    if (error && !timerEnabled) {
      setTimer(2000);
    } else if (!error) {
      setTimer();
      if (displayError) setDisplayError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  if (error) return displayError ? <div>{error.message}</div> : <Loading />;
  else if (!data || !data.v1 || !data.v1.categories) return <Loading />;
  else return <CategoryDisplay fullCategories={data.v1.categories} />;
};

export default Category;
