import { gql } from "@shane32/graphql";

export const PaymentMethodsQuery = gql`
  {
    v1 {
      my {
        paymentMethods {
          id
          accountNameOrEmail
          address {
            id
            country {
              id
              name
            }
            firstName
            lastName
            company
            email
            address1
            address2
            city
            stateProvince {
              id
              name
              abbreviation
            }
            zipPostalCode
            phoneNumber
            createdOnUtc
          }
          paymentType
          creditCardType
          creditCardLast4
          creditCardExp
          displayOrder
          createdOnUtc
          validShippingAddresses {
            id
          }
        }
      }
    }
  }
`;

export interface IPaymentMethodsQuery {
  v1: {
    my: {
      paymentMethods: IPaymentMethod[];
    } | null;
  };
}

export interface IPaymentMethod {
  id: string;
  accountNameOrEmail: string | null;
  address: IAddress;
  paymentType: "CREDIT_CARD" | "PAY_PAL" | "APPLE_PAY" | "GOOGLE_PAY";
  creditCardType: string | null;
  creditCardLast4: string | null;
  creditCardExp: string | null;
  displayOrder: number;
  createdOnUtc: string;
  validShippingAddresses: Array<{ id: string }>;
}

export interface IAddress {
  id: string;
  country: {
    id: string;
    name: string;
  };
  firstName: string;
  lastName: string;
  company: string | null;
  email: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  } | null;
  zipPostalCode: string | null;
  phoneNumber: string | null;
  createdOnUtc: string;
}

export interface IPaymentMethodsQueryVariables {}
