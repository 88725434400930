import { gql } from "@shane32/graphql";

// using non-authenticated link

export const ReviewQuery = gql`
  query ($productId: ID!) {
    v1 {
      my {
        review(productId: $productId) {
          createdOnUtc
          helpfulNoTotal
          helpfulYesTotal
          id
          rating
          replyText
          reviewText
          title
          suggestion
          asDescribed
          arrivedOnTime
          promptService
          wellPackaged
          orderId
        }
      }
    }
  }
`;

export interface IReviewQuery {
  v1: {
    my: {
      review: IReview;
    };
  };
}

export interface IReview {
  createdOnUtc: string;
  helpfulNoTotal: number;
  helpfulYesTotal: number;
  id: string;
  rating: number;
  replyText: string;
  reviewText: string;
  title: string;
  suggestion: string;
  asDescribed: boolean | null;
  arrivedOnTime: boolean | null;
  promptService: boolean | null;
  wellPackaged: boolean | null;
  orderId: string | null;
}

export interface IReviewQueryVariables {
  productId: string;
}
