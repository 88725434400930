import * as React from "react";
import { Mutations } from "../../mutations/Mutations";
import { IShoppingCartDestination, IShoppingCartShipment } from "../../queries/ShoppingCartQuery";
import { IShoppingCartShippingMethod } from "../../queries/ShoppingCartShippingMethodsQuery";
import Queries from "../../queries/Queries";

const cacheExpiration = 1000 * 60 * 60; // 1 hour
const cacheExpirationForError = 1000 * 10; // 10 seconds

const useCartShippingHelper = () => {
  const [cache, setCache] = React.useState<ShippingCacheDictionary>({
    shippingMethods: {},
    selected: {},
  });
  const queryShippingPricing = Queries.useQueryShoppingCartShippingMethodsOnDemand();
  const [setShippingMethodMutation] = Mutations.useSetSelectedShippingMethod();

  // public method to get shipping methods for a grouping in the cart
  const getShippingMethods = (destination: IShoppingCartDestination, group: IShoppingCartShipment, fromCacheOnly: boolean) => {
    // calculate a hash
    // the hash ensures that there is a different cache entry for each different group/items/quantites/destination/etc
    const hash = hashFor(destination, group);
    const groupHash = hashForGroup(group);

    // check and see if we already have a non-expired entry in the cache
    const entry = cache.shippingMethods[hash];
    const now = new Date().getTime();
    if (entry !== undefined && entry !== null && entry.expires > now) {
      const selId = cache.selected[groupHash];
      const shippingMethods = entry.entries;
      if (shippingMethods && selId && shippingMethods.filter((m) => m.id === selId).length > 0) {
        for (let i = 0; i < shippingMethods.length; i++) {
          shippingMethods[i].selected = shippingMethods[i].id === selId;
        }
      }
      // show loading icon instead of returning shipping methods while changing the selected method
      if (entry.loading) return null;
      return shippingMethods;
    }

    // if specified only to return from the cache, then return a 'loading' result now
    if (fromCacheOnly) {
      return null;
    }

    // create a 'loading' entry template
    const newEntry = {
      expires: now + cacheExpiration,
      entries: null,
      loading: true,
    };

    // callback to updating the state, so that we don't start the ajax call twice
    setCache((cache) => {
      // double check that another simultaneous request hasn't already started the ajax call
      const entry = cache.shippingMethods[hash];
      if (entry !== undefined && entry !== null && entry.expires > now) return cache;

      // start ajax call
      queryShippingPricing
        .refetch({
          countryId: destination.countryId,
          provinceId: destination.provinceId,
          postalCode: destination.postalCode,
          warehouseId: group.warehouseId,
          vendorId: group.vendorId,
          shippingProfileId: group.shippingProfileId,
          backordered: group.backordered,
        })
        .then((data) => {
          // validate that data has been successfully returned from the query
          const shippingMethods = data?.data?.v1?.my?.shoppingCartShippingMethods;
          if (!shippingMethods) return Promise.reject();
          // find the selected id
          const selected = shippingMethods.filter((x) => x.selected).map((x) => x.id)[0];
          // update the state to store the returned shipping methods
          setCache((cache) => {
            return {
              shippingMethods: {
                ...cache.shippingMethods,
                [hash]: {
                  expires: new Date().getTime() + cacheExpiration,
                  entries: shippingMethods,
                  loading: false,
                },
              },
              selected: {
                ...cache.selected,
                [groupHash]: selected,
              },
            };
          });
          return;
        })
        .catch(() => {
          // failure retrieving data; just set to an empty list
          setCache((cache) => {
            return {
              shippingMethods: {
                ...cache.shippingMethods,
                [hash]: {
                  expires: new Date().getTime() + cacheExpirationForError,
                  entries: [], // null
                  loading: false,
                },
              },
              selected: cache.selected,
            };
          });
          return;
        });

      // ajax call has been started; update the cache with a 'loading' entry
      return {
        shippingMethods: {
          ...cache.shippingMethods,
          [hash]: newEntry,
        },
        selected: cache.selected,
      };
    });

    // return a 'loading' entry
    return null;
  };

  const setShippingMethod = (destination: IShoppingCartDestination, group: IShoppingCartShipment, methodId: string) => {
    // calculate a hash
    // the hash ensures that there is a different cache entry for each different group/items/quantites/destination/etc
    const hash = hashFor(destination, group);
    const groupHash = hashForGroup(group);

    // check the cache and see if the entry is attempting to load or has expired
    const entry = cache.shippingMethods[hash];
    const now = new Date().getTime();
    if (entry !== undefined && entry !== null) {
      // found a match, but it's loading
      if (entry.expires > now && entry.loading) return Promise.reject();
    }

    // return a promise that resolves once the server has the new shipping method saved
    return new Promise<void>((resolve, reject) => {
      setCache((oldCache) => {
        let entry = oldCache.shippingMethods[hash];
        if (entry !== undefined && entry !== null) {
          // found a match, but it's loading
          if (entry.expires > now && entry.loading) {
            reject();
            return cache;
          }
          if (entry.expires <= now) entry = undefined;
        }

        // start ajax call
        setShippingMethodMutation({
          variables: {
            countryId: destination.countryId,
            provinceId: destination.provinceId,
            postalCode: destination.postalCode,
            warehouseId: group.warehouseId,
            vendorId: group.vendorId,
            shippingProfileId: group.shippingProfileId,
            backordered: group.backordered,
            selectedShippingMethodId: methodId,
          },
        })
          .then((data) => {
            // validate that data has been successfully returned from the query
            const shippingMethods = data?.data?.v1?.cart?.setSelectedShippingMethod;
            if (!shippingMethods) return Promise.reject();
            // find the selected id
            const selected = shippingMethods.filter((x) => x.selected).map((x) => x.id)[0];
            // update the state to store the returned shipping methods
            setCache((oldCache) => {
              resolve();
              const newCache = {
                shippingMethods: {
                  ...oldCache.shippingMethods,
                  [hash]: {
                    expires: new Date().getTime() + cacheExpiration,
                    entries: shippingMethods,
                    loading: false,
                  },
                },
                selected: {
                  ...oldCache.selected,
                  [groupHash]: selected,
                },
              };
              return newCache;
            });
            return;
          })
          .catch(() => {
            // failure retrieving data; just set to an empty list
            setCache((oldCache) => {
              reject();
              const newCache = {
                shippingMethods: {
                  ...oldCache.shippingMethods,
                  [hash]: {
                    expires: new Date().getTime() + cacheExpirationForError,
                    entries: [], // null
                    loading: false,
                  },
                },
                selected: oldCache.selected,
              };
              return newCache;
            });
            return;
          });

        // proactively update the cache
        // create an entry if it doesn't exist
        entry = entry
          ? {
              ...entry,
              loading: true,
            }
          : {
              expires: now + cacheExpiration,
              entries: null, // null == loading
              loading: true,
            };

        // update the entries so the new selected method is selected
        if (entry.entries !== null) {
          let found: boolean = false;
          entry.entries.forEach((m) => {
            if (m.id === methodId) found = true;
          });
          if (found) {
            entry.entries.forEach((m) => {
              m.selected = m.id === methodId;
            });
          } else {
            entry.entries.forEach((m) => {
              m.selected = !found;
              found = true;
            });
          }
        }

        // return the updated cache
        var newCache = {
          shippingMethods: {
            ...oldCache.shippingMethods,
            [hash]: entry,
          },
          selected: {
            ...oldCache.selected,
            [groupHash]: methodId,
          },
        };

        return newCache;
      });
    });
  };

  const clearCache = () => {
    // clear the cache of shipping methods; upon redraw, a new request will
    //   start for the necessary shipping methods
    setCache({
      shippingMethods: {},
      selected: {},
    });
  };

  return {
    getShippingMethods: getShippingMethods,
    clearCache: clearCache,
    setShippingMethod: setShippingMethod,
  };
};

function hashFor(destination: IShoppingCartDestination, group: IShoppingCartShipment) {
  let hash = `${destination.countryId}_${destination.provinceId}_${destination.postalCode}_${group.shippingProfileId}_${group.vendorId}_${
    group.warehouseId
  }_${group.backordered ? "1" : "0"}_`;
  for (let j = 0; j < group.items.length; j++) {
    const item = group.items[j];
    hash += `${item.product.id}_${item.quantity}_`;
  }
  return hash;
}

function hashForGroup(group: IShoppingCartShipment) {
  return `${group.shippingProfileId}_${group.vendorId}_${group.warehouseId}_${group.backordered}_`;
}

type ShippingCacheDictionary = {
  shippingMethods: { [hash: string]: IShippingCacheEntry | undefined };
  selected: { [hash: string]: string | undefined };
};

interface IShippingCacheEntry {
  expires: number;
  entries: IShoppingCartShippingMethod[] | null;
  loading: boolean;
}

export default useCartShippingHelper;
