import { useMutation, useQuery } from "@shane32/graphql";
import { AddressBookQuery, IAddressBookQuery, IAddressBookQueryVariables } from "./AddressBookQuery";
import { AllCustomerReviewsQuery, IAllCustomerReviewsQuery, IAllCustomerReviewsQueryVariables } from "./AllCustomerReviewsQuery";
import { BraintreeClientTokenQuery, IBraintreeClientTokenQuery } from "./BraintreeClientTokenQuery";
import { CategoriesQuery, ICategoriesQuery } from "./CategoriesQuery";
import { CountriesQuery, ICountriesQuery } from "./CountriesQuery";
import { DefaultPaymentAndShippingQuery, IDefaultPaymentAndShippingQuery } from "./DefaultPaymentAndShippingQuery";
import { EditAddressQuery, IEditAddressBookQueryVariables, IEditAddressQuery } from "./EditAddressBookQuery";
import { EmailNotificationsQuery, IEmailNotificationsQuery, IEmailNotificationsQueryVariables } from "./EmailNotificationsQuery";
import { EmptyFilterQuery, IEmptyFilterQuery, IEmptyFilterQueryVariables } from "./EmptyFilters";
import { FeedbackNameQuery, IFeedbackNameQuery, IFeedbackNameQueryVariables } from "./FeedbackNameQuery";
import { FleetMachineQuery, IFleetMachineQuery } from "./FleetMachineQuery";
import { FleetQuery, IFleetQuery } from "./FleetQuery";
import { ILeaveFeedbackQuery, ILeaveFeedbackQueryVariables, LeaveFeedbackQuery } from "./LeaveFeedbackQuery";
import { ILoginAndSecurityQuery, ILoginAndSecurityQueryVariables, LoginAndSecurityQuery } from "./LoginAndSecurityQuery";
import { IMachineManufacturersQuery, IMachineManufacturersQueryVariables, MachineManufacturersQuery } from "./MachineManufacturersQuery";
import { IMachineModelQuery, IMachineModelQueryVariables, MachineModelQuery } from "./MachineModelQuery";
import { IMachineModelsQuery, IMachineModelsQueryVariables, MachineModelsQuery } from "./MachineModelsQuery";
import { IOrdersAndReturnsQuery, OrdersAndReturnsQuery } from "./OrdersAndReturnsQuery";
import { IOrderStatusQuery, IOrderStatusQueryVariables, OrderStatusQuery } from "./OrderStatusQuery";
import { IOrderSummaryQuery, IOrderSummaryQueryVariables, OrderSummaryQuery } from "./OrderSummaryQuery";
import { CustomerDiagramsQuery, ICustomerDiagramsQuery } from "./PartsBookQueries/CustomerDiagramsQuery";
import {
  IMachineManufacturerWithModelDiagramsQuery,
  IMachineManufacturerWithModelDiagramsQueryVariables,
  MachineManufacturerWithModelDiagramsQuery,
} from "./PartsBookQueries/MachineManufacturerWithModelDiagramsQuery";
import { IPaymentMethodQuery, IPaymentMethodQueryVariables, PaymentMethodQuery } from "./PaymentMethodQuery";
import { IPaymentMethodsQuery, IPaymentMethodsQueryVariables, PaymentMethodsQuery } from "./PaymentMethodsQuery";
import { IPreviouslyOrderedProductsQuery, PreviouslyOrderedProductsQuery } from "./PreviouslyOrderedProducts";
import {
  IProductPageAlsoPurchasedQuery,
  IProductPageAlsoPurchasedQueryVariables,
  ProductPageAlsoPurchasedQuery,
} from "./ProductPageAlsoPurchasedQuery";
import { IProductPageQuery, IProductPageQueryVariables, ProductPageQuery } from "./ProductPageQuery";
import { IProductPageRelatedQuery, IProductPageRelatedQueryVariables, ProductPageRelatedQuery } from "./ProductPageRelatedQuery";
import { IProductPageReviewsQuery, IProductPageReviewsQueryVariables, ProductPageReviewsQuery } from "./ProductPageReviewsQuery";
import {
  IProductShippingMethodsQuery,
  IProductShippingMethodsQueryVariables,
  ProductShippingMethodsQuery,
} from "./ProductShippingMethodsQuery";
import { IQrCodeQuery, IQrCodeQueryVariables, QrCodeQuery } from "./QrCodeQuery";
import { IReviewQuery, IReviewQueryVariables, ReviewQuery } from "./ReviewQuery";
import { ISearchFiltersQuery, ISearchQuery, ISearchQueryVariables, SearchFiltersQuery, SearchQuery } from "./SearchQuery";
import { ISimilarItemsSearchQuery, SimilarItemsSearchQuery } from "./SearchSimilarItemsQuery";
import { IShoppingCartQuery, ShoppingCartQuery } from "./ShoppingCartQuery";
import {
  IShoppingCartShippingMethodsQuery,
  IShoppingCartShippingMethodsQueryVariables,
  ShoppingCartShippingMethodsQuery,
} from "./ShoppingCartShippingMethodsQuery";
import { IThankYouPageQuery, IThankYouPageQueryVariables, ThankYouPageQuery } from "./ThankYouPageQuery";
import { IValidateProductIdQuery, ValidateProductIdQuery } from "./ValidateProductIdQuery";
import { IWarehouseQuery, IWarehouseQueryVariables, WarehouseQuery } from "./WarehouseQuery";
import { IZipCodeTemplateQuery, ZipCodeTemplateQuery } from "./ZipCodeTemplateQuery";

/*
 * fetch policies:
 *
 *   cache-first: (default)
 *   - never retrieve from database once the query has been loaded
 *
 *   cache-and-network: //WARNING: this means if you monitor loading, then it will always blink
 *   - load from cache first
 *   - then LOADING = true, and reload from server
 *   - load new data from server
 *
 *   no-cache:
 *   - always load data from server
 *
 *
 * returnPartialData:
 *   false: do not return a data object if there are any errors (default)
 *
 *
 * when authenticating: ....
 *
 */

const Queries = {
  useCategories: () => useQuery<ICategoriesQuery, {}>(CategoriesQuery, { guest: true }),

  /* return { // Patch for v1 being undefined when retrieving from the cache
        ...ret, loading: ret.loading || !ret.data || !ret.data.v1,
        data: !ret.loading && !ret.error && ret.data && ret.data.v1 ? ret.data : undefined,
    }; */

  useZipCodeTemplate: () =>
    useQuery<IZipCodeTemplateQuery, {}>(ZipCodeTemplateQuery, {
      guest: true,
    }),

  useQueryLoginAndSecurity: () =>
    useQuery<ILoginAndSecurityQuery, ILoginAndSecurityQueryVariables>(LoginAndSecurityQuery, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryFeedbackName: () =>
    useQuery<IFeedbackNameQuery, IFeedbackNameQueryVariables>(FeedbackNameQuery, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryOrdersAndReturns: () =>
    useQuery<IOrdersAndReturnsQuery>(OrdersAndReturnsQuery, {
      fetchPolicy: "no-cache",
    }),

  useQueryThankYouPage: (variables: IThankYouPageQueryVariables) =>
    useQuery<IThankYouPageQuery, IThankYouPageQueryVariables>(ThankYouPageQuery, {
      guest: true,
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryOrderStatus: (variables: IOrderStatusQueryVariables) =>
    useQuery<IOrderStatusQuery, IOrderStatusQueryVariables>(OrderStatusQuery, {
      guest: true,
      variables: variables,
      fetchPolicy: "no-cache",
    }),

  useQueryEmailNotifications: () =>
    useQuery<IEmailNotificationsQuery, IEmailNotificationsQueryVariables>(EmailNotificationsQuery, {
      fetchPolicy: "no-cache",
    }),

  useQueryWarehouse: (variables: IWarehouseQueryVariables) =>
    useQuery<IWarehouseQuery, IWarehouseQueryVariables>(WarehouseQuery, {
      guest: true,
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductPageRelated: (variables: IProductPageRelatedQueryVariables) =>
    useQuery<IProductPageRelatedQuery, IProductPageRelatedQueryVariables>(ProductPageRelatedQuery, {
      guest: true,
      variables: variables,
    }),

  useQueryLeaveFeedback: (variables: ILeaveFeedbackQueryVariables) =>
    useQuery<ILeaveFeedbackQuery, ILeaveFeedbackQueryVariables>(LeaveFeedbackQuery, {
      fetchPolicy: "no-cache",
      variables: variables,
    }),

  useQueryOrderSummary: (variables: IOrderSummaryQueryVariables) =>
    useQuery<IOrderSummaryQuery, IOrderSummaryQueryVariables>(OrderSummaryQuery, {
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryValidateProductId: (variables: IProductPageQueryVariables) =>
    useQuery<IValidateProductIdQuery, IProductPageQueryVariables>(ValidateProductIdQuery, {
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductPage: (variables: IProductPageQueryVariables) =>
    useQuery<IProductPageQuery, IProductPageQueryVariables>(ProductPageQuery, {
      guest: true,
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductPageAlsoPurchased: (variables: IProductPageAlsoPurchasedQueryVariables) =>
    useQuery<IProductPageAlsoPurchasedQuery, IProductPageAlsoPurchasedQueryVariables>(ProductPageAlsoPurchasedQuery, {
      guest: true,
      variables: variables,
    }),

  useQueryProductPageReviews: (variables: IProductPageReviewsQueryVariables) =>
    useQuery<IProductPageReviewsQuery, IProductPageReviewsQueryVariables>(ProductPageReviewsQuery, {
      guest: true,
      fetchPolicy: "cache-and-network",
      variables: variables,
    }),

  useQueryProductShippingMethods: (skip: boolean, variables: IProductShippingMethodsQueryVariables) =>
    useQuery<IProductShippingMethodsQuery, IProductShippingMethodsQueryVariables>(ProductShippingMethodsQuery, {
      guest: true,
      fetchPolicy: "no-cache",
      variables: variables,
      skip: skip,
    }),

  useQueryReview: (variables: IReviewQueryVariables) =>
    useQuery<IReviewQuery, IReviewQueryVariables>(ReviewQuery, {
      fetchPolicy: "no-cache",
      variables: variables,
    }),

  useSearchResults: (variables: ISearchQueryVariables) =>
    useQuery<ISearchQuery, ISearchQueryVariables>(SearchQuery, {
      guest: true,
      variables: variables,
    }),

  useEmptyFilters: (variables: IEmptyFilterQueryVariables) =>
    useQuery<IEmptyFilterQuery, IEmptyFilterQueryVariables>(EmptyFilterQuery, {
      guest: true,
      variables: variables,
    }),

  useSearchSimilarItemsResults: (variables: ISearchQueryVariables, skip: boolean) =>
    useQuery<ISimilarItemsSearchQuery, ISearchQueryVariables>(SimilarItemsSearchQuery, {
      guest: true,
      variables: variables,
      skip: skip,
    }),

  useSearchFiltersResults: (variables: ISearchQueryVariables) =>
    useQuery<ISearchFiltersQuery, ISearchQueryVariables>(SearchFiltersQuery, {
      guest: true,
      variables: variables,
    }),

  useQueryShoppingCart: () =>
    useQuery<IShoppingCartQuery>(ShoppingCartQuery, {
      fetchPolicy: "no-cache",
    }),

  useQueryDefaultPaymentAndShippingInfo: () => useQuery<IDefaultPaymentAndShippingQuery>(DefaultPaymentAndShippingQuery),

  useQueryCountries: () =>
    useQuery<ICountriesQuery, {}>(CountriesQuery, {
      guest: true,
    }),

  useQueryShoppingCartShippingMethods: (variables: IShoppingCartShippingMethodsQueryVariables, skip: boolean) =>
    useQuery<IShoppingCartShippingMethodsQuery, IShoppingCartShippingMethodsQueryVariables>(ShoppingCartShippingMethodsQuery, {
      variables: variables,
      fetchPolicy: "no-cache",
      skip: skip,
    }),

  useQueryShoppingCartShippingMethodsOnDemand: () => {
    const [mutation] = useMutation<IShoppingCartShippingMethodsQuery, IShoppingCartShippingMethodsQueryVariables>(
      ShoppingCartShippingMethodsQuery
    );
    return {
      refetch: (vars: IShoppingCartShippingMethodsQueryVariables) => mutation({ variables: vars }),
    };
  },

  useQueryAddressBook: () =>
    useQuery<IAddressBookQuery, IAddressBookQueryVariables>(AddressBookQuery, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryPaymentMethods: () =>
    useQuery<IPaymentMethodsQuery, IPaymentMethodsQueryVariables>(PaymentMethodsQuery, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryEditAddressBook: (variables: IEditAddressBookQueryVariables) =>
    useQuery<IEditAddressQuery, IEditAddressBookQueryVariables>(EditAddressQuery, {
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryPaymentMethod: (variables: IPaymentMethodQueryVariables) =>
    useQuery<IPaymentMethodQuery, IPaymentMethodQueryVariables>(PaymentMethodQuery, {
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryBraintreeClientToken: () => useQuery<IBraintreeClientTokenQuery>(BraintreeClientTokenQuery),

  useQueryAllCustomerReviews: (variables: IAllCustomerReviewsQueryVariables) =>
    useQuery<IAllCustomerReviewsQuery, IAllCustomerReviewsQueryVariables>(AllCustomerReviewsQuery, {
      guest: true,
      variables: variables,
      fetchPolicy: "cache-and-network",
    }),

  useQueryFleet: () =>
    useQuery<IFleetQuery>(FleetQuery, {
      fetchPolicy: "no-cache",
    }),

  useQueryFleetMachine: () =>
    useQuery<IFleetMachineQuery>(FleetMachineQuery, {
      guest: true,
    }),

  useQueryMachineModels: (skip: boolean, variables: IMachineModelsQueryVariables) =>
    useQuery<IMachineModelsQuery, IMachineModelsQueryVariables>(MachineModelsQuery, {
      guest: true,
      variables: variables,
      skip: skip,
    }),

  useQueryMachineModel: (skip: boolean, variables: IMachineModelQueryVariables) => {
    return useQuery<IMachineModelQuery, IMachineModelQueryVariables>(MachineModelQuery, {
      fetchPolicy: "cache-and-network",
      variables: variables,
      skip: skip,
    });
  },

  useQueryMachineManufacturers: (skip: boolean, variables: IMachineManufacturersQueryVariables) => {
    return useQuery<IMachineManufacturersQuery, IMachineManufacturersQueryVariables>(MachineManufacturersQuery, {
      fetchPolicy: "cache-and-network",
      variables: variables,
      skip: skip,
    });
  },

  useQueryPreviouslyOrderedProducts: () => {
    return useQuery<IPreviouslyOrderedProductsQuery>(PreviouslyOrderedProductsQuery, {
      fetchPolicy: "cache-and-network",
    });
  },

  useQueryCustomerDiagrams: () =>
    useQuery<ICustomerDiagramsQuery>(CustomerDiagramsQuery, {
      fetchPolicy: "cache-and-network",
    }),

  useQueryMachineManufacturerWithDiagramModels: (variables: IMachineManufacturerWithModelDiagramsQueryVariables) => {
    return useQuery<IMachineManufacturerWithModelDiagramsQuery, IMachineManufacturerWithModelDiagramsQueryVariables>(
      MachineManufacturerWithModelDiagramsQuery,
      {
        fetchPolicy: "cache-and-network",
        variables: variables,
      }
    );
  },

  useQueryQrCode: (sku: string) => {
    return useQuery<IQrCodeQuery, IQrCodeQueryVariables>(QrCodeQuery, { variables: { sku: sku } });
  },
};

export default Queries;
