import { gql } from "@shane32/graphql";

export const CountriesQuery = gql`
  {
    v1 {
      countries {
        displayOrder
        id
        name
        twoLetterIsoCode
        stateProvinces {
          abbreviation
          displayOrder
          id
          name
        }
      }
    }
  }
`;

export interface IStateProvince {
  abbreviation: string | null;
  displayOrder: number;
  id: string;
  name: string;
}

export interface ICountry {
  displayOrder: number;
  id: string;
  name: string;
  twoLetterIsoCode: string;
  stateProvinces: IStateProvince[];
}

export interface ICountriesQuery {
  v1: {
    countries: ICountry[];
  };
}
