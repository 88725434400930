import React from "react";
import appStyles from "../../App.module.scss";
import GlobalContext from "../../contexts/GlobalContext";

const Overlay = (props: { overlayOpen?: boolean; onClick?: () => void }) => {
  const globalContext = React.useContext(GlobalContext);
  return (
    <div
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        } else {
          globalContext.closeOverlay();
          globalContext.setFitmentSideBar("ClOSED");
        }
      }}
      className={
        props.overlayOpen ||
        globalContext.isAccountOpen ||
        globalContext.fitmentSideBarState !== "ClOSED" ||
        globalContext.isDepartmentsOpen ||
        globalContext.isMobileFiltersOpen
          ? appStyles.overlay
          : ""
      }
    />
  );
};

export default Overlay;
