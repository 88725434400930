import { gql } from "@shane32/graphql";
import { IOrder, OrderPartialQuery } from "./OrdersAndReturnsQuery";

export const OrderStatusQuery = gql`query ($clubOrderGuid: Guid!) {
  v1 {
    order(clubOrderGuid: $clubOrderGuid) {
      ${OrderPartialQuery}
    }
  }
}`;

export interface IOrderStatusQuery {
  v1: {
    order: IOrder[];
  };
}

export interface IOrderStatusQueryVariables {
  clubOrderGuid: string;
}
