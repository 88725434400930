import styles from "../../components/rating/Rating.module.scss";

interface IProps {
  value: number | number[];
  readonly?: boolean;
  onChange?: (rating: number) => void;
  largeStars?: boolean;
  totalRatings?: number | null;
}

const Rating = (props: IProps) => {
  // rating represents an array with a default length of 5,
  // with each index zeroed out as default. This represents a 5
  // star system, as each index correlates to a star rating + 1.
  // Can be used for vendor rating, etc.
  let rating: number | number[];
  if (props.value instanceof Array) {
    if (props.value.length < 5 && props.value.length > 0) {
      // If a rating array is less than five, set rating to a value between 0 and 5 based on some tricky math
      rating =
        Math.round(
          (props.value[props.value.length - 1] /
            props.value.reduce((result, x) => {
              return result + x;
            })) *
            5 *
            10
        ) / 10;
    } else if (props.value.length > 5) {
      // find average rating by dividing the total value of the votes by the total number of votes, and using some trickery to round and truncate some numbers.
      rating =
        Math.round(
          (props.value.reduce((total, x, index) => {
            return total + x * (index + 1);
          }) /
            props.value.reduce((total, x) => {
              return total + x;
            })) *
            10
        ) / 10;
    } else {
      rating = 0;
    }
  } else {
    //If rating is not an array, use whatever value was passed, or set it to 0.
    rating = props.value || 0;
  }

  // chooseRating is used when the stars are selectable and a function must be performed on a selected star
  const chooseRating = (val: number) => {
    if (props.onChange === undefined) return;
    else {
      props.onChange(val);
    }
  };

  const getStar = (num: number) => {
    const minHalf = num - 0.75; // 0.25 for star #1
    const minFull = num - 0.25; // 0.75 for star #1
    return (
      <div
        onClick={() => chooseRating(num)}
        className={
          props.largeStars
            ? rating >= minFull
              ? `${styles.large} ${styles.filled}`
              : rating >= minHalf
              ? `${styles.large} ${styles.half}`
              : `${styles.large} ${styles.outline}`
            : rating >= minFull
            ? styles.filled
            : rating >= minHalf
            ? styles.half
            : styles.outline
        }
      />
    );
  };

  return (
    <div
      className={`${styles["rating-container"]} ${!props.readonly ? styles.selectable : ""} ${
        props.totalRatings !== undefined ? styles["total-ratings"] : null
      }`}
    >
      {getStar(5)}
      {getStar(4)}
      {getStar(3)}
      {getStar(2)}
      {getStar(1)}
      {props.totalRatings !== undefined && props.totalRatings !== null ? (
        <div className={styles.text}>{props.totalRatings.toLocaleString()} Total Ratings</div>
      ) : null}
    </div>
  );
};

export default Rating;

// let valueOfAllVotes = props.value.reduce((total, x, index) => { return total + (x * (index + 1)) });
// let totalVotes = props.value.reduce((total, x) => { return total + x });
