import * as React from "react";
import useUniqueId from "../../hooks/useUniqueId";
import styles from "./Input.module.scss";
import { InputContainerContext } from "./InputContainerContext";
import Validation from "./Validation";

type IProps = (IPropsGeneral & ITextAreaProps) | (IPropsGeneral & IInputProps);

interface IPropsGeneral {
  id?: string;
  name?: string;
  title?: string;
  placeholder?: string | null;
  value?: string | number | null;
  inlineLabel?: boolean;
  textarea?: boolean;
  rows?: number;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  validationText?: string;
  autoFocus?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  noValidation?: boolean;
}

interface ITextAreaProps {
  textarea: true;
  height?: string;
  onBlurTextArea?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  inputRefTextArea?: React.RefObject<HTMLTextAreaElement>;
  onKeyPressTextArea?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
}

interface IInputProps {
  textarea?: false;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
  autoComplete?: string;
  type?: string;
  inputMode?: "numeric" | "decimal" | "search" | "text" | "url" | "email" | "tel";
}

const Input = (props: IProps) => {
  const context = React.useContext(InputContainerContext);

  const myId = useUniqueId();

  const pattern = props.pattern || (props.minLength ? ".{" + props.minLength + ",}" : undefined);

  //generate an id if none given
  const id = props.id || myId;
  const title = props.title || context.title;

  const inner = (
    <>
      <label
        className={
          title === undefined
            ? props.disabled
              ? props.required
                ? `${styles.disabled} hide`
                : "hide"
              : props.disabled
              ? `${styles.disabled}`
              : ""
            : props.required
            ? "required"
            : ""
        }
        htmlFor={id}
      >
        {title}
        {props.noValidation || !props.inlineLabel ? null : <div style={{ minHeight: "20px", visibility: "hidden" }}></div>}
      </label>

      <div>
        {props.textarea === true ? (
          <textarea
            className="form-control"
            name={props.name}
            id={id}
            value={props.value === null ? undefined : props.value}
            disabled={props.disabled}
            required={props.required}
            readOnly={props.readOnly}
            placeholder={props.placeholder === null ? undefined : props.placeholder}
            onChange={props.onChangeTextArea}
            onBlur={props.onBlurTextArea}
            onKeyPress={props.onKeyPressTextArea}
            ref={props.inputRefTextArea}
            autoFocus={props.autoFocus}
            minLength={props.minLength}
            maxLength={props.maxLength}
            rows={props.rows}
            style={props.height ? { minHeight: props.height } : undefined}
          />
        ) : (
          <input
            className="form-control"
            name={props.name}
            id={id}
            value={props.value === null ? "" : props.value}
            disabled={props.disabled}
            required={props.required}
            readOnly={props.readOnly}
            placeholder={props.placeholder === null ? "" : props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
            onKeyPress={props.onKeyPress}
            type={props.type}
            autoComplete={props.autoComplete}
            ref={props.inputRef}
            autoFocus={props.autoFocus}
            minLength={props.minLength}
            maxLength={props.maxLength}
            pattern={pattern}
            inputMode={props.inputMode}
          />
        )}
        {props.noValidation ? null : <Validation text={props.validationText ? props.validationText : "Required"} />}
      </div>
    </>
  );

  return (
    <div className={styles.container} style={{ marginBottom: 0 }}>
      {props.inlineLabel ? <div className={styles["horizontal-label"]}>{inner}</div> : inner}
    </div>
  );
};

export default Input;
