import { gql } from "@shane32/graphql";

export const CancelOrderMutation = gql`
  mutation ($id: ID!) {
    v1 {
      order {
        cancelOrder(id: $id)
      }
    }
  }
`;

export interface ICancelOrderMutationResult {
  v1: {
    order: {
      cancelOrder: boolean;
    };
  };
}

export interface ICancelOrderMutationVariables {
  id: string;
}
