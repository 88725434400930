//import * as React from 'react';
import * as ReactRouter from "react-router-dom";
import GenerateMeta from "../../helpers/GenerateMeta";
import ZboxLogo from "../../images/logo/Zbox-logo.png";

interface IRest {
  [propName: string]: {};
}

type RestType = IRest & { title: string; path?: string | undefined };

export const Route = (props: RestType) => {
  const { title, path, ...rest } = props;

  GenerateMeta.Data({
    title: title,
    description: title,
    path: path || document.location.pathname + document.location.search,
    image: {
      url: `${process.env.REACT_APP_PUBLIC_URL}${ZboxLogo}`,
      width: parseInt(process.env.REACT_APP_IMG_WIDTH || ""),
      height: parseInt(process.env.REACT_APP_IMG_HEIGHT || ""),
    },
  });

  return <ReactRouter.Route {...rest} />;
};
