import { LocationDescriptor } from "history";
import * as React from "react";
import { Link } from "react-router-dom";

interface ISidebarLinkProps {
  to: LocationDescriptor<any>;
  active?: boolean;
  expanded?: boolean;
}

const SidebarLink = (props: React.PropsWithChildren<ISidebarLinkProps>) => {
  return (
    <Link className={props.active ? "sidebar-nav-selected" : props.expanded ? "sidebar-nav-expanded" : undefined} to={props.to}>
      <span></span>
      {props.children}
    </Link>
  );
};

export const SidebarSecondLevel = (props: React.PropsWithChildren<{}>) => <div className="sidebar-second-level">{props.children}</div>;

export default SidebarLink;
