import { gql } from "@shane32/graphql";
import { ShoppingCartPartialQuery, IShoppingCart } from "../queries/ShoppingCartQuery";

export const CartSetLineReferenceMutation = gql`mutation($productId:ID!, $reference:String!, $shoppingCartTypeId:ID!){
  v1{
    cart{
      setLineReference(productId:$productId, reference: $reference, shoppingCartTypeId:$shoppingCartTypeId) ${ShoppingCartPartialQuery()}
    }
  }
}`;

export interface ICartSetLineReferenceResult {
  v1: {
    cart: {
      setLineReference: IShoppingCart;
    };
  };
}

export interface ICartSetLineReferenceVariables {
  productId: string;
  reference: string;
  shoppingCartTypeId: string;
}
