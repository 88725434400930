import { gql } from "@shane32/graphql";

export const EditPaymentMutation = gql`
  mutation ($paymentId: ID!, $address: AddressInputGraph, $nonce: String!, $deviceData: String!, $captchaResponse: String) {
    v1 {
      paymentMethod {
        edit(id: $paymentId, address: $address, nonce: $nonce, deviceData: $deviceData, captchaResponse: $captchaResponse) {
          id
          accountNameOrEmail
          address {
            id
            country {
              id
              name
            }
            firstName
            lastName
            company
            email
            address1
            address2
            city
            stateProvince {
              id
              name
              abbreviation
            }
            zipPostalCode
            phoneNumber
            createdOnUtc
          }
          paymentType
          creditCardType
          creditCardLast4
          creditCardExp
          displayOrder
          createdOnUtc
          validShippingAddresses {
            id
          }
        }
      }
    }
  }
`;

export interface IAddress {
  id: string;
  country: {
    id: string;
    name: string;
  };
  firstName: string;
  lastName: string;
  company: string | null;
  email: string;
  address1: string;
  address2: string | null;
  city: string;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  } | null;
  zipPostalCode: string;
  phoneNumber: string;
  createdOnUtc: string;
}

export interface IPaymentMethod {
  id: string;
  accountNameOrEmail: string | null;
  address: IAddress;
  paymentType: "CREDIT_CARD" | "PAY_PAL" | "APPLE_PAY" | "GOOGLE_PAY";
  creditCardType: string | null;
  creditCardLast4: string | null;
  creditCardExp: string | null;
  displayOrder: number;
  createdOnUtc: string;
  validShippingAddresses: Array<{ id: string }>;
}

export interface IEditPaymentMutationResult {
  v1: {
    paymentMethod: {
      edit: IPaymentMethod;
    };
  };
}

export interface IEditPaymentMutationVariables {
  paymentId: string;
  nonce: string;
  address: {
    countryId: string;
    firstName: string;
    lastName: string;
    company?: string | null;
    address1: string;
    address2?: string | null;
    city: string;
    stateProvinceId?: string | null;
    zipPostalCode: string;
    phoneNumber: string;
    emailAddress?: string | null;
  } | null;
  deviceData: string;
  captchaResponse: string | null;
}
