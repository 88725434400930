import { Link } from "react-router-dom";
import styles from "./PartsBooksComponents.module.scss";

const BackButton = (props: { to: string; className?: string; text: string | undefined }) => {
  return (
    <Link className={styles["link"] + " " + props.className ?? ""} to={props.to}>
      <div className={styles["link-chevron"] + " " + styles["not-hovered-over-chevron"]} />
      {props.text}
    </Link>
  );
};

export default BackButton;
