import { gql } from "@shane32/graphql";
import { ShoppingCartPartialQuery, IShoppingCart } from "../queries/ShoppingCartQuery";

export const RemoveProductFromCartMutation = gql`mutation($shoppingCartItemId:ID!, $shoppingCartTypeId:ID!) {
  v1 {
    cart {
      remove(shoppingCartItemId: $shoppingCartItemId, shoppingCartTypeId: $shoppingCartTypeId) ${ShoppingCartPartialQuery()}
    }
  }
}`;

export interface IRemoveProductFromCartResult {
  v1: {
    cart: {
      remove: IShoppingCart;
    };
  };
}

export interface IRemoveProductFromCartVariables {
  shoppingCartItemId: string;
  shoppingCartTypeId: string;
}
