import { AuthContext } from "@zboxglobal/zboxauth";
import React from "react";
import { Link } from "react-router-dom";
import Money from "../../../../components/Money/Money";
import { CartContext } from "../../../../contexts/CartContext";
import { shoppingCartRef } from "../../../../contexts/RefContextStore";
import GenerateLink from "../../../../helpers/GenerateLink";
import { IDiagramLineItem } from "../DiagramPage";
import styles from "./PartsList.module.scss";

const PartsList = (props: {
  items: IDiagramLineItem[];
  onNotesClick: (index: number) => void;
  productIdAnchor: string | undefined;
  diagramLoading: boolean;
  partsListRef: React.RefObject<HTMLDivElement>;
}) => {
  const [qtys, setQtys] = React.useState<{ [key: string]: number }>({});
  const cartContext = React.useContext(CartContext);
  const authContext = React.useContext(AuthContext);
  const cartRef = React.useContext(shoppingCartRef);
  const [disable, setdisabled] = React.useState(false);
  const partsListRef = props.partsListRef;

  React.useEffect(() => {
    if (partsListRef.current && props.productIdAnchor && !props.diagramLoading && !partsListRef.current.scrollTop) {
      const matchingElement = partsListRef.current.querySelector<HTMLDivElement>(`[data-product-id="${props.productIdAnchor}"]`);
      if (matchingElement !== null) {
        if (matchingElement.parentNode instanceof HTMLElement) {
          let offset = 0;
          matchingElement.parentNode.style.scrollBehavior = "smooth";
          matchingElement.parentNode.style.overscrollBehavior = "contain";
          for (let childNode of Array.from(matchingElement.parentNode.childNodes)) {
            //get the height of each child node and add it to the offset
            if (childNode instanceof HTMLElement) {
              if (childNode.dataset.productId === props.productIdAnchor) break;
              offset += childNode.offsetHeight;
            }
          }
          matchingElement.parentNode.scrollTop = offset;
        }
      }
    }
    //when the componenet is mounted, the scroll position is not set yet,
    //so we need to wait for it to be set, and the data to load, then scroll to the anchor
  }, [partsListRef?.current?.scrollTop, props.diagramLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCartClick = (productid: string) => {
    const quantity = qtys[productid];
    if (!quantity) return;
    AddToCart(productid, quantity);
  };

  const onIncrement = (partId: string) => {
    var newQty = qtys[partId] ? qtys[partId] + 1 : 1;
    setQtys({ ...qtys, [partId]: newQty });
  };

  const onDecrement = (partId: string) => {
    var newQty = qtys[partId] - 1;
    if (newQty < 0) return;
    setQtys({ ...qtys, [partId]: newQty });
    return newQty;
  };

  const onToZero = (partId: string) => {
    setQtys({ ...qtys, [partId]: 0 });
  };

  const ToPx = (num: number | string) => {
    return num + "px";
  };

  const addCartAnimation = (e: React.MouseEvent<HTMLButtonElement>) => {
    const circleDia = 20;
    const circleDiaPx = ToPx(circleDia);

    //the plus button
    var targetPos = e.currentTarget.getBoundingClientRect();

    //the cart button  in header
    const cartEle = cartRef?.current;
    if (!cartEle) {
      return;
    }

    var cart = cartRef.current.getBoundingClientRect();
    //destination
    const cartLeft = cart.left;
    const cartTop = cart.top;

    //starting point
    //stores left and top as data attributes so we can use them in animation
    let leftTrack = targetPos.left;
    let topTrack = targetPos.top;

    // const animatedEle = document.createElement("div");
    const animatedEle = document.createElement("div");
    animatedEle.style.position = "absolute";
    animatedEle.style.zIndex = "4";
    animatedEle.style.width = circleDiaPx;
    animatedEle.style.height = circleDiaPx;
    animatedEle.style.borderRadius = ToPx(circleDia / 2);
    animatedEle.style.backgroundColor = "#ffe81a";
    animatedEle.innerText = "1";
    animatedEle.style.lineHeight = circleDiaPx;
    animatedEle.style.textAlign = "center";

    //setting starting position
    animatedEle.style.left = ToPx(leftTrack);
    animatedEle.style.top = ToPx(topTrack);
    document.body.appendChild(animatedEle);

    const step = 12; // Amount to move per interval

    //animation code called by requestAnimationFrame`
    function animate() {
      //think of left as x and top as y
      const deltaLeft = cartLeft - leftTrack;
      const deltaTop = cartTop - topTrack;

      if (Math.abs(deltaLeft) < step && Math.abs(deltaTop) < step) {
        //called when  reached destination
        animatedEle.style.left = ToPx(cartLeft);
        animatedEle.style.top = ToPx(cartTop);
        animatedEle.remove();
        if (cartEle) {
          //makes cart shake and then removes shake class on animation end
          cartEle.classList.add("shake");
          cartEle.addEventListener("animationend", () => {
            cartEle.classList.remove("shake");
          });
        }
      } else {
        const angle = Math.atan2(deltaTop, deltaLeft);
        leftTrack = leftTrack + Math.cos(angle) * step;
        topTrack = topTrack + Math.sin(angle) * step;
        animatedEle.style.left = ToPx(leftTrack);
        animatedEle.style.top = ToPx(topTrack);

        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  };

  const onDecrementCart = (partId: string) => {
    let items = GetProductInCart(partId);
    if (!items) return;
    //sorts so back ordered is first
    let sortItem = items.sort((a) => (a.backordered ? -1 : 1));
    let item = sortItem[0];
    if (!item) return;

    let newQty = item.quantity - 1;

    if (newQty) {
      afterCode(() => cartContext.client.ChangeQuantity(item.id, newQty as number));
    } else {
      afterCode(() => cartContext.client.RemoveProduct(item.id, "1"));
    }
  };

  const GetProductInCart = (partId: string) => {
    return cartContext.cart?.productGroups.flatMap((x) => x.items).filter((x) => x.product.id === partId);
  };

  const afterCode = (func: () => Promise<any>) => {
    authContext.client.EnsureGuest(true).then(
      () => {
        return func().then(
          () => {
            setdisabled(false);
          },
          (ret) => {
            setdisabled(false);
            alert("Could not add product to cart");
            console.log("Failure adding product to cart -- addproduct failure", ret);
          }
        );
      },
      () => {
        setdisabled(false);
        alert("Could not add product to cart");
        console.log("Failure creating guest account");
        // setBuying(false);
      }
    );
  };

  const onIncrementCart = (e: React.MouseEvent<HTMLButtonElement>, partId: string) => {
    AddToCart(partId, 1);
    addCartAnimation(e);
  };

  const AddToCart = (productId: string, quantity: number) => {
    setdisabled(true);
    authContext.client.EnsureGuest(true).then(
      () => {
        return cartContext.client.AddProduct(productId, quantity).then(
          () => {
            setdisabled(false);
            onToZero(productId);
            // const order: IProductTracker = {
            //   id: props.product.id,
            //   name: props.product.name,
            //   price: props.product.priceAndAvailability.price || 0,
            //   brand: "",
            //   quantity: quantity,
            // };
            // tracker.addToCart(order);
            // history.push(GenerateLink.ForCart());
          },
          (ret) => {
            setdisabled(false);
            alert("Could not add product to cart");
            console.log("Failure adding product to cart -- addproduct failure", ret);
          }
        );
      },
      () => {
        setdisabled(false);
        alert("Could not add product to cart");
        console.log("Failure creating guest account");
      }
    );
  };

  return (
    <div ref={partsListRef} className={styles["partsList"]}>
      <div className={styles["listTitle"]}>Parts List</div>
      {props.items.map((x, i) => {
        const product = x.products.filter((x) => x.priceAndAvailability.status === "IN_STOCK")[0];
        var maxStockPurchaseAmount = 25;
        let cantAddMore = true;
        if (product) {
          const currentQty = qtys[product.id] || 0;
          if (product.priceAndAvailability) {
            if (product.priceAndAvailability.stock) {
              maxStockPurchaseAmount = product.priceAndAvailability.stock;
            }
          }
          cantAddMore = maxStockPurchaseAmount <= currentQty;
        }

        const category = product?.categories.reduce((max, current) => (current.displayOrder < max.displayOrder ? current : max));
        const cartItems = product?.id ? GetProductInCart(product.id) : null;
        const qty = cartItems && cartItems.length > 0 ? cartItems.reduce((partialSum, a) => partialSum + a.quantity, 0) : 0;
        const matchingProductIdAnchor = product?.id && product?.id === props.productIdAnchor;
        return (
          <div
            className={`${styles["partListItem"]} ${matchingProductIdAnchor ? styles["anchorBorder"] : ""}`.trim()}
            key={i}
            data-product-id={product?.id}
          >
            <div className={styles["topRow"]}>
              <div className={styles["pIndex"]}>{x.partDrawingIdentifier}</div>
              <div className={styles["req"]}>Qty Req: {x.quantity === 0 ? "N/A" : x.quantity}</div>
              <div className={styles["notes"]} onClick={() => props.onNotesClick(i)}>
                Notes
              </div>
              <div className={styles["price"]}>
                {product?.priceAndAvailability?.price == null ? (
                  <a href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`} className={styles["diagramCallButton"]}>
                    Call for price
                  </a>
                ) : (
                  <Money amount={product.priceAndAvailability.price} color={"black"}></Money>
                )}
              </div>
            </div>
            <div className={styles["bottomRow"]}>
              {product == null ? (
                <div>{x.partNumber}</div>
              ) : (
                <Link to={GenerateLink.ForProduct(product.id, product.seoName)}>
                  {category.category.name}
                  <br />
                  {x.partNumber}
                </Link>
              )}
              {product?.id && (
                <div className={styles["cartComp"]}>
                  {/* desktop counter not linked to cart */}
                  <div className={styles["cartQtyDesktop"]}>
                    <button disabled={disable} className={styles["subtract"]} onClick={() => onDecrement(product.id)}></button>
                    <div className={styles["counter"]}>{qtys[product.id] ? qtys[product.id] : 0}</div>
                    <button disabled={disable || cantAddMore} className={styles["add"]} onClick={() => onIncrement(product.id)}></button>
                  </div>
                  {/* mobile cart counter */}
                  <div className={`${styles["cartQtyMobile"]} ${qty === 0 ? styles["none"] : qty === 1 ? styles["one"] : styles["many"]}`}>
                    <button disabled={disable} className={styles["subtract"]} onClick={() => onDecrementCart(product.id)}></button>
                    <div className={styles["counter"]}>
                      {cartItems && cartItems.length > 0 ? cartItems.reduce((partialSum, a) => partialSum + a.quantity, 0) : 0}
                    </div>
                    <button
                      disabled={disable}
                      className={styles["add"]}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => onIncrementCart(e, product.id)}
                    ></button>
                  </div>

                  <button disabled={disable} className={styles["cartButtonDesktop"]} onClick={() => onCartClick(product.id)}>
                    <div></div>
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div className={styles["partListItem"]} style={{ height: "80px" }} key={-2}></div>
    </div>
  );
};

export default PartsList;
