import { Link } from "react-router-dom";
import image from "../../images/products/no-image-available.png";
import styles from "./CategoryCard.module.scss";

interface IProps {
  title: string;
  url: string;
  imageUrl: string | null;
  width?: number;
  carousel?: boolean;
  usingParent?: boolean;
  numberOfProducts?: number | null;
}

const CategoryCard = (props: IProps) => {
  let src = props.imageUrl !== null ? props.imageUrl : image;
  return (
    <Link
      to={props.url}
      className={props.carousel ? `${styles.categorycard} ${styles.carousel}` : props.usingParent ? undefined : styles.categorycard}
      style={{ width: props.width }}
    >
      <div style={{ height: props.width }}>
        <img src={src} alt={props.imageUrl ? (props.title ? props.title : "No Title") : "No Image Found"} />
      </div>
      <div>
        {props.title} <span className={props.numberOfProducts ? "" : "hide"}>({props.numberOfProducts})</span>
      </div>
    </Link>
  );
};

export default CategoryCard;
