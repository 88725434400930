import * as React from "react";
import Queries from "../queries/Queries";
import { IFleet } from "../queries/FleetQuery";
import { IGarageItemAdd } from "../mutations/FleetMutations/AddMachineMutation";
import { IGarageItemEdit } from "../mutations/FleetMutations/EditMachineMutation";
import { AuthContext } from "@zboxglobal/zboxauth";
import { FleetMutations } from "../mutations/FleetMutations/FleetMutations";

//todo reorganize how this fleetcontext works so it is more resposive and more performat
//this will also mean changing how some of the code uses the fleetcontext

export const FleetContext = React.createContext<IFleetContext>({
  client: {
    AddMachine: () => Promise.reject(),
    RemoveMachine: () => Promise.reject(),
    EditMachine: () => Promise.reject(),
    SetSelectedMachine: () => Promise.reject(),
    SetMachinePicture: () => Promise.reject(),
    ClearMachinePicture: () => Promise.reject(),
    FitmentCheck: () => Promise.reject(),
  },
  fleet: null,
  selectedMachine: null,
  loading: true,
});

type IFleetState = {
  isLoading: boolean;
};

export const FleetContainer = (props: { children?: React.ReactNode }) => {
  const authContext = React.useContext(AuthContext);
  const [fleetState, setFleetState] = React.useState<IFleetState>({
    isLoading: false,
  });
  const setIsLoading = (value: boolean) => {
    setFleetState({ isLoading: value });
  };

  //get fleet data
  //const history = useHistory();
  const { error, data, refetch } = Queries.useQueryFleet();
  const refresh = () => {
    setIsLoading(true);
    return refetch()
      .catch(() => {})
      .then(() => {
        setIsLoading(false);
      });
  };

  let fleet: IFleet[] | null;
  let selectedMachine: IFleet | null;
  if (!error && data && data.v1 && data.v1.my) {
    fleet = data.v1.my.garage;

    //find the id of the fleet in the 'fleet' array, and set that here
    const selectedId = data.v1.my.selectedGarageItem?.id;
    selectedMachine = (selectedId && fleet.filter((x) => x.id === selectedId)[0]) || null;
  } else {
    fleet = null;
    selectedMachine = null;
  }

  //change fleet data
  const [mutationAddMachine] = FleetMutations.useAddMachine();
  const [mutationEditMachine] = FleetMutations.useEditMachine();
  const [mutationRemoveMachine] = FleetMutations.useDeleteMachine();
  const [mutationSetSelectedMachine] = FleetMutations.useSetSelectedMachine();
  const [mutationSetMachinePicture] = FleetMutations.useSetMachinePicture();
  const [mutationClearMachinePicture] = FleetMutations.useClearMachinePicture();
  const [mutationFitmentCheck] = FleetMutations.useFitmentCheck();

  const addMachine = (garageItem: IGarageItemAdd, setSelected: boolean) => {
    setIsLoading(true);
    return authContext.client.EnsureGuest(true).then(() => {
      return mutationAddMachine({
        variables: { garageItem: garageItem, setSelected: setSelected },
      }).then(
        (data) => {
          console.log("Add graph data", data);
          refresh();
          const x = data.data!.v1.garage.add;
          const mach = {
            id: x.id,
            machineStatus: x.machineStatus,
            sortOrder: x.sortOrder,
            machineModel: {
              id: x.machineModel.id,
              name: x.machineModel.name,
              machineManufacturer: {
                id: x.machineModel.machineManufacturer?.id,
                name: x.machineModel.machineManufacturer?.name,
              },
            },
          } as IFleet;
          return mach;
        },
        (errorRet) => {
          setIsLoading(false);
          console.error(errorRet);
          return Promise.reject("Unable to add a machine");
        }
      );
    });
  };

  const editMachine = (garageId: string, garageItem: IGarageItemEdit) => {
    setIsLoading(true);
    const tempGarageItem = garageItem;
    if (tempGarageItem.machineAttachmentId === "none") tempGarageItem.machineAttachmentId = null;
    if (tempGarageItem.machineEngineId === "none") tempGarageItem.machineEngineId = null;
    return mutationEditMachine({
      variables: { garageItem: garageItem, garageId: garageId },
    }).then(
      (data) => {
        console.log("Edit graph data", data);
        refresh();
      },
      (errorRet) => {
        setIsLoading(false);
        console.error(errorRet);
        return Promise.reject("Unable to edit a machine.");
      }
    );
  };

  const removeMachine = (garageId: string) => {
    setIsLoading(true);
    return mutationRemoveMachine({
      variables: { garageId: garageId },
    }).then(
      (data) => {
        console.log("Remove graph data", data);
        refresh();
      },
      (errorRet) => {
        setIsLoading(false);
        console.error(errorRet);
        return Promise.reject("Unable to delete a machine.");
      }
    );
  };

  const setSelectedMachine = (garageId: string | null) => {
    setIsLoading(true);
    return mutationSetSelectedMachine({ variables: { id: garageId } }).then(
      (data) => {
        console.log("Set Graph Data", data);
        refresh();
      },
      (errorRet) => {
        console.error(errorRet);
        return Promise.reject("Unable to set selected machine.");
      }
    );
  };

  const setMachinePicture = (garageId: string, url: string | null, base64PictureData: string | null, mimeType: string | null) => {
    setIsLoading(true);
    return mutationSetMachinePicture({
      variables: {
        garageId: garageId,
        url: url,
        base64PictureData: base64PictureData,
        mimeType: mimeType,
      },
    }).then(
      (data) => {
        console.log("Set Machine Picture Data", data);
        refresh();
      },
      (errorRet) => {
        setIsLoading(false);
        console.error(errorRet);
        return Promise.reject("Unable to set machine picture.");
      }
    );
  };

  const clearMachinePicture = (garageId: string) => {
    setIsLoading(true);
    return mutationClearMachinePicture({
      variables: { garageId: garageId },
    }).then(
      (data) => {
        console.log("Clear Machine Picture Data", data);
        refresh();
      },
      (errorRet) => {
        setIsLoading(false);
        console.error(errorRet);
        return Promise.reject("Unable to clear machine picture.");
      }
    );
  };

  const fitmentCheck = (productId: string, garageId?: string) => {
    if (!garageId && selectedMachine?.id) {
      garageId = selectedMachine.id;
    } else {
      return Promise.reject("failed to check fitmet");
    }
    setIsLoading(true);
    return mutationFitmentCheck({
      variables: { garageId: garageId, productId: productId },
    }).then(
      (data) => {
        setIsLoading(false);
        return data.data?.v1.garage.fitmentCheck || false;
      },
      (errorRet) => {
        setIsLoading(false);
        console.error(errorRet);
        return Promise.reject("failed to check fitmet");
      }
    );
  };

  React.useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authContext.status.isRegistered, authContext.status.userInfo?.id]);

  const contextState: IFleetContext = {
    client: {
      AddMachine: addMachine,
      EditMachine: editMachine,
      RemoveMachine: removeMachine,
      SetSelectedMachine: setSelectedMachine,
      SetMachinePicture: setMachinePicture,
      ClearMachinePicture: clearMachinePicture,
      FitmentCheck: fitmentCheck,
    },
    fleet: fleet,
    selectedMachine: selectedMachine,
    loading: fleetState.isLoading,
  };

  return <FleetContext.Provider value={contextState}>{props.children}</FleetContext.Provider>;
};

export interface IFleetClient {
  AddMachine: (garageItem: IGarageItemAdd, setSelected: boolean) => Promise<IFleet>;
  EditMachine: (garageId: string, garageItem: IGarageItemEdit) => Promise<void>;
  RemoveMachine: (garageId: string) => Promise<void>;
  SetSelectedMachine: (garageId: string | null) => Promise<void>;
  SetMachinePicture: (garageId: string, url: string | null, base64PictureData: string | null, mimeType: string | null) => Promise<void>;
  ClearMachinePicture: (garageId: string) => Promise<void>;
  FitmentCheck: (productId: string, garageId?: string) => Promise<boolean>;
}

export interface IFleetContext {
  client: IFleetClient;
  fleet: IFleet[] | null;
  selectedMachine: IFleet | null;
  loading: boolean;
}
