import * as React from "react";

export default function useStructuredData(structuredData: any) {
  structuredData = JSON.stringify(structuredData);
  React.useEffect(() => {
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.appendChild(document.createTextNode(structuredData));
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [structuredData]);
}
