import styles from "./Money.module.scss";

const Money = (props: { amount: number; color: string }) => {
  const dollars = Math.floor(props.amount);
  const cents = Math.round((props.amount - dollars) * 100);
  return (
    <div className={styles.price + " h1b "}>
      <span>$</span>
      {dollars}
      <span>{cents}</span>
    </div>
  );
};

export default Money;
