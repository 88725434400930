import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";

// using non-authenticated link

export const ProductPageRelatedQuery = gql`
  query ($productId: ID) {
    v1 {
      product(id: $productId) {
        relatedProducts {
          displayOrder
          product {
            id
            name
            seoName
            approvedRatingSum
            approvedTotalReviews
            priceAndAvailability {
              allowedQuantities
              backorderAvailabilityRange
              canNotify
              oldPrice
              orderMaximumQuantity
              orderMinimumQuantity
              preorderAvailabilityStartDateTime
              price
              status
              stock
            }
            picture {
              thumbnail(size: 100) {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export interface IProductPageRelatedQueryVariables {
  productId: string;
}

export interface IProductPageRelatedQuery {
  v1: {
    product: {
      relatedProducts: IRelatedItem[];
    } | null;
  };
}

export interface IRelatedItem {
  displayOrder: number;
  product: IRelatedProduct;
}

export interface IRelatedProduct {
  id: string;
  name: string;
  seoName: string | null;
  approvedRatingSum: number;
  approvedTotalReviews: number;
  priceAndAvailability: IPriceAndAvailability;
  picture: {
    thumbnail: {
      url: string;
    };
  } | null;
}

// interface IPriceAndAvailability {
//     allowedQuantities: string | null;
//     backorderAvailabilityRange: string | null;
//     canNotify: boolean;
//     oldPrice: number | null;
//     orderMinimumQuantity: number;
//     orderMaximumQuantity: number;
//     preorderAvailabilityStartDateTime: string | null;
//     price: number | null;
//     status: AvailabilityStatusEnum;
//     stock: number | null;
// }

// enum AvailabilityStatusEnum {
//     Preorder = "PREORDER",
//     InStock = "IN_STOCK",
//     LowStock = "LOW_STOCK",
//     CanBackorder = "CAN_BACKORDER",
//     ContactSeller = "CONTACT_SELLER",
//     CallForPrice = "CALL_FOR_PRICE",
//     CallToOrder = "CALL_TO_ORDER",
//     BuildToOrder = "BUILD_TO_ORDER",
//     NotAvailable = "NOT_AVAILABLE",
//     Discontinued = "DISCONTINUED",
//     DropShip = "DROP_SHIP",
// }
