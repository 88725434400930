import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

interface IProps {
  className?: string;
  crumbs: IbreadCrumbs[] | null;
}

export interface IbreadCrumbs {
  url: string | null;
  name: string;
}

const GenericBreadCrumbs = (props: IProps) => {
  const crumbAmount = props.crumbs?.length ?? 0;
  return (
    <div className={props.className}>
      <div className={styles.container}>
        {props.crumbs !== null &&
          props.crumbs.map((x, index) => (
            <React.Fragment key={index}>
              {x.url === null ? <span className={styles.secondary}>{x.name}</span> : <Link to={x.url}>{x.name}</Link>}
              {crumbAmount - 1 !== index && <div className={`image-breadcrumbarrow`} />}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
};

export default GenericBreadCrumbs;
