import styles from "./ToolTip.module.scss";

interface IProps {
  children: any;
  message: string;
}

const ToolTip = (props: IProps) => {
  return (
    <div className={styles.tooltip}>
      {props.children}
      <span className={styles.tooltiptext}>{props.message}</span>
    </div>
  );
};

export default ToolTip;
