import * as React from "react";
import styles from "./RadioContainer.module.scss";
import Validation from "./Validation";

export interface IRadioGroup {
  name?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  removeValidation?: (checked: boolean) => void;
}

export const RadioContainerContext = React.createContext({} as IRadioGroup);

interface IHorizontal {
  horizontal?: true;
  vertical?: false;
}

interface IVertical {
  horizontal?: false;
  vertical?: true;
}

interface IMoreProps {
  name?: string;
  title?: string;
  value?: string;
  required?: boolean;
  disabled?: boolean;
  displayValidation?: boolean;
  validationText?: string;
  methodStyling?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  children: any;
}

type IProps = (IHorizontal | IVertical) & IMoreProps;

const RadioContainer = (props: IProps) => {
  const contextProps: IRadioGroup = {
    name: props.name,
    value: props.value,
    required: props.required,
    disabled: props.disabled,
    onChange: props.onChange,
  };

  return (
    <RadioContainerContext.Provider value={contextProps}>
      <div
        className={
          props.methodStyling
            ? props.horizontal
              ? `${styles["container"]} ${styles["method-styling"]}`
              : `${styles["container"]} ${styles["vertical"]} ${styles["method-styling"]}`
            : props.horizontal
            ? `${styles["container"]}`
            : `${styles["container"]} ${styles["vertical"]}`
        }
      >
        {props.children}
        <Validation text={props.validationText} displayValidation={props.required && props.value === undefined} />
      </div>
    </RadioContainerContext.Provider>
  );
};

export default RadioContainer;
