import "./Validation.scss";

interface IProps {
  text?: string;
  displayValidation?: boolean;
}

const Validation = (props: IProps) => {
  return props.text ? (
    <div className={"validation-container" + (props.displayValidation ? " validation-force-display" : "")}>
      {props.text !== undefined ? "* " + props.text : null}
    </div>
  ) : null;
};

export default Validation;

//KARISSA WAS HERE AND CHANGED THIS BC IT WAS NOT HIDING THE VALIDATION CONTAINER PROPERLY
//<div className={props.green ? "validation-container green" : "validation-container"}>
//	<span className={props.displayValidation ? "" : "hide"}>{props.text !== undefined ? '*' + props.text : null}</span>
//</div>
