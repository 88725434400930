import { ICheckoutPage } from "../contexts/CheckoutContext";

export default class GenerateRoute {
  static ForSearch = () => "/search";
  static ForMobileAutoCompleteSearch = () => "/searchSuggest";
  static ForMobileAutoCompleteSearchParam = () => "/searchSuggest/:search";
  // static ForMobileAutoCompleteSearch= () =>  "/(searchSuggest/:search|searchSuggest)";
  // "/searchSuggest/:search";
  static ForHome = () => "/";
  static ForAllCategory = () => "/category";
  static ForCategory = () => "/:seoCategory/c/:categoryId";
  static ForSearchCategory = () => "/:seoCategory/s/c/:categoryId"; //?sortby=price&page=1&perpage=20&filters=[["price",["0/10","10/25"]],["1234",["1","2"]]]
  static ForProduct = () => "/:seoProduct/p/:productId"; //?search=320+filter

  static MachineMfgsPage = () => "/mfg"; //?search=320+filter
  static ModelPage = () => "/mfg/:mfgId"; //?search=320+filter
  static MachinePage = () => "/m/:MachineModelId"; //?search=320+filter
  static MyMachinePage = () => "/m/:MachineModelId/mm/:GarageId"; //?search=320+filter
  static EditMachinePage = () => "/m/:MachineModelId/edit";
  static ForSearchProduct = () => "/search/s/p"; //?sortby=price&page=1&perpage=20&filters=[["price",["0/10","10/25"]],["1234",["1","2"]]]
  static ForSignIn = () => "/signin";
  static ForPasswordRecovery = () => "/passwordrecovery/confirm";
  static ForTeam = () => "/team";
  static ForCareer = () => "/career/:jobId";
  static ForApiTests = () => "/apitests";
  static ForAbout = () => "/about";
  static ForSellWithUs = () => "/sellwithus";
  static ForVendor = () => "/vendor";
  static ForAccount = () => "/account";
  static ForAccountSecurity = () => "/account/security";
  static ForSecurityEdit(linkType: "Name" | "Email" | "Password" | "Phone" | "Standard") {
    switch (linkType) {
      case "Name":
        return "/account/security/edit/name";
      case "Email":
        return "/account/security/edit/emailaddress";
      case "Password":
        return "/account/security/edit/password";
      case "Phone":
        return "/account/security/edit/phonenumber";
      case "Standard":
      default:
        return "/account/security/edit";
    }
  }
  static ForOrders = () => "/account/orders";
  static ForFilteredOrders = () => "/account/orders/:filter";
  static ForOrderDetails = () => "/account/order/:clubOrderGuid";
  static ForRma = () => "/account/rma/:packageId";
  static ForCancel = () => "/account/cancel/:packageId";
  static ForTrackPackage = (linkType: "Id" | "Standard") => (linkType === "Standard" ? "/account/track" : "/account/track/:packageId");
  static ForFeedback = (linkType: "Id" | "Standard") => (linkType === "Id" ? "/account/feedback/:orderId" : "/account/feedback/");
  static ForEmailNotifications = () => "/account/notifications";
  static ForPaymentMethods(linkType: "IdAndDelete" | "Id" | "Standard" | "Add") {
    switch (linkType) {
      case "Id":
        return "/account/paymentmethods/:methodId";
      case "Add":
        return "/account/paymentmethods/0/add";
      case "IdAndDelete":
        return "/account/paymentmethods/:methodId/delete";
      case "Standard":
      default:
        return "/account/paymentmethods";
    }
  }
  static ForAddressBook(linkType: "IdAndDelete" | "Id" | "Standard" | "Add") {
    switch (linkType) {
      case "Id":
        return "/account/addressbook/:addressId";
      case "Add":
        return "/account/addressbook/0/add";
      case "IdAndDelete":
        return "/account/addressbook/:addressId/delete";
      case "Standard":
      default:
        return "/account/addressbook";
    }
  }
  static ForCheckout(linkType: ICheckoutPage) {
    switch (linkType) {
      case "INITIALIZER":
        return "/checkout/initializer";
      case "SIGNIN":
        return "/checkout/signin";
      case "CHANGE_EMAIL":
        return "/checkout/changeemail";
      case "ORDER_REVIEW":
        return "/checkout/orderreview";
      case "PAYMENT_DETAILS_ADD":
        return "/checkout/payment/add";
      case "PAYMENT_DETAILS_EDIT":
        return "/checkout/payment/edit/:id";
      case "PAYMENT_LIST":
        return "/checkout/payment";
      case "SHIPPING_DETAILS_ADD":
        return "/checkout/shipping/add";
      case "SHIPPING_DETAILS_EDIT":
        return "/checkout/shipping/edit/:id";
      case "SHIPPING_LIST":
        return "/checkout/shipping";
      case "THANK_YOU":
        return "/checkout/thankyou/:clubOrderGuid";
      case null:
      default:
        return "/checkout";
    }
  }
  static MyFleet = () => "/MyFleet";

  static ForFleet = (linkType: "Add" | "Standard") => (linkType === "Standard" ? "/fleet" : "/fleet/add");
  static ForFleetRig(linkType: "Edit" | "Sell" | "Delete" | "Standard") {
    switch (linkType) {
      case "Edit":
        return "/fleet/rig/:rigId/edit";
      case "Sell":
        return "/fleet/rig/:rigId/sell";
      case "Delete":
        return "/fleet/rig/:rigId/delete";
      case "Standard":
      default:
        return "/fleet/rig/:rigId";
    }
  }
  static ForOrderStatus = () => "/orderstatus/:clubOrderGuid";
  static ForFleetRigsListings = () => "/fleet/rigslistings";

  static ForPartsBooks = () => "/partsbooks";
  static ForDiagramBrand = () => "/partsbooks/brand/:manufacturerId";
  static ForDiagram = () => "/:seoDiagram?/m/:modelId";

  static ForCart = () => "/cart";
  static ForCartReward = () => "/cart/:rewardCode";
  static ForHelp = () => "/(support|help)";
  static ForHelpOrders = () => "/help/orders";
  static ForHelpPayment = () => "/help/paymentsettings";
  static ForHelpReturns = () => "/help/returnsandrefunds";
  static ForHelpShipping = () => "/help/shippingpolicies";
  static ForHelpSitePolicies = () => "/help/sitepolicies";
  static ForHelpOther = () => "/help/other";
  static ForHelpContact = () => "/help/contactus";
  static ForHelpFaq = () => "/help/faq";
  static ForReviews = () => "/account/reviews";
  static ForAllCustomerReviews = () => "/product/reviews";
  static ForImpersonate = () => "/admin/impersonate/:userId";
  static ForUnimpersonate = () => "/admin/impersonate/none";
  static ForGraphiQL = () => "/graphiql";
  static ForDocumentation = (page?: string) => `/documentation${page ? `/${page}` : ""}`;
  static ForReturns(route: "ExtReturn" | "ExtReturn1" | "ExtReturns" | "ExtReturns2") {
    switch (route) {
      case "ExtReturn":
        return "/extreturn";
      case "ExtReturn1":
        return "/extreturn1";
      case "ExtReturns2":
        return "/extreturns2";
      case "ExtReturns":
      default:
        return "/extreturns";
    }
  }
  static ForQrCode = () => "/go/sku/:sku";
}
