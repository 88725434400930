import { NavLink } from "react-router-dom";
import GenerateLink from "../helpers/GenerateLink";
import * as React from "react";
import { AuthContext } from "@zboxglobal/zboxauth";

export default function ImpersonationBar() {
  const authContext = React.useContext(AuthContext);
  if (authContext.status.impersonating) {
    return (
      <div style={{ textAlign: "center" }}>
        Impersonating user {authContext.status.userInfo.id} -{" "}
        {authContext.status.userInfo.name ? authContext.status.userInfo.name + " - " : ""}
        {authContext.status.userInfo.email} - <NavLink to={GenerateLink.ForUnimpersonate()}>quit</NavLink>
      </div>
    );
  } else return null;
}
