import { useParams, useHistory } from "react-router-dom";
import GenerateLink from "../helpers/GenerateLink";
import * as React from "react";
import useEffectOnce from "../hooks/useEffectOnce";
import { AuthContext } from "@zboxglobal/zboxauth";

export default function Impersonate() {
  const params = useParams<{ userId?: string }>();
  const authContext = React.useContext(AuthContext);
  const history = useHistory();
  const userId = params.userId === "none" ? undefined : params.userId;
  useEffectOnce(() => {
    const promise = userId ? authContext.client.Impersonate(userId) : authContext.client.ImpersonateQuit();
    promise
      .catch(() => {})
      .then(() => {
        history.replace(GenerateLink.ForHome());
      });
  });
  return <p>Please wait...</p>;
}
