import * as React from "react";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import useStructuredData from "../../hooks/useStructuredData";
import styles from "./Breadcrumb.module.scss";

interface ICategory {
  id: string;
  name: string;
  seoName: string | null;
  parentCategoryId: string | null;
}

interface IProps {
  selectedCategoryId: string | null;
  categories: ICategory[] | null;
  lastIsLink?: boolean;
  lastCategoryIsSearch?: boolean;
}

const Breadcrumb = (props: React.PropsWithChildren<IProps>) => {
  const categoryTree = React.useMemo(() => {
    return getBreadcrumbTrail(props.categories || [], props.selectedCategoryId, props.lastCategoryIsSearch);
  }, [props.categories, props.selectedCategoryId, props.lastCategoryIsSearch]);

  useStructuredData({
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: categoryTree
      .slice(0)
      .reverse()
      .map((category, index) => {
        return {
          "@type": "ListItem",
          position: ++index,
          name: category.name,
          item: `${process.env.REACT_APP_PUBLIC_URL}${category.url}`,
        };
      }),
  });

  return React.useMemo(
    () => (
      <div className={styles.container}>
        {categoryTree.map((cat, index) => (
          <React.Fragment key={cat.id}>
            {index === categoryTree.length - 1 ? (
              props.lastIsLink ? (
                <Link to={cat.url}>{cat.name}</Link>
              ) : (
                <span className={styles.secondary}>{cat.name}</span>
              )
            ) : (
              <>
                <Link to={cat.url} className={index === 0 || index === 2 ? "show-md" : undefined}>
                  {cat.name}
                </Link>
                <div className={`image-breadcrumbarrow${index === 0 || index === 2 ? " show-md" : ""}`} />
              </>
            )}
          </React.Fragment>
        ))}
        {props.children}
      </div>
    ),
    [categoryTree, props.children, props.lastIsLink]
  );
};

export function getBreadcrumbTrail(categories: ICategory[], selectedCategoryId: string | null, lastIsSearch?: boolean) {
  const categoryTree: Array<{ id: string; url: string; name: string }> = [];
  const findCategory = (catId: string | null) => (catId ? categories.filter((x) => x.id === catId)[0] || null : null);
  const selectedCategory = findCategory(selectedCategoryId || null);
  let first = true;
  const addCategory = (cat: ICategory) => {
    if (categoryTree.length > 20) throw Error(`Detected looping category tree at category id ${cat.id}`);
    categoryTree.push({
      id: cat.id,
      url: first && lastIsSearch ? GenerateLink.ForSearchCategory(cat.id, cat.seoName) : GenerateLink.ForCategory(cat.id, cat.seoName),
      name: cat.name,
    });
    first = false;
    const parentCategory = findCategory(cat.parentCategoryId);
    if (parentCategory) addCategory(parentCategory);
  };

  if (selectedCategory) addCategory(selectedCategory);

  categoryTree.push({
    id: "0",
    url: GenerateLink.ForHome(),
    name: "All Categories",
  });
  categoryTree.reverse();

  return categoryTree;
}

export default Breadcrumb;
