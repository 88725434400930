import Dayjs from "dayjs";
import { AvailabilityStatusEnum } from "../../queries/ProductPageQuery";
import styles from "./StockStatus.module.scss";

interface IProps {
  status: AvailabilityStatusEnum;
  warehouse?: boolean;
  date?: string | null;
  backOrderAvailibilityRange?: string | null;
}

const StockStatus = (props: IProps) => {
  const chooseStatus = (status: AvailabilityStatusEnum) => {
    switch (status) {
      case AvailabilityStatusEnum.BuildToOrder:
        return (
          <div className={styles.uks}>
            Build to Order {props.backOrderAvailibilityRange ? <div>Estimated {props.backOrderAvailibilityRange}</div> : null}
          </div>
        );
      case AvailabilityStatusEnum.CanBackorder:
        return (
          <div className={styles.oos}>
            Currently Out Of Stock {props.backOrderAvailibilityRange ? <div>Restock in {props.backOrderAvailibilityRange}</div> : null}
          </div>
        );
      case AvailabilityStatusEnum.Backordered:
        return <div className={styles.uks}>Backordered</div>;
      case AvailabilityStatusEnum.ContactSeller:
        return <div className={styles.uks}>Unknown Stock</div>;
      case AvailabilityStatusEnum.CallForPrice:
        return (
          <div className={styles.oos}>
            Call For Price: <div>{process.env.REACT_APP_PHONE_NUMBER}</div>
          </div>
        );
      case AvailabilityStatusEnum.Discontinued:
        return <div className={styles.oos}>Discontinued</div>;
      case AvailabilityStatusEnum.DropShip:
        return (
          <div className={styles.ins}>
            In Stock and Drop Ship {props.backOrderAvailibilityRange ? <div>Estimated {props.backOrderAvailibilityRange}</div> : null}
          </div>
        );
      case AvailabilityStatusEnum.InStock:
        return (
          <div>
            <span className={styles.ins}>In Stock</span>
            <span className={props.warehouse ? "hide-xl b1r" : "hide"}> and shipping from: </span>
          </div>
        );
      case AvailabilityStatusEnum.LowStock:
        return <div className={styles.ins}>Low Stock</div>;
      case AvailabilityStatusEnum.Preorder:
        return <div className={styles.oos}>Availability: {props.date !== null ? Dayjs(props.date).format("MMMM Do") : "Unknown"}</div>;
      default:
        return (
          <div className={styles.uks}>
            Not Available {props.backOrderAvailibilityRange ? <div>Estimated {props.backOrderAvailibilityRange}</div> : null}
          </div>
        );
    }
  };

  return <div className="b1b">{chooseStatus(props.status)}</div>;
};

export default StockStatus;
