import { createInsightsMiddleware } from "instantsearch.js/es/middlewares";
import { useLayoutEffect } from "react";
import { useInstantSearch } from "react-instantsearch-hooks-web";

declare const window: Window & {
  aa: any;
};

export default function InsightsMiddleware() {
  const { use } = useInstantSearch();
  useLayoutEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: window.aa ? window.aa : null,
    });

    return use(middleware);
  }, [use]);

  return null;
}
