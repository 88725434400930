import * as React from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import CategoryCard from "../../components/cards/CategoryCard";
import categoryCardStyles from "../../components/cards/CategoryCard.module.scss";
import Sidebar from "../../components/sidebars/Sidebar";
import SidebarLink, { SidebarSecondLevel } from "../../components/sidebars/SidebarLink";
import GenerateLink from "../../helpers/GenerateLink";
import GenerateMeta from "../../helpers/GenerateMeta";
import ZboxLogo from "../../images/logo/Zbox-logo.png";
import { ICategoryAndPicture } from "../../queries/CategoriesQuery";
import styles from "./CategoryDisplay.module.scss";
import CategoryDisplayMobile from "./CategoryDisplayMobile";

interface IParams {
  categoryId?: string;
}

interface IProps {
  fullCategories: ICategoryAndPicture[];
}

const CategoryDisplay = React.memo((props: IProps) => {
  const params = useParams<IParams>();
  //const history = useHistory();
  //for homepage, only show categories intended to show on home page
  const homepageCategories = props.fullCategories.filter((y) => y.showOnHomePage);

  //for main page, sort by: display order, alphabetical sorting, id
  const sortedCat = homepageCategories
    .slice(0)
    .sort((a, b) =>
      a.displayOrder > b.displayOrder
        ? 1
        : a.displayOrder < b.displayOrder
        ? -1
        : a.name > b.name
        ? 1
        : a.name < b.name
        ? -1
        : a.id > b.id
        ? 1
        : -1
    );

  //for left side bar, sort by: name, id
  const sortedCatName = homepageCategories.slice(0).sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : a.id > b.id ? 1 : -1));

  const selectedCategoryId = params.categoryId || null;
  const selectedCategory = selectedCategoryId ? props.fullCategories.filter((x) => x.id === selectedCategoryId)[0] || null : null;
  const selectedParentCategoryId = selectedCategory?.parentCategoryId || null;

  GenerateMeta.Data({
    title: (selectedCategory ? selectedCategory.name : process.env.REACT_APP_TAGLINE) || "",
    description:
      (selectedCategory
        ? selectedCategory.name + " at Zbox include many results in our easy to use parts catalog."
        : process.env.REACT_APP_TAGLINE) || "",
    path: document.location.pathname + document.location.search,
    image: {
      url: `${process.env.REACT_APP_PUBLIC_URL}${ZboxLogo}`,
      width: parseInt(process.env.REACT_APP_IMG_WIDTH || ""),
      height: parseInt(process.env.REACT_APP_IMG_HEIGHT || ""),
    },
  });

  return (
    <div className={styles.container}>
      <Sidebar showArrow>
        {sortedCatName
          .filter((topLevel) => topLevel.parentCategoryId === null)
          .map((topLevel) => (
            <React.Fragment key={topLevel.id}>
              <SidebarLink
                to={selectedCategoryId === topLevel.id ? GenerateLink.ForHome() : GenerateLink.ForCategory(topLevel.id, topLevel.seoName)}
                active={selectedCategoryId === topLevel.id}
                expanded={selectedParentCategoryId === topLevel.id}
              >
                {topLevel.name}
              </SidebarLink>
              {selectedCategoryId === topLevel.id || selectedParentCategoryId === topLevel.id ? (
                <SidebarSecondLevel>
                  {sortedCatName
                    .filter((secondLevel) => secondLevel.parentCategoryId === topLevel.id)
                    .map((secondLevel) => (
                      <SidebarLink
                        key={secondLevel.id}
                        to={
                          selectedCategoryId === secondLevel.id
                            ? GenerateLink.ForCategory(topLevel.id, topLevel.seoName)
                            : GenerateLink.ForCategory(secondLevel.id, secondLevel.seoName)
                        }
                        active={selectedCategoryId === secondLevel.id}
                      >
                        {secondLevel.name}
                      </SidebarLink>
                    ))}
                </SidebarSecondLevel>
              ) : null}
            </React.Fragment>
          ))}
      </Sidebar>
      <div className={`${styles.categories} ${categoryCardStyles.homepagecategories}`}>
        <Breadcrumb selectedCategoryId={selectedCategoryId} categories={props.fullCategories} />
        {sortedCat
          .filter(
            (topLevel) =>
              topLevel.parentCategoryId === null /*&& topLevel.NumberOfProducts > 0*/ &&
              (selectedCategoryId
                ? selectedParentCategoryId
                  ? selectedParentCategoryId === topLevel.id
                  : selectedCategoryId === topLevel.id
                : true)
          )
          .map((topLevel) => (
            <React.Fragment key={topLevel.id}>
              <h2>{topLevel.name}</h2>
              {sortedCat
                .filter(
                  (secondLevel) =>
                    secondLevel.parentCategoryId === topLevel.id &&
                    (selectedCategoryId === topLevel.id ||
                      ((selectedParentCategoryId ? selectedParentCategoryId === topLevel.id : true) &&
                        (selectedCategoryId
                          ? selectedCategoryId === selectedParentCategoryId
                            ? true
                            : selectedCategoryId === secondLevel.id
                          : true)))
                )
                .map((secondLevel) => (
                  <React.Fragment key={secondLevel.id}>
                    <h3>{secondLevel.name}</h3>
                    <div>
                      {sortedCat
                        .filter((thirdLevel) => thirdLevel.parentCategoryId === secondLevel.id)
                        .map((thirdLevel) => (
                          <CategoryCard
                            key={thirdLevel.id}
                            title={thirdLevel.name}
                            imageUrl={thirdLevel.picture !== null ? thirdLevel.picture.thumbnail.url : null}
                            url={GenerateLink.ForSearchCategory(thirdLevel.id, thirdLevel.seoName)}
                            usingParent
                          />
                        ))}
                    </div>
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </div>
      <CategoryDisplayMobile fullCategories={props.fullCategories} />
      {/* <div className={styles.mobile}>
                    {!selectedCategoryId ? '' : <AlternativeNav showNav buttonText="Back" onClick={() => history.goBack()} title={selectedCategory?.name || "Categories"} />}
                    <CategoryList categories={sortedCat} selectedCategoryId={selectedCategoryId} />
            </div>*/}
    </div>
  );
});

export default CategoryDisplay;
