import { gql } from "@shane32/graphql";

export const DeleteAddressMutation = gql`
  mutation ($addressId: ID!) {
    v1 {
      shippingAddress {
        delete(id: $addressId)
      }
    }
  }
`;

export interface IDeleteAddressMutationResult {
  v1: {
    shippingAddress: {
      delete: boolean;
    };
  };
}

export interface IDeleteAddressMutationVariables {
  addressId: string;
}
