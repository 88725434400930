import SeeAll from "./SeeAll";
import styles from "./SeeAll.module.scss";
const SeeAllTitle = (props: { children: React.ReactNode; to: string }) => {
  return (
    <div className={styles["container"]}>
      <div>{props.children}</div>
      <SeeAll to={props.to} arrow />
    </div>
  );
};

export default SeeAllTitle;
