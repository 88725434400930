import { ReactNode } from "react";
import { IProductSearchResult, IWarehouse } from "../../models/product/IProductSearchResult";
import { IRelatedProduct } from "../../queries/ProductPageRelatedQuery";
import ItemContainer from "../items/ItemContainer";
import ProductCarousel from "../ProductCarousel/ProductCarousel";

// export type ICollectionType = "COOKIE" | "NOP" | "Algolia";
export type ICollectionLayout = "Vertical" | "Carousel";
// interface IPorpsVertical  {
//     layout: "Vertical";
//     products: ICollectionData[];
// }

interface IProps {
  layout: ICollectionLayout;
  products: ICollectionData[];
  title: ReactNode;
  seeMoreTo: string;
}
export interface ICollectionData extends IRelatedProduct {
  warehouse: IWarehouse | null;
}

// type IProps = (IPorpsVertical| IPorpsCarousel);
const Collection = (props: IProps) => {
  return (
    <div>
      {
        {
          Vertical: <Vertical title={props.title} products={props.products}></Vertical>,
          Carousel: <ProductCarousel seeMoreTo={props.seeMoreTo} relatedItems={props.products} title={props.title} />,
        }[props.layout]
      }
    </div>
  );
};

const Vertical = (props: { products: any[]; title: ReactNode }) => {
  return (
    <>
      {props.products && (
        <>
          {props.title}
          {props.products.map((i) => {
            const g: IProductSearchResult = {
              approvedRatingSum: i.approvedRatingSum,
              additionalShippingCharge: 0,
              id: i.id,
              name: i.name,
              seoName: i.seoName,
              partInterchange: [],
              priceAndAvailability: i.priceAndAvailability,
              manufacturerPartNumber: null,
              manufacturers: [],
              isFreeShipping: false,
              picture: i.picture,
              warehouse: i.warehouse,
            };
            return (
              <div key={i.id}>
                <ItemContainer listingType="SEARCH_RESULT" item={g} searchString={""} interchange={null} />
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default Collection;
