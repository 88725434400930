import * as React from "react";
import useUniqueId from "../../hooks/useUniqueId";
import styles from "./Checkbox.module.scss";
import { CheckboxContainerContext } from "./CheckboxContainerContext";

interface IProps {
  id?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  children?: any;
  className?: string;
  //required?: boolean;
}

const Checkbox = (props: IProps) => {
  const context = React.useContext(CheckboxContainerContext);
  const myId = useUniqueId();

  const onChangeForm = (e: React.FormEvent<HTMLInputElement>) => {
    if (context.onChange) context.onChange(e.currentTarget.checked, e.currentTarget.value);
  };

  //generate an id if none given
  const id = props.id || myId;

  //default value to "true" if none given
  const value = props.value || "true";

  //1. if checked is not undefined, use the value (true or false)
  //2. if we have context.values, return true or false depending on if it's in the list
  //3. otherwise return undefined
  const checked = props.checked === undefined ? context.values && context.values.indexOf(value) >= 0 : props.checked;

  //use this.props.name if it is defined, otherwise this.context.name
  const name = props.name || context.name;

  //1. if props.onChange, then use it
  //2. if context.onChange, then use this.onChange
  //3. else return undefined
  const onChange = props.onChange || (context.onChange && onChangeForm);
  //same as this code:
  //const onChange = this.props.onChange ? this.props.onChange : (this.context.onChange ? this.onChange : undefined);
  //const required = this.props.required || this.context.required;

  return (
    <div className={styles.container + " " + (props.className || "")}>
      <input
        className={context.displayValidation ? `${styles.checkbox} form-control` : `${styles.checkbox} remove-required form-control`}
        id={id}
        value={value}
        type="checkbox"
        required={context.required}
        disabled={props.disabled}
        onChange={onChange}
        checked={checked}
        name={name}
      />
      <label htmlFor={id}>{props.children}</label>
    </div>
  );
};

export default Checkbox;
