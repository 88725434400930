import { gql } from "@shane32/graphql";

export const AllCustomerReviewsQuery = gql`
  query ($productId: ID) {
    v1 {
      productReviews(id: $productId) {
        createdOnUtc
        customerNotifiedOfReply
        helpfulNoTotal
        helpfulYesTotal
        id
        isApproved
        productReviewHelpfulnessEntries {
          id
          wasHelpful
        }
        productReviewReviewTypeMappingEntries {
          rating
          reviewType {
            description
            displayOrder
            id
            isRequired
            name
            visibleToAllCustomers
          }
        }
        rating
        replyText
        reviewText
        store {
          companyAddress
          companyName
          companyPhoneNumber
          companyVat
          defaultLanguage {
            defaultCurrency {
              createdOnUtc
              currencyCode
              customFormatting
              displayLocale
              displayOrder
              id
              name
              rate
              roundingType
              updatedOnUtc
            }
            displayOrder
            flagImageFileName
            id
            languageCulture
            name
            rtl
            uniqueSeoCode
          }
          hosts
          id
          name
          sslEnabled
          url
        }
        title
      }
    }
  }
`;

export interface ProductReview {
  createdOnUtc: Date;
  customerNotifiedOfReply: boolean;
  helpfulNoTotal: number;
  helpfulYesTotal: number;
  id: string;
  isApproved: boolean;
  productReviewHelpfulnessEntries: IProductReviewHelpfulnessEntries[];
  productReviewReviewTypeMappingEntries: IProductReviewReviewTypeMappingEntries[];
  rating: number;
  replyText: string | null;
  reviewText: string;
  store: Store;
  title: string;
}

interface IProductReviewHelpfulnessEntries {
  id: string;
  wasHelpful: boolean;
}

interface IProductReviewReviewTypeMappingEntries {
  rating: number;
  reviewType: IReviewType;
}

interface IReviewType {
  description: string;
  displayOrder: number;
  id: string;
  isRequired: boolean;
  name: string;
  visibleToAllCustomers: boolean;
}

interface Store {
  companyAddress: string;
  companyName: string;
  companyPhoneNumber: string;
  companyVat: null;
  defaultLanguage: null;
  hosts: string;
  id: string;
  name: string;
  sslEnabled: boolean;
  url: string;
}

export interface IAllCustomerReviewsQuery {
  v1: {
    productReviews: ProductReview[];
  };
}

export interface IAllCustomerReviewsQueryVariables {
  productId: string;
}
