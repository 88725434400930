import { gql } from "@shane32/graphql";

export const EditAddressQuery = gql`
  query ($addressId: ID!) {
    v1 {
      my {
        shippingAddress(id: $addressId) {
          id
          country {
            id
            name
          }
          firstName
          lastName
          company
          email
          address1
          address2
          city
          stateProvince {
            id
            name
            abbreviation
          }
          zipPostalCode
          phoneNumber
          createdOnUtc
        }
      }
    }
  }
`;

export interface IEditAddressQuery {
  v1: {
    my: {
      shippingAddress: IAddress | null;
    } | null;
  };
}

export interface IAddress {
  id: string;
  country: {
    id: string;
    name: string;
  };
  firstName: string;
  lastName: string;
  company: string | null;
  email: string;
  address1: string;
  address2: string | null;
  city: string;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  } | null;
  zipPostalCode: string;
  phoneNumber: string;
  createdOnUtc: string;
}

export interface IEditAddressBookQueryVariables {
  addressId: string;
}
