import { gql } from "@shane32/graphql";

export const LeaveFeedbackQuery = gql`
  query ($orderId: ID!) {
    v1 {
      my {
        order(id: $orderId) {
          createdOnUtc
          orderItems {
            id
            itemWeight
            orderItemGuid
            product {
              id
              name
              seoName
              manufacturerPartNumber
              manufacturers {
                displayOrder
                manufacturer {
                  displayOrder
                  id
                  name
                  picture {
                    imageUrl
                  }
                }
              }
              picture {
                thumbnail(size: 100) {
                  url
                }
              }
              shipSeparately
              sku
              vendor {
                id
                name
              }
            }
            quantity
          }
          orderStatus
        }
      }
    }
  }
`;

export interface ILeaveFeedbackQuery {
  v1: {
    my: {
      order: IOrder;
    };
  };
}

export interface ILeaveFeedbackQueryVariables {
  orderId: string;
}

export interface IOrder {
  createdOnUtc: string;
  orderItems: Array<{
    itemWeight: number | null;
    orderItemGuid: string;
    product: {
      id: string;
      name: string;
      seoName: string | null;
      additionalShippingCharge: number;
      manufacturerPartNumber: string;
      manufacturers: Array<{
        displayOrder: number;
        manufacturer: {
          displayOrder: number;
          id: string;
          name: string;
          picture: {
            imageUrl: string;
          } | null;
        };
      }>;
      picture: {
        thumbnail: {
          url: string;
        };
      };
      shipSeparately: boolean;
      sku: string | null;
      vendor: {
        id: string;
        name: string;
      } | null;
    };
    quantity: number;
  }>;
  orderStatus: string;
}
