import { Link } from "react-router-dom";
import styles from "./SeeAll.module.scss";

const SeeAll = (props: { to: string; arrow: boolean }) => {
  return (
    <Link style={{ display: "flex" }} to={props.to}>
      See All
      {props.arrow && <div className={styles["arrow"]} />}
    </Link>
  );
};

export default SeeAll;
