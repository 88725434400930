import { IAuthenticationClient } from "@zboxglobal/zboxauth";
import { IGraphQLClient } from "@shane32/graphql";

export const GraphQlBaseUrl = "api/graphql";

export const ApiBaseUrl = process.env.REACT_APP_API_URL || "";
export const AdminBaseUrl = process.env.REACT_APP_ADMIN_URL || "";
export const BuildVersion = process.env.REACT_APP_VERSION || "";
export const isProduction = !!Number(process.env.REACT_APP_IS_PRODUCTION || "1");
export const isTest = { value: false };
export const GoogleRecaptchaSiteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || "";

export const navPushedOrReplaced: Array<(url: string) => void> = [];

// setup navPushedOrReplaced
const oldPushState = window.history.pushState.bind(window.history);
window.history.pushState = (data: any, title: string, url?: string | null | undefined) => {
  oldPushState(data, title, url);
  if (url) {
    navPushedOrReplaced.forEach((callback) => {
      callback(url);
    });
  }
};
const oldReplaceState = window.history.replaceState.bind(window.history);
window.history.replaceState = (data: any, title: string, url?: string | null | undefined) => {
  oldReplaceState(data, title, url);
  if (url) {
    navPushedOrReplaced.forEach((callback) => {
      callback(url);
    });
  }
};

export const InitializeAuthListener = (auth: IAuthenticationClient, client: IGraphQLClient) => {
  // setup status subscriptions
  let oldStatus = auth.GetStatus();
  //when logged-in status changes, reset apollo cache
  auth.Status.subscribe(() => {
    //note: all queries currently executing will report an error temporarily while the store is resetting
    //  so, only reset the store when the id of the person changes (or the person logs in/out)
    const status = auth.GetStatus();
    if (
      status.loggedIn !== oldStatus.loggedIn ||
      (status.userInfo &&
        oldStatus.userInfo &&
        (status.userInfo.id !== oldStatus.userInfo.id || status.userInfo.roles !== oldStatus.userInfo.roles))
    ) {
      client.ResetStore(); //do not wait for async operation to complete
    }
    oldStatus = status;
  });
};
