import styles from "./ModalHeader.module.scss";

interface IProps {
  onClose: () => void;
  children: any;
}

const ModalHeader = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{props.children}</div>
      <button className={styles.close} onClick={props.onClose}></button>
    </div>
  );
};
export default ModalHeader;
