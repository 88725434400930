import styles from "./FarmConstruction.module.scss";
const FarmConstruction = () => {
  return (
    <div className={styles["body"]}>
      <div className={styles["title"]}>
        <h1>Search - Buy - Repair</h1>
        <p>Search our catalog of over 30,000</p>
        <p>parts covering many OEM brands.</p>
      </div>
      <div className={styles["con"]} />
      <div className={styles["farm"]} />
    </div>
  );
};

export default FarmConstruction;
