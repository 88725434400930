import { gql } from "@shane32/graphql";

export const ChangeFeedbackNameMutation = gql`
  mutation ($feedbackName: String!) {
    v1 {
      loginAndSecurity {
        changeFeedbackName(feedbackName: $feedbackName)
      }
    }
  }
`;

export interface IChangeFeedbackNameMutationResult {
  v1: {
    loginAndSecurity: {
      changeFeedbackName: string;
    };
  };
}

export interface IChangeFeedbackNameMutationVariables {
  feedbackName: string;
}
