import { gql } from "@shane32/graphql";

export const SetDefaultPaymentMutation = gql`
  mutation ($paymentId: ID!) {
    v1 {
      paymentMethod {
        setDefault(id: $paymentId)
      }
    }
  }
`;

export interface ISetDefaultPaymentMutationResult {
  v1: {
    paymentMethod: {
      setDefault: boolean;
    };
  };
}

export interface ISetDefaultPaymentMutationVariables {
  paymentId: string;
}
