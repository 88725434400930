import { gql } from "@shane32/graphql";

export const ChangeNumberMutation = gql`
  mutation ($phoneNumber: String!) {
    v1 {
      loginAndSecurity {
        changeNumber(phoneNumber: $phoneNumber)
      }
    }
  }
`;

export interface IChangeNumberMutationResult {
  v1: {
    loginAndSecurity: {
      changeNumber: number;
    };
  };
}

export interface IChangeNumberMutationVariables {
  phoneNumber: string;
}
