import * as React from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import GenerateLink from "../../helpers/GenerateLink";
import DefaultSuspense from "../loading/DefaultSuspense";
import SideBarPopOut from "../SideBarPopOut/SideBarPopOut";
import styles from "./FleetSideBar.module.scss";

const FleetSideBarBody = React.lazy(() => import("./FleetSideBarBody"));

const FleetSideBar = () => {
  const globalContext = React.useContext(GlobalContext);
  const closeOverlay = () => {
    globalContext.setFitmentSideBar("ClOSED");
  };

  return (
    <SideBarPopOut close={closeOverlay} open={globalContext.fitmentSideBarState !== "ClOSED"}>
      <div className={styles["MyFleetSideBar"]}>
        <div className={styles["MyFleet-Container"]}>
          <div className="d-flex">
            <div className={styles["rig-icon"]} />
            <h2>My Fleet</h2>
          </div>
          <div className={styles["name-container"]}>
            <Link onClick={closeOverlay} to={GenerateLink.ForMyFleet()}>
              Manage Your Fleet
            </Link>
          </div>
        </div>
        <DefaultSuspense>
          <FleetSideBarBody />
        </DefaultSuspense>
      </div>
    </SideBarPopOut>
  );
};

export default FleetSideBar;
