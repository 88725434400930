import { gql } from "@shane32/graphql";
import { ShoppingCartPartialQuery, IShoppingCart } from "../queries/ShoppingCartQuery";

export const ChangeQuantityInCartMutation = gql`mutation($shoppingCartItemId: ID!, $shoppingCartTypeId: ID!, $qty: Int!){
    v1 {
        cart {
            changeQuantity(shoppingCartItemId: $shoppingCartItemId, shoppingCartTypeId: $shoppingCartTypeId, qty: $qty) ${ShoppingCartPartialQuery()}
        }
    }
}`;

export interface IChangeQuantityInCartResult {
  v1: {
    cart: {
      changeQuantity: IShoppingCart;
    };
  };
}

export interface IChangeQuantityInCartVariables {
  shoppingCartItemId: string;
  qty: number;
  shoppingCartTypeId: string;
}
