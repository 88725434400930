import { useHistory, useParams } from "react-router";
import GenerateLink from "../../../../helpers/GenerateLink";
import { DiagramParams, ISection } from "../DiagramPage";
import styles from "./DiagramSectionNav.module.scss";

interface NavListProps {
  Items: ISection[];
}

interface IParams {
  modelId: string;
}

const DiagramSectionNav = (props: NavListProps) => {
  const params = useParams<IParams>();
  const { sectionId } = DiagramParams();
  const parentId = sectionId ? parseInt(sectionId) : null;

  return (
    <div className={styles["section-list"]}>
      <div className={styles["navTitle"]}> Parts Book</div>
      <ListSection parentId={null} selectedId={parentId} list={props.Items} modelId={params.modelId}></ListSection>
    </div>
  );
};

const ListSection = (props: { list: ISection[]; selectedId: number | null; parentId: number | null; modelId: string }) => {
  const history = useHistory();
  const { diagramId: slectedDiagramId } = DiagramParams();
  const onClick = (sectionId: string, diagramId: string | null, seoName: string) => {
    window.scrollTo(0, 0);
    history.push(GenerateLink.ForDiagramSection(props.modelId, sectionId, diagramId == null ? slectedDiagramId : diagramId, seoName, null));
  };

  return (
    <>
      {props.list
        .filter((x) => x.parentSectionId === props.parentId)
        .sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0))
        .map((x, i: number) => {
          const ID = parseInt(x.id);
          const diagramId = x.diagrams?.length ? x.diagrams[0].id : null;
          const children = props.list.filter((y) => y.parentSectionId === ID);
          const parentSelected = x.parentSectionId === props.selectedId;
          const selectedSection = ID === props.selectedId;
          const selectedDiagram = x.diagrams?.filter((j) => j.id === slectedDiagramId).length > 0;
          const childselected = children?.filter((j) => j.diagrams[0].id === slectedDiagramId).length > 0;
          const isDiagram = x.diagrams != null && x.diagrams.length > 0;
          return (
            <div
              key={ID}
              className={`${styles["section-item"]}
                                ${
                                  parentSelected
                                    ? styles["parentSelected"]
                                    : selectedSection || childselected
                                    ? styles["selected"]
                                    : styles["unselected"]
                                }
                                ${isDiagram ? styles["isDiagram"] : ""}
                                ${selectedDiagram ? styles["selectedDiagram"] : ""}`}
            >
              <div className={styles["titleGroup"]} onClick={() => onClick(x.id, diagramId, x.seoName)}>
                <div className={styles["arrow"]}></div>
                <div className={styles["sectionTitle"]}>{x.title}</div>
              </div>

              {children ? (
                <ListSection selectedId={props.selectedId} list={props.list} parentId={ID} modelId={props.modelId}></ListSection>
              ) : null}
            </div>
          );
        })}
    </>
  );
};

export default DiagramSectionNav;
