import { gql } from "@shane32/graphql";

export const EmptyFilterQuery = gql`
  query ($searchString: String, $filters: [IdAndValuesGraph!]) {
    v1 {
      searchResults(searchString: $searchString, filters: $filters) {
        emptyFilters {
          id
          name
          values {
            id
            name
            quantity
          }
        }
      }
    }
  }
`;

export interface IEmptyFilterQuery {
  v1: {
    searchResults: {
      emptyFilters: Array<{
        id: string;
        name: string;
        values: Array<{
          id: string;
          name: string;
          quantity: number;
        }>;
      }>;
    };
  };
}

export interface IEmptyFilterQueryVariables {
  searchString?: string | null;
  filters?: Array<{ id: string; values: string[] }> | null;
}
