import { gql } from "@shane32/graphql";

export const CheckoutMutation = gql`
  mutation (
    $shippingAddressId: ID!
    $paymentMethodId: ID!
    $cart: ShoppingCartInputGraph!
    $email: String
    $deviceData: String!
    $captchaResponse: String
  ) {
    v1 {
      cart {
        placeOrder(
          shippingAddressId: $shippingAddressId
          paymentMethodId: $paymentMethodId
          cart: $cart
          email: $email
          deviceData: $deviceData
          captchaResponse: $captchaResponse
        )
      }
    }
  }
`;

export interface ICheckoutMutationResult {
  v1: {
    cart: {
      placeOrder: string;
    };
  };
}

export interface ICheckoutGroupItem {
  productId: string;
  price: number;
  quantity: number;
  reference: string;
}

export interface ICheckoutCartGroup {
  shippingMethodId: string;
  shippingPrice: number;
  backordered: boolean;
  items: ICheckoutGroupItem[];
}

export interface ICheckoutCart {
  poNumber: string;
  groups: ICheckoutCartGroup[];
}

export interface ICheckoutMutationVariables {
  shippingAddressId: string;
  paymentMethodId: string;
  cart: ICheckoutCart;
  email?: string;
  deviceData: string;
  captchaResponse: string | null;
}
