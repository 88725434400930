import { gql } from "@shane32/graphql";

export const DefaultPaymentAndShippingQuery = gql`
  {
    v1 {
      my {
        defaultPaymentMethod {
          id
          accountNameOrEmail
          address {
            address1
            address2
            city
            company
            country {
              id
              name
            }
            createdOnUtc
            email
            firstName
            id
            lastName
            phoneNumber
            stateProvince {
              abbreviation
              id
              name
            }
            zipPostalCode
          }
          createdOnUtc
          creditCardExp
          creditCardLast4
          creditCardType
          displayOrder
          paymentType
          validShippingAddresses {
            id
          }
        }
        defaultShippingAddress {
          id
          address1
          address2
          city
          company
          country {
            id
            name
          }
          createdOnUtc
          email
          firstName
          id
          lastName
          phoneNumber
          stateProvince {
            abbreviation
            id
            name
          }
          zipPostalCode
        }
      }
    }
  }
`;

export interface IDefaultPaymentAndShippingQuery {
  v1: {
    my: {
      defaultPaymentMethod: IPaymentMethod | null;
      defaultShippingAddress: IShippingAddress | null;
    } | null;
  };
}

export interface IPaymentMethod {
  id: string;
  accountNameOrEmail: string | null;
  address: IBillingAddress;
  creditCardExp: string | null;
  creditCardLast4: string | null;
  creditCardType: string | null;
  createdOnUtc: string;
  displayOrder: number;
  validShippingAddresses: Array<{ id: string }>;
  paymentType: "CREDIT_CARD" | "PAY_PAL" | "APPLE_PAY" | "GOOGLE_PAY";
}

export interface IShippingAddress {
  id: string;
  country: {
    id: string;
    name: string;
  };
  createdOnUtc: string;
  firstName: string;
  lastName: string;
  company: string | null;
  email: string;
  address1: string;
  address2: string | null;
  city: string;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  };
  zipPostalCode: string;
  phoneNumber: string;
}

export interface IBillingAddress {
  id: string;
  country: {
    id: string;
    name: string;
  };
  createdOnUtc: string;
  firstName: string;
  lastName: string;
  company: string | null;
  email: string;
  address1: string | null;
  address2: string | null;
  city: string | null;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  } | null;
  zipPostalCode: string | null;
  phoneNumber: string | null;
}
