import React from "react";
import { useHistory, useParams } from "react-router-dom";
import GenericBreadCrumbs from "../../../components/breadcrumb/GenericBreadCrumbs";
import Loading from "../../../components/loading/Loading";
import GenerateLink from "../../../helpers/GenerateLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import Queries from "../../../queries/Queries";
import NotFound from "../../errorpage/NotFound";
import DesktopHeader from "../components/DesktopHeader";
import BackButton from "../components/DiagramHeader";
import styles from "./DiagramBrand.module.scss";

interface IParams {
  manufacturerId: string;
}

const DiagramBrand = () => {
  const { manufacturerId } = useParams<IParams>();
  const [modelSearch, setModelSearch] = React.useState<string>("");
  const modelListRef = React.useRef<HTMLUListElement>(null);
  const diagramManufacturerPageRef = React.useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const history = useHistory();

  const { loading, error, data, errors } = Queries.useQueryMachineManufacturerWithDiagramModels({
    machineManufacturerId: manufacturerId,
  });

  if (error || errors) return <NotFound />;
  else if (loading || !data || !data.v1) return <Loading />;
  else if (data.v1.machineManufacturers.length !== 1 || data.v1.machineManufacturers[0].machineModels.length === 0) return <NotFound />;

  // sort models by name
  data.v1.machineManufacturers[0].machineModels.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  let modelStartCharacter = "";

  const resetModelSearch = (value: string) => {
    if (width > 992 && modelListRef.current) {
      modelListRef.current.scrollTo(0, 0);
    } else if (diagramManufacturerPageRef.current) {
      diagramManufacturerPageRef.current.scrollIntoView();
    }
    setModelSearch(value);
  };

  return (
    <section className={styles["diagram-manufacturer-page"]} ref={diagramManufacturerPageRef}>
      <DesktopHeader />
      <div className={styles["body"]}>
        <div className={styles["sticky-top"]}>
          <GenericBreadCrumbs
            className={styles["breadcrumbs"]}
            crumbs={[
              {
                name: data.v1.machineManufacturers[0].name,
                url: null,
              },
            ]}
          />
          <div className={styles["page-header"]}>
            <div className={styles["models-header"]}>
              <BackButton text={"Manufacturers"} to={GenerateLink.ForPartsBooks()}></BackButton>
              <div className={styles["header-text"] + " h4b"}>Models</div>
            </div>
            <input
              className={styles["model-search-box"] + " b1r"}
              type="text"
              placeholder="Filter your options"
              value={modelSearch}
              onChange={(e) => resetModelSearch(e.target.value)}
            />
          </div>
        </div>
        <ul className={styles["model-list"]} ref={modelListRef}>
          {data.v1.machineManufacturers[0].machineModels.reduce((acc: JSX.Element[], model, index) => {
            if (model.name.toLowerCase().includes(modelSearch.toLowerCase())) {
              if (model.name.charAt(0).toUpperCase() !== modelStartCharacter) {
                acc.push(
                  <li key={index + model.name.charAt(0)} className={styles["model-character-header"] + " h5m"}>
                    <div className={styles["model-list-text"]}>{model.name.charAt(0).toUpperCase()}</div>
                  </li>
                );
                modelStartCharacter = model.name.charAt(0).toUpperCase();
              }
              acc.push(
                <li key={index} onClick={() => history.push(GenerateLink.ForDiagramModel(model.id))} className={styles["model"] + " h5r"}>
                  <div className={styles["model-list-text"]}>{model.name}</div>
                </li>
              );
            }
            return acc;
          }, [])}
        </ul>
      </div>
    </section>
  );
};

export default DiagramBrand;
