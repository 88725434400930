import { AuthContext } from "@zboxglobal/zboxauth";
import noScroll from "no-scroll";
import * as React from "react";
import { IMachineModel } from "../queries/MachineModelsQuery";

export type IHeaderType = "STANDARD" | "HIDDEN" | "CHECKOUT" | "EditMachinePage" | "NoSearchMobile" | "ScrollNavBar";
export type fitmentSideBarState = "ALL" | "ClOSED" | "ADD";

export interface IGlobalContext {
  isAccountOpen: boolean;
  toggleAccount: () => void;

  closeOverlay: () => void;

  isDiagramPageOpen: boolean;
  toggleDiagramPageOpen: (b: boolean) => void;
  isDepartmentsOpen: boolean;
  toggleDepartments: () => void;

  isMobileSuggestOpen: boolean;
  toggleMobileSuggest: (b: boolean) => void;

  isMobileFiltersOpen: boolean;
  toggleMobileFilters: () => void;
  setFitmentSideBar: (to: fitmentSideBarState) => void;
  fitmentSideBarState: fitmentSideBarState;

  header: IHeaderType;
  setHeaderType: (headerType: IHeaderType, priority: number) => () => void; //returns cleanup function which restores the old header

  guestMachine: IMachineModel | null;
  setGuestMachine: (model: IMachineModel | null) => void;

  setSearchByMachine: (val: boolean) => void;
  searchbyMachine: boolean;
}

interface IState {
  isAccountOpen: boolean;
  isDepartmentsOpen: boolean;
  isMobileSuggestOpen: boolean;
  fitmentSideBarState: fitmentSideBarState;
  isMobileFiltersOpen: boolean;
  searchbyMachine: boolean;
  isDiagramPageOpen: boolean;
  guestMachine: IMachineModel | null;
  headerStack: Array<{ priority: number; value: IHeaderType }>;
  headerActive: IHeaderType;
}

const GlobalContext = React.createContext<IGlobalContext>({
  isAccountOpen: false,
  toggleAccount: () => {},
  closeOverlay: () => {},
  isDepartmentsOpen: false,
  toggleDepartments: () => {},
  isMobileFiltersOpen: false,
  toggleMobileFilters: () => {},

  toggleDiagramPageOpen: (b: boolean) => {},
  isDiagramPageOpen: false,

  toggleMobileSuggest: (b: boolean) => {},
  isMobileSuggestOpen: false,

  setFitmentSideBar: (to: fitmentSideBarState) => {},
  fitmentSideBarState: "ClOSED",
  header: "STANDARD",
  setHeaderType: () => () => {},
  guestMachine: null,
  setGuestMachine: () => {},
  setSearchByMachine: () => {},
  searchbyMachine: false,
});

export default GlobalContext;

export const GlobalContextController = (props: { children: any }) => {
  const authContext = React.useContext(AuthContext);
  const [state, setState] = React.useState<IState>({
    isAccountOpen: false,
    isDepartmentsOpen: false,

    fitmentSideBarState: "ClOSED",
    isMobileSuggestOpen: false,
    isDiagramPageOpen: false,
    isMobileFiltersOpen: false,
    headerStack: [],
    headerActive: "STANDARD",
    guestMachine: null,
    searchbyMachine: false,
  });

  const isNoScroll =
    state.isDepartmentsOpen || state.isAccountOpen || state.isMobileFiltersOpen || state.isMobileSuggestOpen || state.isDiagramPageOpen;
  React.useEffect(() => {
    if (isNoScroll) {
      noScroll.on();
      return () => {
        noScroll.off();
      };
    } else return () => {};
  }, [isNoScroll]);

  React.useEffect(() => {
    if (authContext.status.isRegistered) {
      return () => {
        setState((oldState) => ({
          ...oldState,
          guestMachine: null,
        }));
      };
    } else return () => {};
  }, [authContext.status.isRegistered]);

  const contextState: IGlobalContext = {
    isAccountOpen: state.isAccountOpen,
    isDiagramPageOpen: state.isDiagramPageOpen,
    isDepartmentsOpen: state.isDepartmentsOpen,
    isMobileFiltersOpen: state.isMobileFiltersOpen,
    fitmentSideBarState: state.fitmentSideBarState,
    isMobileSuggestOpen: state.isMobileSuggestOpen,
    closeOverlay: React.useCallback(() => {
      setState((oldState) => ({
        ...oldState,
        isAccountOpen: false,
        isDepartmentsOpen: false,
        isMobileFiltersOpen: false,
        fitmentSideBarState: "ClOSED",
      }));
    }, []),

    toggleDiagramPageOpen: React.useCallback((b: boolean) => {
      setState((oldState) => ({
        ...oldState,
        isDiagramPageOpen: b,
      }));
    }, []),

    toggleAccount: React.useCallback(() => {
      setState((oldState) => ({
        ...oldState,
        isDepartmentsOpen: false,
        isAccountOpen: !oldState.isAccountOpen,
      }));
    }, []),

    toggleDepartments: React.useCallback(() => {
      setState((oldState) => ({
        ...oldState,
        isDepartmentsOpen: !oldState.isDepartmentsOpen,
        isAccountOpen: false,
      }));
    }, []),

    toggleMobileSuggest: React.useCallback((b: boolean) => {
      setState((oldState) => ({
        ...oldState,
        isMobileSuggestOpen: b,
      }));
    }, []),

    setFitmentSideBar: React.useCallback((to: fitmentSideBarState) => {
      setState((oldState) => ({
        ...oldState,
        fitmentSideBarState: to,
      }));
    }, []),

    toggleMobileFilters: React.useCallback(() => {
      setState((oldState) => ({
        ...oldState,
        isMobileFiltersOpen: !oldState.isMobileFiltersOpen,
      }));
    }, []),
    header: state.headerActive,
    setHeaderType: React.useCallback((headerType: IHeaderType, priority: number) => {
      setState((oldState) => {
        const newArray = [...oldState.headerStack, { priority: priority, value: headerType }];
        return {
          ...oldState,
          headerStack: newArray,
          headerActive: getActiveHeader(newArray),
        };
      });
      //return cleanup function to restore old header
      return () => {
        setState((oldState) => {
          const newArray = oldState.headerStack.filter((x) => x.priority !== priority);
          return {
            ...oldState,
            headerStack: newArray,
            headerActive: getActiveHeader(newArray),
          };
        });
      };
    }, []),
    guestMachine: state.guestMachine,
    setGuestMachine: React.useCallback((model: IMachineModel | null) => {
      setState((oldState) => ({
        ...oldState,
        guestMachine: model,
      }));
    }, []),

    searchbyMachine: state.searchbyMachine,
    setSearchByMachine: React.useCallback((val: boolean) => {
      setState((oldState) => ({
        ...oldState,
        searchbyMachine: val,
      }));
    }, []),
  };

  return <GlobalContext.Provider value={contextState}>{props.children}</GlobalContext.Provider>;
};

function getActiveHeader(array: Array<{ priority: number; value: IHeaderType }>) {
  let active: IHeaderType = "STANDARD";
  let activePriority = -1;
  for (let i = 0; i < array.length; i++) {
    if (array[i].priority > activePriority) {
      activePriority = array[i].priority;
      active = array[i].value;
    }
  }
  return active;
}
