import { IChangeNameMutationVariables, ChangeNameMutation, IChangeNameMutationResult } from "./ChangeNameMutation";
import { IChangeNumberMutationVariables, IChangeNumberMutationResult, ChangeNumberMutation } from "./ChangeNumberMutation";
import {
  IAddProductToCartFromCatalogResult,
  IAddProductToCartFromCatalogVariables,
  AddProductToCartFromCatalogMutation,
} from "./AddProductToCartFromCatalogMutation";
import { IChangeQuantityInCartVariables, IChangeQuantityInCartResult, ChangeQuantityInCartMutation } from "./ChangeQuantityInCartMutation";
import {
  IRemoveProductFromCartVariables,
  IRemoveProductFromCartResult,
  RemoveProductFromCartMutation,
} from "./RemoveProductFromCartMutation";
import { ISaveForLaterProductResult, ISaveForLaterProductVariables, SaveForLaterProductMutation } from "./SaveForLaterProductMutation";
import {
  ISetSelectedShippingMethodVariables,
  ISetSelectedShippingMethodResult,
  SetSelectedShippingMethodMutation,
} from "./SetSelectedShippingMethodMutation";
import { ICartSetDestinationResult, ICartSetDestinationVariables, CartSetDestinationMutation } from "./CartSetDestinationMutation";
import { IMoveToCartResult, IMoveToCartVariables, MoveToCartMutation } from "./MoveToCartMutation";
import { ICartSetLineReferenceResult, ICartSetLineReferenceVariables, CartSetLineReferenceMutation } from "./CartSetLineReferenceMutation";
import { ICheckoutMutationResult, ICheckoutMutationVariables, CheckoutMutation } from "./CheckoutMutation";
import {
  ICheckoutExternalPaymentMutationResult,
  ICheckoutExternalPaymentMutationVariables,
  CheckoutExternalPaymentMutation,
} from "./CheckoutExternalPaymentMutation";
import { IAddAddressMutationVariables, AddAddressMutation, IAddAddressMutationResult } from "./AddAddressMutation";
import { EditAddressMutation, IEditAddressMutationResult, IEditAddressMutationVariables } from "./EditAddressMutation";
import { IDeleteAddressMutationVariables, DeleteAddressMutation, IDeleteAddressMutationResult } from "./DeleteAddressMutation";
import {
  SetDefaultAddressMutation,
  ISetDefaultAddressMutationVariables,
  ISetDefaultAddressMutationResult,
} from "./SetDefaultAddressMutation";
import { IAddPaymentMutationVariables, AddPaymentMutation, IAddPaymentMutationResult } from "./AddPaymentMutation";
import { EditPaymentMutation, IEditPaymentMutationResult, IEditPaymentMutationVariables } from "./EditPaymentMutation";
import { IDeletePaymentMutationVariables, DeletePaymentMutation, IDeletePaymentMutationResult } from "./DeletePaymentMutation";
import {
  SetDefaultPaymentMutation,
  ISetDefaultPaymentMutationVariables,
  ISetDefaultPaymentMutationResult,
} from "./SetDefaultPaymentMutation";
import {
  ICartSetDestinationExternalPaymentResult,
  ICartSetDestinationExternalPaymentVariables,
  CartSetDestinationExternalPaymentMutation,
} from "./CartSetDestinationExternalPaymentMutation";
import { useMutation, IQueryResult } from "@shane32/graphql";
import { IAddFeedbackMutationResult, IAddFeedbackMutationVariables, AddFeedbackMutation } from "./AddFeedbackMutation";
import {
  IChangeFeedbackNameMutationResult,
  IChangeFeedbackNameMutationVariables,
  ChangeFeedbackNameMutation,
} from "./ChangeFeedbackNameMutation";
import { ICancelOrderMutationResult, ICancelOrderMutationVariables, CancelOrderMutation } from "./CancelOrderMutation";
import {
  ISubmitReturnRequestMutationResult,
  ISubmitReturnRequestMutationVariables,
  SubmitReturnRequestMutation,
} from "./SubmitReturnRequestMutation";

export const Mutations = {
  useSetDestination: function (): [(data: ICartSetDestinationVariables) => Promise<IQueryResult<ICartSetDestinationResult>>] {
    const [mutation] = useMutation<ICartSetDestinationResult, ICartSetDestinationVariables>(CartSetDestinationMutation);
    return [(data) => mutation({ variables: data })];
  },

  useSetDestinationExternalPayment: function (): [
    (data: ICartSetDestinationExternalPaymentVariables) => Promise<IQueryResult<ICartSetDestinationExternalPaymentResult>>
  ] {
    const [mutation] = useMutation<ICartSetDestinationExternalPaymentResult, ICartSetDestinationExternalPaymentVariables>(
      CartSetDestinationExternalPaymentMutation
    );
    return [(data) => mutation({ variables: data })];
  },

  useAddProductToCartFromCatalog: function (): [
    (data: IAddProductToCartFromCatalogVariables) => Promise<IQueryResult<IAddProductToCartFromCatalogResult>>
  ] {
    const [mutation] = useMutation<IAddProductToCartFromCatalogResult, IAddProductToCartFromCatalogVariables>(
      AddProductToCartFromCatalogMutation
    );
    return [(data) => mutation({ variables: data })];
  },

  useRemoveProductFromCart: function (): [(data: IRemoveProductFromCartVariables) => Promise<IQueryResult<IRemoveProductFromCartResult>>] {
    const [mutation] = useMutation<IRemoveProductFromCartResult, IRemoveProductFromCartVariables>(RemoveProductFromCartMutation);
    return [(data) => mutation({ variables: data })];
  },

  useSaveForLaterProduct: function (): [(data: ISaveForLaterProductVariables) => Promise<IQueryResult<ISaveForLaterProductResult>>] {
    const [mutation] = useMutation<ISaveForLaterProductResult, ISaveForLaterProductVariables>(SaveForLaterProductMutation);
    return [(data) => mutation({ variables: data })];
  },

  useMoveToCart: function (): [(data: IMoveToCartVariables) => Promise<IQueryResult<IMoveToCartResult>>] {
    const [mutation] = useMutation<IMoveToCartResult, IMoveToCartVariables>(MoveToCartMutation);
    return [(data) => mutation({ variables: data })];
  },

  useChangeQuantityInCart: function (): [(data: IChangeQuantityInCartVariables) => Promise<IQueryResult<IChangeQuantityInCartResult>>] {
    const [mutation] = useMutation<IChangeQuantityInCartResult, IChangeQuantityInCartVariables>(ChangeQuantityInCartMutation);
    return [(data) => mutation({ variables: data })];
  },

  useSetLineReferenceInCart: function (): [(data: ICartSetLineReferenceVariables) => Promise<IQueryResult<ICartSetLineReferenceResult>>] {
    const [mutation] = useMutation<ICartSetLineReferenceResult, ICartSetLineReferenceVariables>(CartSetLineReferenceMutation);
    return [(data) => mutation({ variables: data })];
  },

  useCheckout: function () {
    return useMutation<ICheckoutMutationResult, ICheckoutMutationVariables>(CheckoutMutation);
  },

  useCheckoutExternalPayment: function () {
    return useMutation<ICheckoutExternalPaymentMutationResult, ICheckoutExternalPaymentMutationVariables>(CheckoutExternalPaymentMutation);
  },

  useEditAddress: function () {
    return useMutation<IEditAddressMutationResult, IEditAddressMutationVariables>(EditAddressMutation);
  },

  useAddAddress: function () {
    return useMutation<IAddAddressMutationResult, IAddAddressMutationVariables>(AddAddressMutation);
  },

  useDeleteAddress: function () {
    return useMutation<IDeleteAddressMutationResult, IDeleteAddressMutationVariables>(DeleteAddressMutation);
  },

  useSetDefaultAddress: function () {
    return useMutation<ISetDefaultAddressMutationResult, ISetDefaultAddressMutationVariables>(SetDefaultAddressMutation);
  },

  useChangeFeedbackName: function () {
    return useMutation<IChangeFeedbackNameMutationResult, IChangeFeedbackNameMutationVariables>(ChangeFeedbackNameMutation);
  },

  useChangeName: function () {
    return useMutation<IChangeNameMutationResult, IChangeNameMutationVariables>(ChangeNameMutation);
  },

  useChangeNumber: function () {
    return useMutation<IChangeNumberMutationResult, IChangeNumberMutationVariables>(ChangeNumberMutation);
  },

  useEditPayment: function () {
    return useMutation<IEditPaymentMutationResult, IEditPaymentMutationVariables>(EditPaymentMutation);
  },

  useAddPayment: function () {
    return useMutation<IAddPaymentMutationResult, IAddPaymentMutationVariables>(AddPaymentMutation);
  },

  useDeletePayment: function () {
    return useMutation<IDeletePaymentMutationResult, IDeletePaymentMutationVariables>(DeletePaymentMutation);
  },

  useSetDefaultPayment: function () {
    return useMutation<ISetDefaultPaymentMutationResult, ISetDefaultPaymentMutationVariables>(SetDefaultPaymentMutation);
  },

  useSetSelectedShippingMethod: function () {
    return useMutation<ISetSelectedShippingMethodResult, ISetSelectedShippingMethodVariables>(SetSelectedShippingMethodMutation);
  },

  useAddFeedback: function () {
    return useMutation<IAddFeedbackMutationResult, IAddFeedbackMutationVariables>(AddFeedbackMutation);
  },

  useCancelOrder: function () {
    return useMutation<ICancelOrderMutationResult, ICancelOrderMutationVariables>(CancelOrderMutation);
  },

  useSubmitReturnRequest: function () {
    return useMutation<ISubmitReturnRequestMutationResult, ISubmitReturnRequestMutationVariables>(SubmitReturnRequestMutation);
  },
};
