import React from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/loading/Loading";
import GenerateLink from "../../../helpers/GenerateLink";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { ICustomerDiagram, RelationType } from "../../../queries/PartsBookQueries/CustomerDiagramsQuery";
import Queries from "../../../queries/Queries";
import NotFound from "../../errorpage/NotFound";
import DesktopHeader from "../components/DesktopHeader";
import styles from "./PartsBooks.module.scss";

const PartsBooks = () => {
  const [recentlyViewedSelected, setRecentlyViewedSelected] = React.useState<boolean>(true);
  const [manufacturerSearch, setManufacturerSearch] = React.useState<string>("");
  const manufacturerListRef = React.useRef<HTMLDivElement>(null);
  const customerDiagramRef = React.useRef<HTMLDivElement>(null);
  const manufacturerSelectionRef = React.useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const history = useHistory();

  // for actual use
  const {
    loading: customerDiagramsLoading,
    error: customerDiagramsError,
    data: customerDiagramsData,
    errors: customerDiagramsErrors,
  } = Queries.useQueryCustomerDiagrams();
  const {
    loading: machineManufacturersLoading,
    error: machineManufacturersError,
    data: machineManufacturersData,
    errors: machineManufacturersErrors,
  } = Queries.useQueryMachineManufacturers(false, {
    machineTypeId: null,
    diagramsRequired: true,
  });
  // end actual use

  if (customerDiagramsErrors || customerDiagramsError || machineManufacturersErrors || machineManufacturersError) return <NotFound />;
  else if (
    customerDiagramsLoading ||
    !customerDiagramsData ||
    !customerDiagramsData.v1 ||
    machineManufacturersLoading ||
    !machineManufacturersData ||
    !machineManufacturersData.v1
  )
    return <Loading />;

  const customerHasDiagrams =
    customerDiagramsData.v1.my != null &&
    customerDiagramsData.v1.my.customerDiagrams.some(
      (x) => x.relationType === RelationType.Favorite || x.relationType === RelationType.Recent
    );

  const setManufacturerSearchValue = (value: string) => {
    if (width > 992 && manufacturerSelectionRef.current) {
      manufacturerSelectionRef.current.scrollTo(0, 0);
    } else if (manufacturerSelectionRef.current && manufacturerSelectionRef.current.getBoundingClientRect().top < 0) {
      manufacturerSelectionRef.current.scrollIntoView();
    }
    setManufacturerSearch(value);
  };

  const switchCustomerDiagramFilter = (recentlyViewedToggleSelected: boolean) => {
    if (recentlyViewedToggleSelected) {
      if (customerDiagramRef.current) {
        customerDiagramRef.current.scrollLeft = 0;
      }
      setRecentlyViewedSelected(!recentlyViewedSelected);
    }
  };

  const onBookClick = (modelId: string, diagramId: string, seoDiagram: string) => {
    history.push(GenerateLink.ForDiagram(modelId, diagramId, seoDiagram));
  };

  if (customerHasDiagrams) {
    //sort customer diagrams by createdOnUtc converted to date
    customerDiagramsData.v1.my.customerDiagrams.sort((a, b) => {
      const aDate = new Date(a.createdOnUtc);
      const bDate = new Date(b.createdOnUtc);
      return bDate.getTime() - aDate.getTime();
    });
  }

  return (
    <section className={styles["parts-books-page"]}>
      <div className={styles["page-header"]}>
        <div className={styles["mobile-header"]}>
          <div className={styles["header-book"]} />
          <div className={styles["header-text"]}>PARTS BOOKS</div>
          <div className={styles["header-book"]} />
        </div>
        <DesktopHeader />
      </div>
      <div
        className={
          styles["body"] + " " + (customerHasDiagrams ? styles["body-with-customer-diagrams"] : styles["body-without-customer-diagrams"])
        }
      >
        {customerHasDiagrams && (
          <div className={styles["customer-diagrams"]}>
            <div className={styles["previously-viewed"]}>
              <div className={styles["previously-viewed-header"]}>Previously Viewed</div>
              <div className={styles["diagram-list-filters"]}>
                <div
                  className={recentlyViewedSelected ? styles["recently-viewed-selected"] : styles["recently-viewed-unselected"]}
                  onClick={(e) => switchCustomerDiagramFilter(!recentlyViewedSelected)}
                />
                <div
                  className={recentlyViewedSelected ? styles["bookmark-unselected"] : styles["bookmark-selected"]}
                  onClick={(e) => switchCustomerDiagramFilter(recentlyViewedSelected)}
                />
              </div>
            </div>
            <div className={styles["parts-book-card-slider"]} ref={customerDiagramRef}>
              {recentlyViewedSelected
                ? customerDiagramsData.v1.my?.customerDiagrams.map((diagram, i) => {
                    return <PartsBookRecent key={i} onClick={onBookClick} diagram={diagram}></PartsBookRecent>;
                  })
                : customerDiagramsData.v1.my?.customerDiagrams
                    .filter((x) => x.relationType === RelationType.Favorite)
                    .map((diagram, i) => {
                      return <PartsBookRecent key={i} onClick={onBookClick} diagram={diagram}></PartsBookRecent>;
                    })}
            </div>
          </div>
        )}
        <div className={styles["manufacturer-selection"]} ref={manufacturerSelectionRef}>
          <div className={styles["manufacturer-search"]}>
            <div className={styles["manufacturer-selection-header"]}>Select a Manufacturer</div>
            <input
              className={styles["manufacturer-search-box"] + " b1r"}
              type="text"
              placeholder="Filter your options"
              value={manufacturerSearch}
              onChange={(e) => setManufacturerSearchValue(e.target.value)}
            />
          </div>
          <div className={styles["manufacturer-list-wrapper"]} ref={manufacturerListRef}>
            <ul className={styles["manufacturer-list"]}>
              {machineManufacturersData.v1.machineManufacturers.reduce((acc: JSX.Element[], machineManufacturer, index) => {
                if (machineManufacturer.name.toLowerCase().includes(manufacturerSearch.toLowerCase())) {
                  acc.push(
                    <li key={index} onClick={() => history.push(GenerateLink.ForDiagramBrand(machineManufacturer.id))}>
                      <div className={styles["manufacturer"]}>
                        <div className={styles["manufacturer-book"]} />
                        <div className="h4b">{machineManufacturer.name}</div>
                      </div>
                    </li>
                  );
                }
                return acc;
              }, [])}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

const PartsBookRecent = (props: {
  onClick: (modelId: string, diagramId: string, seoDiagram: string) => void;
  diagram: ICustomerDiagram;
}) => {
  const brand = props.diagram.diagram.machineSection.machineModel.machineManufacturer.name;
  const model = props.diagram.diagram.machineSection.machineModel.name;
  return (
    <div
      onClick={() =>
        props.onClick(props.diagram.diagram.machineSection.machineModel.id, props.diagram.id, props.diagram.diagram.machineSection.seoName)
      }
      className={styles["book-cover"]}
    >
      <div className={styles["book-cover-top"]}>
        <div className={props.diagram.relationType === RelationType.Favorite ? styles["book-cover-bookmark"] : ""}></div>
        <div className={styles["book-cover-zbox-logo"] + " image-ZLogoWhite"} />
      </div>
      <div className={styles["book-cover-body"]}>
        <div className={styles["book-cover-text"] + " b2b"}>
          <div>{brand}</div>
          <div>{model}</div>
        </div>
        <div className={styles["book-cover-text"] + " " + styles["book-cover-diagram-name"]}>
          {props.diagram.diagram.machineSection.title}
        </div>
      </div>
    </div>
  );
};

export default PartsBooks;
