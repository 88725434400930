import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import styles from "./ImageZoomer.module.scss";
import image from "./placeHolder.png";
import React from "react";

const ImageZoomer = (props: { image?: string; height?: string; animate: boolean; loading: boolean }) => {
  const transformComponentRef = React.useRef<ReactZoomPanPinchRef | null>(null);

  const zoomAmount = 0.7;

  const zoomOut = () => {
    if (transformComponentRef.current) {
      transformComponentRef.current.zoomOut(zoomAmount);
    }
  };

  //keeps the image in sight when done panning
  const keepInSight = (ref: ReactZoomPanPinchRef) => {
    const imageWidth = Number(ref.instance.contentComponent?.querySelector("img")?.getClientRects()[0].width);
    const imageHeight = Number(ref.instance.contentComponent?.querySelector("img")?.getClientRects()[0].height);
    const wrapperWidth = Number(ref.instance.wrapperComponent?.getClientRects()[0].width);
    const wrapperHeight = Number(ref.instance.wrapperComponent?.getClientRects()[0].height);

    const scale = ref.state.scale;
    let yOffset = scale * 60;
    let XOffset = scale * 60;
    if (wrapperWidth > 1000) {
      XOffset = scale * 300;
    }

    if (wrapperHeight > 800) {
      yOffset = scale * 300;
    }

    //puts x back on the screen
    if (imageWidth + ref.state.positionX < 0 + XOffset) {
      ref.setTransform(-imageWidth + XOffset, ref.state.positionY, ref.state.scale);
    } else if (ref.state.positionX > wrapperWidth - XOffset) {
      ref.setTransform(wrapperWidth - XOffset, ref.state.positionY, ref.state.scale);
    }

    //puts x back on the screen
    if (imageHeight + ref.state.positionY < 0 + yOffset) {
      ref.setTransform(ref.state.positionX, -imageHeight + yOffset, ref.state.scale);
    } else if (ref.state.positionY > wrapperHeight - yOffset) {
      ref.setTransform(ref.state.positionX, wrapperHeight - yOffset, ref.state.scale);
    }
  };

  return props.loading ? null : (
    <TransformWrapper
      //get image width to set initial position
      ref={transformComponentRef}
      limitToBounds={false}
      initialScale={1}
      minScale={0.5}
      onPanningStop={keepInSight}
      maxScale={10}
      initialPositionX={0}
      panning={{
        velocityDisabled: true,
      }}
    >
      <TransformComponent
        contentClass={styles["contentClass"]}
        wrapperStyle={{
          height: props.height,
          width: "100%",
          backgroundColor: "white",
        }}
        wrapperClass={props.animate ? " " + styles["image-animate-slide"] : ""}
      >
        <img
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = image;
          }}
          onLoad={() => {
            if (transformComponentRef.current) {
              transformComponentRef.current.resetTransform();
            }
          }}
          className={styles["image"]}
          id="P1_IMAGE"
          alt="diagram"
          src={props.image ? props.image : image}
        />
      </TransformComponent>
      <div
        style={{
          position: "absolute",
          right: 3,
          top: 3,
        }}
      >
        <div className={styles["zoomIn"]} onClick={() => transformComponentRef?.current?.zoomIn(zoomAmount)}></div>
        <div className={styles["zoomOut"]} onClick={zoomOut}></div>
      </div>
    </TransformWrapper>
  );
};

export default ImageZoomer;
