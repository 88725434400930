import { gql } from "@shane32/graphql";

export const CustomerDiagramsQuery = gql`
  query {
    v1 {
      my {
        customerDiagrams {
          id
          createdOnUtc
          relationType
          diagram {
            id
            machineSection {
              title
              seoName
              machineModel {
                id
                name
                machineManufacturer {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface ICustomerDiagramsQuery {
  v1: {
    my: {
      customerDiagrams: ICustomerDiagram[];
    };
  };
}

export interface ICustomerDiagram {
  id: string;
  createdOnUtc: string;
  relationType: RelationType;
  diagram: IDiagram;
}

export interface IDiagram {
  id: string;
  machineSection: IMachineSection;
}

export interface IMachineSection {
  title: string;
  machineModel: IMachineModel;
  seoName: string;
}

export interface IMachineModel {
  id: string;
  name: string;
  machineManufacturer: IMachineManufacturer;
}

export interface IMachineManufacturer {
  name: string;
}

export enum RelationType {
  Recent = "RECENT",
  Favorite = "FAVORITE",
  Unfavorite = "UNFAVORITE",
}
