import { AuthContext } from "@zboxglobal/zboxauth";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import GenerateLink from "../../helpers/GenerateLink";
import SideBarPopOut from "../SideBarPopOut/SideBarPopOut";
import styles from "./NavSideBar.module.scss";

const NavSidebar = () => {
  const history = useHistory();
  const globalContext = React.useContext(GlobalContext);
  const context = React.useContext(AuthContext);

  const signOut = () => {
    context.client.Logout();
  };
  const globalClickHandler = () => {
    globalContext.closeOverlay();
  };
  const redirectToLogin = () => {
    history.push(GenerateLink.ForSignIn());
  };
  const redirectToAccount = () => {
    history.push(GenerateLink.ForAccount());
  };
  const redirectToAdmin = () => {
    context.client.SaveCookieRedirect("/Admin").then(
      () => {},
      () => {
        alert("Failed to save cookie");
      }
    );
  };

  return (
    <SideBarPopOut open={globalContext.isDepartmentsOpen} close={globalClickHandler}>
      <div className={styles["departments-links"]}>
        <button
          className={styles["my-account-container-mobile"]}
          onClick={
            context.status.loggedIn && !context.status.isGuest
              ? () => redirectToAccount()
              : () => {
                  redirectToLogin();
                  globalClickHandler();
                }
          }
        >
          <div>
            <div className={styles["person-icon"]} />
            {context.status.loggedIn && !context.status.isGuest ? (
              <div className={styles["name-container"]}>
                <div>Hi,&nbsp;</div>
                <div>{context.status.userInfo.name || context.status.userInfo.email}</div>
              </div>
            ) : (
              <div className={styles["name-container"]}>
                <div>Log in</div>
              </div>
            )}
          </div>
        </button>
        {context.status.isRegistered && (
          <>
            <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForAccount()}>
              Your Account
            </Link>
            <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForOrders()}>
              Your Orders
            </Link>
            <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForFleet()}>
              Your Fleet
            </Link>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                signOut();
                globalContext.toggleDepartments();
              }}
            >
              Sign Out
            </Link>
            <div className="divider" />
          </>
        )}
        <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForAbout()}>
          About
        </Link>
        <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForTeam()}>
          Meet the Team
        </Link>
        <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForSellWithUs()}>
          Sell With Us
        </Link>
        <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForVendor()}>
          Become a Vendor
        </Link>
        <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForHelp()}>
          Help
        </Link>
        <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForPartsBooks()}>
          Parts Books
        </Link>
        {!context.status.isRegistered && (
          <Link onClick={globalContext.toggleDepartments} to={GenerateLink.ForMyFleet()}>
            My Fleet
          </Link>
        )}
        {context.status.isAdmin && (
          <button className={styles.admin} onClick={redirectToAdmin}>
            Admin
          </button>
        )}
      </div>
    </SideBarPopOut>
  );
};

export default NavSidebar;
