import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../contexts/GlobalContext";
import GenerateLink from "../../helpers/GenerateLink";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import AutoCompleteContainer from "../search_bar/AutoComplete/AutoCompleteContainer";
import AccountTab from "./AccountTab";
import styles from "./ScrollNavBar.module.scss";

const ScrollNavBar = () => {
  const globalContext = React.useContext(GlobalContext);
  // const context = React.useContext(AuthContext);
  const [offset, setOffset] = useState(false);
  const globalClickHandler = () => {
    globalContext.closeOverlay();
  };
  const salesBannerRef = React.useRef<HTMLDivElement>(null);

  const { width } = useWindowDimensions();
  useEffect(() => {
    const onScroll = () => {
      const threshHold = width > 576 ? 290 + 4 : 71 + 72 + 6;
      if (window.pageYOffset > threshHold && !offset) {
        setOffset(true);
      } else if (window.pageYOffset <= threshHold) {
        setOffset(false);
      }
    };
    // clean up code
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  });

  useEffect(() => {
    if (offset && width < 576) {
      var salesBannerHeight = salesBannerRef.current?.clientHeight ?? 0;
      document.body.style.setProperty("margin-bottom", `${salesBannerHeight}px`);
    } else {
      document.body.style.setProperty("margin-bottom", `0px`);
    }
    return () => {
      document.body.style.setProperty("margin-bottom", `0px`);
    };
  }, [offset, width]);

  // const redirectToAdmin = () => {
  //     context.client.SaveCookieRedirect("/Admin").then(
  //         () => { },
  //         () => { alert("Failed to save cookie"); }
  //     );
  // }

  return (
    <div className={`${styles["container"]} ${globalContext.isAccountOpen ? styles["dropdown-z-index-top"] : null}`}>
      <div className={`${styles["middle"]}  ${offset ? styles["allOne"] : ""}`}>
        <div className={styles["bar"]}></div>
        {/* <div ref={salesBannerRef} className={`${styles["sales-banner"]} ${offset && width < 576 ? styles["sales-banner-bottom"] : ""}`}>
          <div className={styles["title"]}>Happy Holidays!</div>
          <div className={styles["info-text"]}>
            ⚠️ 🛒 Our online checkout is currently down for maintenance. We're still taking orders over the phone! ☎️ Please call us at{" "}
            <a href={`tel:${process.env.REACT_APP_PHONE_NUMBER}`}>{process.env.REACT_APP_PHONE_NUMBER}</a> ⚠️
          </div>
        </div> */}
        <div className={styles["left"]}>
          <div className={styles.departments}>
            <div onClick={globalContext.toggleDepartments} className={styles.menu} />
          </div>
          <Link onClick={globalClickHandler} to={GenerateLink.ForHelp()}>
            Help
          </Link>
          <Link onClick={globalClickHandler} to={GenerateLink.ForTeam()}>
            Meet The Team
          </Link>
          <Link onClick={globalClickHandler} to={GenerateLink.ForSellWithUs()}>
            Sell With Us
          </Link>
          {/* {context.status.isAdmin && <button className={styles.admin} onClick={redirectToAdmin}>Admin</button>} */}
          <div className={styles.logo} />
        </div>
        <div className={styles["search"]}>
          <div className={styles.logo} />
          <AutoCompleteContainer borderType="fine" />
        </div>
        <div className={styles["right"]}>
          <AccountTab accountClick={globalContext.toggleDepartments} loggedInDisplay="Account" color="grey"></AccountTab>
        </div>
      </div>
      <div className={styles["spacer"]} />
    </div>
  );
};

export default ScrollNavBar;
