import { gql } from "@shane32/graphql";

export const EditAddressMutation = gql`
  mutation ($addressId: ID!, $address: AddressInputGraph!) {
    v1 {
      shippingAddress {
        edit(id: $addressId, address: $address) {
          id
          country {
            id
            name
          }
          firstName
          lastName
          company
          email
          address1
          address2
          city
          stateProvince {
            id
            name
            abbreviation
          }
          zipPostalCode
          phoneNumber
          createdOnUtc
        }
      }
    }
  }
`;

export interface IAddress {
  id: string;
  country: {
    id: string;
    name: string;
  };
  firstName: string;
  lastName: string;
  company: string | null;
  email: string;
  address1: string;
  address2: string | null;
  city: string;
  stateProvince: {
    id: string;
    name: string;
    abbreviation: string | null;
  } | null;
  zipPostalCode: string;
  phoneNumber: string;
  createdOnUtc: string;
}

export interface IEditAddressMutationResult {
  v1: {
    shippingAddress: {
      edit: IAddress;
    };
  };
}

export interface IEditAddressMutationVariables {
  addressId: string;
  address: {
    countryId: string;
    firstName: string;
    lastName: string;
    company?: string | null;
    address1: string;
    address2?: string | null;
    city: string;
    stateProvinceId?: string | null;
    zipPostalCode: string;
    phoneNumber: string;
    emailAddress?: string | null;
  };
}
