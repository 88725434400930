import { gql } from "@shane32/graphql";

export const SetMachineMutation = gql`
  mutation ($id: ID) {
    v1 {
      garage {
        setSelected(garageId: $id)
      }
    }
  }
`;

export interface ISetMachineMutationResult {
  v1: {
    garage: {
      setSelected: boolean;
    };
  };
}

export interface ISetMachineMutationVariables {
  id: string | null;
}
