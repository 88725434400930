import { gql } from "@shane32/graphql";
import { IShoppingCartWithShippingMethods, ShoppingCartPartialQuery } from "../queries/ShoppingCartQuery";

export const CartSetDestinationExternalPaymentMutation = gql`mutation ($countryId: ID!, $stateProvinceId: ID, $postalCode: String) {
  v1 {
    cart {
      setDestination(countryId: $countryId, stateProvinceId: $stateProvinceId, postalCode: $postalCode) ${ShoppingCartPartialQuery(true)}
    }
  }
}`;

export interface ICartSetDestinationExternalPaymentResult {
  v1: {
    cart: {
      setDestination: IShoppingCartWithShippingMethods;
    };
  };
}

export interface ICartSetDestinationExternalPaymentVariables {
  countryId: string;
  stateProvinceId: string | null;
  postalCode: string | null;
}
