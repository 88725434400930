import * as React from "react";
import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import { IProductLeaveFeedback } from "../../models/product/IProductLeaveFeedback";
import { IProductOrderPage } from "../../models/product/IProductOrderPage";
import { IProductSearchResult } from "../../models/product/IProductSearchResult";
import { IProductShoppingCart } from "../../models/product/IProductShoppingCart";
import defaultImage from "../../pages/productpage/images/no_image_avail.jpg";
import { AvailabilityStatusEnum } from "../../queries/ProductPageQuery";
import { IManufacturer, IProduct } from "../../queries/ThankYouPageQuery";
import Checkbox from "../inputs/Checkbox";
import Input from "../inputs/Input";
import Select from "../inputs/Select";
import Rating from "../rating/Rating";
import StockStatus from "../stock_status/StockStatus";
import styles from "./ItemContainer.module.scss";
import ShoppingCartItemContainer from "./ShoppingCartItemContainer";

interface IPropsOrderStatus {
  listingType: "ORDER_STATUS";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsOrderDetails {
  listingType: "ORDER_DETAILS";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsCancel {
  listingType: "CANCEL";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsFeedback {
  listingType: "FEEDBACK";
  item: IProductLeaveFeedback;
  onSelectRating: (rating: number) => void;
  tempRating: number;
}

interface IPropsTrackPackage {
  listingType: "TRACK_PACKAGE";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsReturnRequest {
  listingType: "RETURN_REQUEST";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
  onClickCheckbox: (e: React.FormEvent<HTMLInputElement>) => void;
  checkboxChecked: boolean;
}

interface IPropsOrdersAndReturns {
  listingType: "ORDERS_AND_RETURNS";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsReturnStatus {
  listingType: "RETURN_STATUS";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsPackSlip {
  listingType: "PACK_SLIP";
  item: IProductOrderPage;
  orderQuantity: number;
  price: number;
}

interface IPropsReturnRequestField {
  listingType: "RETURN_REQUEST_FIELD";
  item: IProductOrderPage;
}

interface IPropsShoppingCart {
  listingType: "SHOPPING_CART";
  item: IProductShoppingCart;
  //estimatedTaxRate: number | null;
  backordered: boolean;
  quantity: number;
  reference: string | null;
  lineReference?: string;
  showLineReference: boolean;
  //selectedQuantities: { [id: string]: number };
  quantityText?: string | null;
  //inputQuantity: string;
  buttonsDisabled?: boolean;
  onHandleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  onSelectQuantity: (e: React.FormEvent<HTMLSelectElement>) => void;
  onRemoveItem: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveForLater: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveInputQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  referenceText?: string | null;
  editingRef?: boolean;
  onSaveLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
  onChangeReferenceInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
}

interface IPropsOrderReview {
  listingType: "ORDER_REVIEW";
  item: IProductShoppingCart;
  backordered: boolean;
  quantity: number;
  reference: string | null;
  lineReference?: string;
  showLineReference: boolean;
  quantityText?: string | null;
  buttonsDisabled?: boolean;
  onHandleKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChangeQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  onSelectQuantity: (e: React.FormEvent<HTMLSelectElement>) => void;
  onRemoveItem: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveForLater: (e: React.FormEvent<HTMLButtonElement>) => void;
  onSaveInputQuantity: (e: React.FormEvent<HTMLInputElement>) => void;
  referenceText?: string | null;
  editingRef?: boolean;
  onSaveLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
  onChangeReferenceInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onEditLineReference: (e: React.FormEvent<HTMLButtonElement>) => void;
}

interface IPropsSaveForLater {
  listingType: "SAVE_FOR_LATER";
  item: IProductShoppingCart;
  backordered: boolean;
}

interface IPropsSearchResult {
  listingType: "SEARCH_RESULT";
  item: IProductSearchResult;
  searchString?: string;
  interchange?: string | null;
}

interface IPropsCheckout {
  listingType: "CHECKOUT";
  item: IProductOrderPage;
}

interface IPropsGeneral {}

interface IPropsThankYou {
  listingType: "THANK_YOU";
  item: IProduct;
  reference: string | null;
  quantity: number;
  price: number;
}

type IProps = (
  | IPropsOrderStatus
  | IPropsShoppingCart
  | IPropsSaveForLater
  | IPropsCheckout
  | IPropsThankYou
  | IPropsFeedback
  | IPropsCancel
  | IPropsSearchResult
  | IPropsTrackPackage
  | IPropsReturnRequest
  | IPropsOrdersAndReturns
  | IPropsReturnStatus
  | IPropsReturnRequestField
  | IPropsPackSlip
  | IPropsOrderDetails
  | IPropsOrderReview
) &
  IPropsGeneral;

const ItemContainer = React.memo((props: IProps) => {
  // this is a purecomponent -- DO NOT USE STATE OR CONTEXT HERE
  let additionalDetails: Array<JSX.Element | null> = [];
  let shoppingDetails: Array<JSX.Element | null> = [];
  let returnDetails: Array<JSX.Element | null> = [];
  let price: number | null = null;
  let dollars: string[] | null = null;
  let cents: string | null = null;

  switch (props.listingType) {
    case "CHECKOUT":
    case "SEARCH_RESULT":
    case "RETURN_REQUEST_FIELD":
    case "SHOPPING_CART":
    case "ORDER_REVIEW":
    case "SAVE_FOR_LATER":
      price = props.item.priceAndAvailability.price;
      break;
    case "FEEDBACK":
      break;
    default:
      price = props.price;
  }

  if (price !== null) {
    const priceString = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 4,
    }).format(price);
    const priceSplit = priceString.split(".");
    cents = priceSplit[1];
    dollars = priceSplit[0].split("$");
  }

  switch (props.listingType) {
    case "CANCEL":
    case "CHECKOUT":
    case "PACK_SLIP":
    case "RETURN_REQUEST":
    case "RETURN_REQUEST_FIELD":
    case "RETURN_STATUS":
    case "SHOPPING_CART":
    case "FEEDBACK":
    case "SEARCH_RESULT":
    case "ORDER_DETAILS":
    case "SAVE_FOR_LATER":
    case "THANK_YOU":
    case "ORDER_REVIEW":
      //Add manufacturer field {/*2/2/21 AB TESTING to revert later*/}
      additionalDetails.push(
        props.listingType === "SHOPPING_CART" || props.item.manufacturers.length === 0 ? null : (
          <div className={`${styles["brand"]} ${styles["default-field"]}`} key={"BRAND"}>
            {" "}
            Brand: {(props.item.manufacturers as IManufacturer[]).map((x) => x.manufacturer.name).join(", ")}
          </div>
        )
      );
      additionalDetails.push(
        props.listingType === "SEARCH_RESULT" && props.item.warehouse ? (
          <div className={`${styles["brand"]} ${styles["default-field"]}`} key={"WAREHOUSE"}>
            {" "}
            Warehouse: {props.item.warehouse.address?.city ? props.item.warehouse.address?.city + "," : null}{" "}
            {props.item.warehouse.address?.stateProvince?.abbreviation || null}
          </div>
        ) : null
      );

      switch (props.listingType) {
        case "CANCEL":
        case "PACK_SLIP":
        case "RETURN_REQUEST":
        case "RETURN_STATUS":
          //Add quantity value
          additionalDetails.push(
            <div
              className={
                /*["ORDER_STATUS"].indexOf(this.props.listingType) > -1 ? `${styles['default-field']} ${styles['tablet-hide']}` : */ styles[
                  "default-field"
                ]
              }
              key={1}
            >
              Quantity: {props.orderQuantity}
            </div>
          );
          additionalDetails.push(
            <div className={styles["default-field"]} key={"SOLD_BY"}>
              Sold by: <span className={styles.vendor}>{props.item.vendor !== null ? props.item.vendor.name : null}</span>
            </div>
          );
          switch (props.listingType) {
            case "RETURN_REQUEST":
              returnDetails.push(
                <div key={"RETURN_CHECKBOX"}>
                  <Checkbox checked={props.checkboxChecked} onChange={props.onClickCheckbox} />
                </div>
              );
              break;
          }
          break;
        case "CHECKOUT":
        case "RETURN_REQUEST_FIELD":
        case "SHOPPING_CART":
        case "ORDER_REVIEW":
        case "FEEDBACK":
        case "ORDER_DETAILS":
        case "SAVE_FOR_LATER":
        case "THANK_YOU":
          additionalDetails.push(
            <div className={styles["default-field"]} key={"SOLD_BY"}>
              Sold by:{" "}
              {
                /*props.listingType === "SHOPPING_CART" ?

                        <a href="" className={styles.vendor}>{props.item.vendor !== null ? props.item.vendor.name : null}</a>
                        :*/
                <span className={styles.vendor}>{props.item.vendor !== null ? props.item.vendor.name : null}</span>
              }
            </div>
          );

          switch (props.listingType) {
            case "THANK_YOU":
              if (props.reference && props.reference !== "")
                additionalDetails.push(
                  <div className={styles["default-field"]} key={"REFERENCE"}>
                    <div>
                      Reference: <span>{props.reference}</span>
                    </div>
                  </div>
                );
              additionalDetails.push(
                <div className={`${styles["default-field"]} ${styles["thank-you-hide"]}`} key={"QTY"}>
                  Quantity: {props.quantity}
                </div>
              );
              break;
            case "FEEDBACK":
              additionalDetails.push(
                <div className={styles["default-field"]} key={"RATING"}>
                  <Rating largeStars onChange={props.onSelectRating} value={props.tempRating ? props.tempRating : 0} />
                </div>
              );
              break;
            case "SHOPPING_CART":
            case "SAVE_FOR_LATER":
            case "ORDER_REVIEW":
              additionalDetails.push(
                <div className={styles["default-field"]} key={"PRICE_STATUS"}>
                  <StockStatus status={props.backordered ? AvailabilityStatusEnum.Backordered : props.item.priceAndAvailability.status} />
                </div>
              );
              switch (props.listingType) {
                case "SHOPPING_CART":
                case "ORDER_REVIEW":
                  let quantitySelect: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

                  shoppingDetails.push(
                    <div key={"CART_ROW"} className={styles.inputs}>
                      <div className={`${styles["default-field"]} ${styles["card-inputs"]}`}>
                        <span className={styles.quantity}>Quantity:</span>
                        <span className={styles.qty}>Qty:</span>
                        {props.onSelectQuantity !== undefined ? (
                          props.quantity >= 10 ? (
                            <React.Fragment>
                              <Input
                                value={props.quantityText === "" ? "" : props.quantityText || props.quantity + ""}
                                onKeyPress={props.onHandleKeyPress}
                                onChange={props.onChangeQuantity}
                                onBlur={props.onSaveInputQuantity}
                                id={props.item.id}
                                disabled={props.buttonsDisabled}
                                inputMode="numeric"
                              />
                            </React.Fragment>
                          ) : (
                            <Select
                              onChange={props.onSelectQuantity}
                              id={props.item.id}
                              value={props.quantity}
                              disabled={props.buttonsDisabled}
                            >
                              {quantitySelect.map((select, index) => (
                                <option key={index} value={select}>
                                  {select === 10 ? "10+" : select}
                                </option>
                              ))}
                            </Select>
                          )
                        ) : null}
                        {props.onRemoveItem !== undefined ? (
                          <button
                            className="btn secondary w-small fs-small short"
                            onClick={props.onRemoveItem}
                            id={props.item.id}
                            disabled={props.buttonsDisabled}
                          >
                            REMOVE
                          </button>
                        ) : null}
                        {props.onSaveForLater !== undefined ? (
                          <button
                            className="btn secondary w-small fs-small short"
                            onClick={props.onSaveForLater}
                            id={props.item.id}
                            disabled={props.buttonsDisabled}
                          >
                            SAVE FOR LATER
                          </button>
                        ) : null}
                      </div>
                      {props.showLineReference ? (
                        props.reference && !props.editingRef ? (
                          <div className={`${styles["line-reference"]} ${styles["set-reference"]}`}>
                            <label>Reference:</label>
                            <div title="Reference:">{props.referenceText}</div>
                            <button type="submit" className="btn secondary w-small fs-small short" onClick={props.onEditLineReference}>
                              EDIT
                            </button>
                          </div>
                        ) : (
                          <div className={styles["line-reference"]}>
                            <Input inlineLabel title="Reference:" value={props.referenceText} onChange={props.onChangeReferenceInput} />
                            <button type="submit" className="btn secondary w-small fs-small short" onClick={props.onSaveLineReference}>
                              SAVE
                            </button>
                          </div>
                        )
                      ) : null}
                    </div>
                  );
                  break;
              }
              break;
          }
          break;
        case "SEARCH_RESULT":
          additionalDetails.push(
            <div className={styles["details-container"]} key={"SEARCH_RESULT"}>
              {/*{props.item ? <Rating value={props.item.approvedRatingSum} readonly /> : ''}*/}
              <div className={styles["search-result-price"]}>
                {price !== null ? (
                  <div className={styles.superscript}>
                    <span>$</span>
                    {dollars}
                    <span>{cents}</span>
                  </div>
                ) : null}
                {props.item.additionalShippingCharge ? (
                  <span className={styles["default-field"]}>
                    +{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(props.item.additionalShippingCharge)}{" "}
                    shipping
                  </span>
                ) : null}
                {props.item.priceAndAvailability.status ? (
                  <div className="ml-10 mb-5">
                    <StockStatus status={props.item.priceAndAvailability.status} />
                  </div>
                ) : null}
              </div>
            </div>
          );
          break;
      }
      break;

    case "ORDER_STATUS":
    case "TRACK_PACKAGE":
    case "ORDERS_AND_RETURNS":
      additionalDetails.push(
        <div className={styles["default-field"]} key={"VENDOR_NAME"}>
          Sold by:{" "}
          {
            /*props.listingType === "ORDER_STATUS" ?

                <a href="" className={styles.vendor}>{props.item.vendor !== null ? props.item.vendor.name : null}</a>
                :*/
            <span className={styles.vendor}>{props.item.vendor !== null ? props.item.vendor.name : null}</span>
          }
        </div>
      );
      additionalDetails.push(
        <div
          className={
            /*["ORDER_STATUS"].indexOf(props.listingType) > -1 ? `${styles['default-field']} ${styles['tablet-hide']}` : */ styles[
              "default-field"
            ]
          }
          key={"ORDER_QTY"}
        >
          Quantity: {props.orderQuantity}
        </div>
      );
      break;
  }

  //const price = props.listingType !== 'SEARCH_RESULT' && props.listingType !== 'FEEDBACK' ? (props.price !== undefined ? props.price : props.item.priceAndAvailability.price) : null;

  const elemnt = () => {
    switch (props.listingType) {
      case "SHOPPING_CART":
      case "ORDER_REVIEW":
        return <ShoppingCartItemContainer prop={props} />;

      default:
        return (
          <>
            <div
              className={
                props.listingType === "ORDER_STATUS"
                  ? `${styles["image-details-container"]} ${styles["orders-and-returns"]}`
                  : props.listingType === "ORDER_DETAILS"
                  ? `${styles["image-details-container"]} ${styles["order-details"]}`
                  : styles["image-details-container"]
              }
            >
              {returnDetails}
              <div className={styles["image-container"]}>
                {props.listingType === "ORDER_STATUS" ||
                props.listingType === "ORDER_DETAILS" ||
                props.listingType === "SEARCH_RESULT" ||
                props.listingType === "SAVE_FOR_LATER" ? (
                  <Link
                    to={GenerateLink.ForProduct(
                      props.item.id,
                      props.item.seoName,
                      (props.listingType === "SEARCH_RESULT" && props.searchString) || undefined
                    )}
                  >
                    <img src={props.item.picture === null ? defaultImage : props.item.picture.thumbnail.url} alt="" />
                  </Link>
                ) : (
                  <img src={props.item.picture === null ? defaultImage : props.item.picture.thumbnail.url} alt="" />
                )}
              </div>
              <div className={styles["details-container"]}>
                {props.listingType === "ORDER_STATUS" ||
                props.listingType === "ORDER_DETAILS" ||
                props.listingType === "SEARCH_RESULT" ||
                props.listingType === "SAVE_FOR_LATER" ? (
                  <Link
                    className={styles["item-name"]}
                    to={GenerateLink.ForProduct(
                      props.item.id,
                      props.item.seoName,
                      (props.listingType === "SEARCH_RESULT" && props.searchString) || undefined
                    )}
                  >
                    {/*props.item.manufacturerPartNumber !== null ? `${props.item.manufacturerPartNumber}:` : ''*/} {props.item.name}
                  </Link>
                ) : (
                  <div className={`${styles["non-clickable"]}`}>
                    {/*props.item.manufacturerPartNumber !== null ? `${props.item.manufacturerPartNumber}:` : ''*/} {props.item.name}
                  </div>
                )}
                {price != null && props.listingType !== "SEARCH_RESULT" ? (
                  <div
                    className={
                      props.listingType === "ORDER_STATUS"
                        ? `${styles.price} ${styles["tablet-hide"]}`
                        : props.listingType === "THANK_YOU"
                        ? `${styles.price} ${styles["thank-you-hide"]}`
                        : styles.price
                    }
                  >
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 4,
                    }).format(price)}
                  </div>
                ) : null}
                {additionalDetails}
                {props.listingType === "SEARCH_RESULT" && props.interchange ? (
                  <div className={styles.interchange}>
                    Direct interchange with <span>{props.interchange}</span>
                  </div>
                ) : null}
              </div>
              {shoppingDetails}
            </div>
          </>
        );
    }
  };

  return elemnt();
});

//function map<T, U>(array: T[], func: (value: T, index: number) => U): U[] {
//    return array.map(func);
//}

export default ItemContainer;

/* Purchased Item :
 * Picture - ALL
 * Number: Name - ALL
 * Price - ALL
 * Brand - CANCEL, CHECKOUT, PACKSLIP, RETURN_REQUEST, RETURN_STATUS, SEARCH_RESULT
 * Sold By - CANCEL, CHECKOUT, ORDER_STATUS, PACKSLIP, RETURN_REQUEST, RETURN_STATUS, TRACK_PACKAGE
 * Add Rating - FEEDBACK
 * Quantity - CANCEL, ORDER_STATUS, RETURN_REQUEST, RETURN_STATUS, PACKSLIP, TRACK_PACKAGE
 * Reference - CANCEL, CHECKOUT
 * Return Quantity - RETURN_REQUEST_FIELD
 * Reason for Return - RETURN_REQUEST_FIELD
 */
