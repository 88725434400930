import { gql } from "@shane32/graphql";
import { IShoppingCart, ShoppingCartPartialQuery } from "../queries/ShoppingCartQuery";

export const AddProductToCartFromCatalogMutation = gql`mutation($productId:ID!, $shoppingCartTypeId:ID!, $qty: Int!) {
  v1 {
    cart {
        add(productId: $productId, shoppingCartTypeId: $shoppingCartTypeId, qty: $qty) ${ShoppingCartPartialQuery()}
    }
  }
}`;

export interface IAddProductToCartFromCatalogResult {
  v1: {
    cart: {
      add: IShoppingCart;
    };
  };
}

export interface IAddProductToCartFromCatalogVariables {
  productId: string;
  qty: number;
  shoppingCartTypeId: string;
}
