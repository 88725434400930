import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";

export const SearchQuery = gql`
  query (
    $searchString: String
    $sortMethod: SortMethodEnum
    $filters: [IdAndValuesGraph!]
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    v1 {
      searchResults(searchString: $searchString, filters: $filters) {
        productsConnection(first: $first, last: $last, before: $before, after: $after, sortMethod: $sortMethod) {
          totalCount
          items {
            additionalShippingCharge
            approvedRatingSum
            approvedTotalReviews
            id
            isFreeShipping
            manufacturerPartNumber
            manufacturers {
              manufacturer {
                name
              }
            }
            name
            seoName
            partInterchange
            priceAndAvailability {
              allowedQuantities
              backorderAvailabilityRange
              oldPrice
              orderMaximumQuantity
              orderMinimumQuantity
              preorderAvailabilityStartDateTime
              price
              status
              stock
              canNotify
            }
            picture {
              thumbnail(size: 100) {
                url
              }
            }
            showOnHomePage
            warehouse {
              address {
                city
                stateProvince {
                  abbreviation
                }
              }
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export const SearchFiltersQuery = gql`
  query ($searchString: String, $filters: [IdAndValuesGraph!]) {
    v1 {
      searchResults(searchString: $searchString, filters: $filters) {
        filters {
          id
          name
          values {
            id
            name
            quantity
          }
        }
      }
    }
  }
`;

export interface ISearchQueryProductsConnection {
  totalCount: number;
  items: Array<{
    approvedRatingSum: number;
    approvedTotalReviews: number;
    additionalShippingCharge: number;
    id: string;
    isFreeShipping: boolean;
    manufacturerPartNumber: string;
    manufacturers: Array<{
      manufacturer: {
        name: string;
      };
    }>;
    name: string;
    seoName: string | null;
    partInterchange: string[];
    priceAndAvailability: IPriceAndAvailability;
    picture: {
      thumbnail: {
        url: string;
      };
    } | null;
    showOnHomePage: boolean;
    warehouse: {
      address: {
        city: string | null;
        stateProvince: {
          abbreviation: string | null;
        };
      };
    } | null;
  }>;
  pageInfo: {
    startCursor: string;
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
}

export interface ISearchQuery {
  v1: {
    searchResults: {
      productsConnection: ISearchQueryProductsConnection;
    };
  };
}

export type ISearchQueryFilters = Array<{
  id: string;
  name: string;
  values: Array<{
    id: string;
    name: string;
    quantity: number;
  }>;
}>;

export interface ISearchFiltersQuery {
  v1: {
    searchResults: {
      filters: ISearchQueryFilters;
    };
  };
}

export enum SortMethodEnum {
  DEFAULT = "DEFAULT",
  FEATURED = "FEATURED",
  PRICE_HIGH = "PRICE_HIGH",
  PRICE_LOW = "PRICE_LOW",
  A_TO_Z = "A_TO_Z",
  Z_TO_A = "Z_TO_A",
  HIGHEST_RATING = "HIGHEST_RATING",
  BEST_SELLING = "BEST_SELLING",
  RELEVANCE = "RELEVANCE",
}

export interface ISearchQueryVariables {
  searchString?: string | null;
  //relatedManufacturerPartNumber?: string | null;
  filters?: Array<{ id: string; values: string[] }> | null;
  first?: number | null;
  last?: number | null;
  before?: string | null;
  after?: string | null;
  id?: string;
  sortMethod?: string | null;
  thumbnailSizes?: number[];
}
