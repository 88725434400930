import * as React from "react";
import { isTest } from "../globalVariables";
import useTimer from "./useTimer";

declare const window: Window & { ga: any; paypal: any };

let initializedPaymentMethods: boolean = false;

let braintree: typeof import("braintree-web") | null = null;
let braintreeResolve: (data: typeof import("braintree-web")) => void;

let braintreeReject: (reason: any) => void;

// initialize a promise that can be utilized by any instance of useTrackers
const braintreePromise = new Promise<typeof import("braintree-web")>((resolve, reject) => {
  //store global handles to the resolve and reject methods
  braintreeResolve = resolve;
  braintreeReject = reject;
});

function stateEquals(state1: IPaymentScriptsState, state2: IPaymentScriptsState) {
  return (
    state1.payPalLoaded === state2.payPalLoaded &&
    state1.googlePayLoaded === state2.googlePayLoaded &&
    state1.braintreeLoaded === state2.braintreeLoaded &&
    state1.braintree === state2.braintree
  );
}

function getCurState() {
  const ret = {
    googlePayLoaded: typeof google === "object",
    payPalLoaded: typeof window.paypal === "object",
    braintreeLoaded: !!braintree,
    braintree: braintree,
    allLoaded: false,
  };
  ret.allLoaded = ret.googlePayLoaded && ret.payPalLoaded && ret.braintreeLoaded;
  return ret;
}

interface IPaymentScriptsState {
  googlePayLoaded: boolean;
  payPalLoaded: boolean;
  braintreeLoaded: boolean;
  braintree: typeof import("braintree-web") | null;
}

export default function usePaymentScripts(skip: boolean) {
  if (!initializedPaymentMethods && !skip) initializePaymentMethods();

  // state is used so that when a script loads, it sets state and causes a component update (aka refresh)
  const [state, setState] = React.useState(getCurState());

  // this timer monitors when the scripts actually loads
  const [, setTimer2] = useTimer(
    () => {
      // update state if it has changed
      const curState = getCurState();
      if (!stateEquals(curState, state)) {
        setState(curState);
      }
      // once all dependencies are loaded, quit polling
      if (curState.allLoaded) {
        setTimer2(0);
      }
    },
    state.allLoaded || isTest.value ? 0 : 100,
    true
  ); // don't poll if everything is already loaded

  if (!state.braintreeLoaded) {
    braintreePromise.then(() => {
      const curState = getCurState();
      if (!stateEquals(curState, state)) {
        setState(curState);
      }

      if (curState.allLoaded) {
        setTimer2(0);
      }
    });
  }

  return state;
}

function initializePaymentMethods() {
  //be sure to run this code only once
  if (initializedPaymentMethods) return;
  initializedPaymentMethods = true;

  if (isTest.value) {
    braintreeReject("Braintree disabled during tests");
    return;
  }

  if (navigator.userAgent.toLowerCase().indexOf("googlebot") !== -1) {
    braintreeReject("Braintree disabled for googlebot");
    return;
  }

  // paypal
  const paypalCheckoutComponentScript = document.createElement("script");
  paypalCheckoutComponentScript.src = "https://www.paypal.com/sdk/js?client-id=" + process.env.REACT_APP_PAYPAL_CLIENTID + "&vault=true";
  paypalCheckoutComponentScript.async = true;
  document.body.appendChild(paypalCheckoutComponentScript);

  // google pay
  const googlePayScript = document.createElement("script");
  googlePayScript.src = "https://pay.google.com/gp/p/js/pay.js";
  googlePayScript.async = true;
  document.body.appendChild(googlePayScript);

  // braintree (not an external script, so use code-splitting to separate it)
  // note: currently the webpackPreload tag does not work
  // https://github.com/facebook/create-react-app/issues/3319
  // https://github.com/facebook/create-react-app/issues/5925
  // https://github.com/facebook/create-react-app/issues/7935
  const braintreePromise = import(/* webpackPreload: true */ "braintree-web");
  braintreePromise.then(
    (b) => {
      //when the script has loaded, store a global reference to braintree
      braintree = b;
      //and then notify all listening useTracker instances (or any instances created in the future)
      braintreeResolve(b);
    },
    (reason) => {
      //log the error
      console.error("braintree webpack bundle failed to load", reason);
      //notify all listening useTracker instances
      braintreeReject(reason);
    }
  );
}
