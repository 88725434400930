import { GraphQLContext, IGraphQLClient } from "@shane32/graphql";
import * as React from "react";
import { PendingOperationsContext } from "../contexts/PendingOperationsContext";
import GenerateMeta from "../helpers/GenerateMeta";
import QueryString from "../helpers/querystring";
import useTimer from "./useTimer";

declare const window: Window & {
  paypal: any;
  initialLoadTime: number;
  sessionId: number;
  dataLayer: any;
  track: any;
};

export const trackerOptions = parseUrl(window.location.href);

export interface ITrackOrder {
  id: string;
  revenue: number;
  tax: number;
  shipping: number;
  email: string | null | undefined;
}

export interface IProductTracker {
  id: string;
  name: string;
  price: number;
  brand: string;
  quantity: number;
}

let initializedTrackers = false;

let browserLoggingUri = "";

initializeGoogleTagManager();

//interface ITrackProductsOrdered {
//    id: string,
//    name: string,
//    quantity: number,
//    price: number,
//};

const useTrackers = () => {
  const client = React.useContext(GraphQLContext).client;
  const anyPendingRequests = React.useContext(PendingOperationsContext);

  // This timer watches for all pending graphql queries to finish, then initializes the trackers, braintree, etc
  const [, setTimer] = useTimer(() => {
    // Only call initializeTrackers if it has not already been called
    if (initializedTrackers) {
      setTimer(0);
    } else if (!anyPendingRequests.anyPendingOperations()) {
      setTimer(0);
      initializeTrackers(client);
    }
  });

  // initialize the trackers once the DOM is fully populated
  React.useEffect(() => {
    if (!initializedTrackers) setTimer(100, true);
  }, [setTimer]);

  return {
    newUri: React.useCallback(
      (uri: string) => {
        if (window.track) {
          try {
            window.track.view(uri);
          } catch (error) {
            try {
              window.track.exception(error);
            } catch (e) {}
          }
        } else if (window.dataLayer) {
          window.dataLayer.push({
            event: "pageview",
            page: uri,
            title: document.title,
            ecommerce: null,
          });
        }
        browserLoggingUri = uri;
        if (initializedTrackers) browserLog(client, uri);
      },
      [client]
    ),
    addToCart: React.useCallback((product: IProductTracker) => {
      if (window.track) {
        try {
          window.track.add(product);
        } catch (error) {
          try {
            window.track.exception(error);
          } catch (e) {}
        }
      }
    }, []),
    placeOrder: React.useCallback((order: ITrackOrder, products: IProductTracker[]) => {
      if (window.track) {
        try {
          window.track.order(order, products);
        } catch (error) {
          try {
            window.track.exception(error);
          } catch (e) {}
        }
      }
    }, []),
    trackEvent: React.useCallback((name: string, value: string) => {
      if (window.track) {
        try {
          window.track.event(name, value);
        } catch (error) {
          try {
            window.track.exception(error);
          } catch (e) {}
        }
      }
    }, []),
    exception: React.useCallback((error: any) => {
      if (window.track) {
        try {
          window.track.exception(error);
        } catch (e) {
          try {
            window.track.exception(e);
          } catch (e) {}
        }
      }
    }, []),
  };
};

function initializeGoogleTagManager() {
  (window as any).dataLayer = [
    {
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
      originalLocation:
        document.location.protocol + "//" + document.location.hostname + document.location.pathname + document.location.search,
      ecommerce: null,
    },
  ];
}

function parseUrl(originalUri: string) {
  let queryParams = new QueryString("");
  const qPos = originalUri.indexOf("?");
  if (qPos >= 0) {
    const hashPos = originalUri.indexOf("#");
    if (hashPos > qPos) {
      queryParams = new QueryString(originalUri.slice(qPos, hashPos));
    } else {
      queryParams = new QueryString(originalUri.slice(qPos));
    }
  }
  const noGa = queryParams.getString("noGA");
  const onDemandPayment = queryParams.getString("onDemandPayment");
  return {
    noGa,
    onDemandPayment,
  };
}

function initializeTrackers(client: IGraphQLClient) {
  //be sure to run this code only once
  if (initializedTrackers) return;
  initializedTrackers = true;

  browserLog(client, browserLoggingUri); // browser log

  // Generate Metatags
  GenerateMeta.Init();

  const userAgent = navigator.userAgent.toLowerCase();
  console.log(
    userAgent.indexOf("bot") < 0,
    userAgent.indexOf("lighthouse") < 0,
    !trackerOptions.noGa,
    `${process.env.REACT_APP_IS_PRODUCTION}` === "1"
  );
  // if (userAgent.indexOf("bot") < 0 && // not a bot
  //     userAgent.indexOf("lighthouse") < 0 && // or lighthouse
  //     !trackerOptions.noGa && // or query string excluded
  //     `${process.env.REACT_APP_IS_PRODUCTION}` === "1") {
  //     const googleTagManager = document.createElement("script");
  //     googleTagManager.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`;
  //     googleTagManager.async = true;
  //     document.body.append(googleTagManager);
  // }
}

function browserLog(client: IGraphQLClient, uri: string) {
  const requestVariables = {
    log: {
      browserHeight: window.innerHeight,
      browserWidth: window.innerWidth,
      //referrer: document.referrer,
      timeFromLoad: Date.now() - window.initialLoadTime,
      url: uri,
      sessionId: window.sessionId,
    },
  };
  client.ExecuteQueryRaw({
    query: "mutation($log:BrowserLogInputGraph!){v1{browserLog{log(log:$log)}}}",
    variables: requestVariables,
  });
}

export default useTrackers;
