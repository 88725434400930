import { gql } from "@shane32/graphql";

export const LoginAndSecurityQuery = gql`
  {
    isAuthenticated
    v1 {
      my {
        firstName
        lastName
        feedbackName
        email
        phoneNumber
      }
    }
  }
`;

export interface ICustomer {
  firstName: string | null;
  lastName: string | null;
  feedbackName: string | null;
  email: string | null;
  phoneNumber: string | null;
}

export interface ILoginAndSecurityQuery {
  isAuthenticated: boolean;
  v1: {
    my: ICustomer | null;
  };
}

export interface ILoginAndSecurityQueryVariables {}
