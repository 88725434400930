import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";

export interface IPreviouslyOrderedProductsQuery {
  v1: {
    my: {
      orders: {
        items: {
          createdOnUtc: Date;
          orderItems: {
            product: {
              seoName: string;
              id: string;
              name: string;
              additionalShippingCharge: number;
              manufacturerPartNumber: string | null;
              approvedRatingSum: number;
              approvedTotalReviews: number;
              priceAndAvailability: IPriceAndAvailability;
              manufacturers: Array<{
                displayOrder: number;
                manufacturer: {
                  displayOrder: number;
                  id: string;
                  name: string;
                  picture: {
                    imageUrl: string;
                  } | null;
                };
              }>;
              picture: {
                thumbnail: {
                  url: string;
                };
              };
              shipSeparately: boolean;
              sku: string | null;
              vendor: {
                name: string;
              } | null;
            };
            quantity: number;
          }[];
        }[];
      };
    } | null;
  };
}

export const PreviouslyOrderedProductsQuery = gql`
  query {
    v1 {
      my {
        orders {
          items {
            createdOnUtc
            orderItems {
              id
              product {
                seoName
                id
                name
                manufacturerPartNumber
                approvedRatingSum
                approvedTotalReviews
                priceAndAvailability {
                  allowedQuantities
                  backorderAvailabilityRange
                  canNotify
                  oldPrice
                  orderMinimumQuantity
                  orderMaximumQuantity
                  preorderAvailabilityStartDateTime
                  price
                  status
                  stock
                }
                manufacturers {
                  displayOrder
                  manufacturer {
                    displayOrder
                    id
                    name
                    picture {
                      imageUrl
                    }
                  }
                }
                picture {
                  thumbnail(size: 100) {
                    url
                  }
                }
                shipSeparately
                sku
                vendor {
                  name
                }
              }
              quantity
            }
          }
        }
      }
    }
  }
`;
