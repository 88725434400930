import { gql, useMutation, useQuery } from "@shane32/graphql";
import React, { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import GenericBreadCrumbs, { IbreadCrumbs } from "../../../components/breadcrumb/GenericBreadCrumbs";
import Loading from "../../../components/loading/Loading";
import Modal from "../../../components/modal/Modal";
import ModalHeader from "../../../components/modal/ModalHeader";
import Navbar from "../../../components/navbar/Navbar";
import AutoCompleteContainer from "../../../components/search_bar/AutoComplete/AutoCompleteContainer";
import GlobalContext from "../../../contexts/GlobalContext";
import GenerateLink from "../../../helpers/GenerateLink";
import QueryString from "../../../helpers/querystring";
import useHeader from "../../../hooks/useHeader";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import BackButton from "../components/DiagramHeader";
import BookMark from "./BookMark/BookMark";
import styles from "./DiagramPage.module.scss";
import DiagramSectionNav from "./DiagramSectionNav/DiagramSectionNav";
import ImageZoomer from "./ImageZoomer";
import PartsList from "./PartsList/PartsList";
import { AuthContext } from "@zboxglobal/zboxauth";

export enum IdType {
  Section = "section",
  Diagram = "diagram",
  Model = "model",
}
interface IParams {
  modelId: string;
}

interface diagramVars {
  id: string;
}

interface BrandVars {
  modelId: string;
}

const QueryMachineSections = function (modelId: string, skip: boolean) {
  const machineSectionQuery = gql`
    query ($id: ID!) {
      v1 {
        machineSections(machineModelId: $id) {
          id
          title
          parentSectionId
          description
          seoName
          diagrams {
            id
          }
        }
      }
    }
  `;

  return useQuery<IMachineSectionQuery, { id: string }>(machineSectionQuery, {
    variables: { id: modelId },
    skip: skip,
  });
};

interface IMachineSectionQuery {
  v1: {
    machineSections: ISection[];
  };
}

export interface ISection {
  id: string;
  title: string;
  parentSectionId: number;
  description: string;
  seoName: string;
  diagrams: {
    id: string;
  }[];
}

const QueryBrand = function (modelId: string) {
  const brandQuery = gql`
    query ($modelId: ID!) {
      v1 {
        machineModel(id: $modelId) {
          id
          name
          machineManufacturer {
            name
            id
          }
        }
      }
    }
  `;

  return useQuery<IBrandQuery, BrandVars>(brandQuery, {
    variables: { modelId: modelId },
  });
};

interface IBrandQuery {
  v1: {
    machineModel: {
      id: string;
      name: string;
      machineManufacturer: {
        id: string;
        name: string;
      };
    };
  };
}

//get diagram query
const QueryDiagram = function (variables: diagramVars, skip: boolean) {
  const diagramQuery = gql`
    query ($id: ID!) {
      v1 {
        diagrams(id: $id) {
          id
          imageUrl
          diagramLineItems {
            partDrawingIdentifier
            partNumber
            quantity
            quantity
            comment
            products {
              name
              priceAndAvailability {
                price
                status
                stock
              }
              categories {
                displayOrder
                category {
                  name
                }
              }
              seoName
              id
            }
          }
          machineSection {
            title
            seoName
            machineModel {
              name
              id
            }
            machineSection {
              title
              seoName
              id
            }
          }
        }
      }
    }
  `;

  return useQuery<IDiagramQuery, diagramVars>(diagramQuery, {
    skip: skip,
    variables: variables,
  });
};

interface IDiagramQuery {
  v1: {
    diagrams: {
      id: string;
      imageUrl: string;
      diagramLineItems: IDiagramLineItem[];
      machineSection: {
        title: string;
        machineModel: {
          name: string;
          id: string;
        };
        machineSection: {
          title: string;
          id: string;
        };
      };
    }[];
  };
}

export interface IDiagramLineItem {
  partDrawingIdentifier: string;
  partNumber: string;
  quantity: number;
  comment: string;
  products: {
    name: string;
    priceAndAvailability: {
      price: number;
      status: string;
      stock: number;
    };
    seoName: string;
    id: string;
    categories: {
      displayOrder: number;
      category: {
        name: string;
      };
    }[];
  }[];
}

const logDiagramMutation = `
mutation ($diagramId: ID!) {
  v1 {
    diagram {
      logDiagram(diagramId: $diagramId)
    }
  }
}
`;

interface ILogDiagramMutationVariables {
  diagramId: string;
}

interface ILogDiagramMutationResult {
  v1: {
    diagram: {
      logDiagram: boolean;
    };
  };
}

export const DiagramParams = () => {
  const qs = new QueryString(useLocation().search);
  const diagramId: string = qs.getString("diagramId") ? (qs.getString("diagramId") as string) : "";
  const sectionId = qs.getString("sectionId");
  const isDiagram = diagramId !== "";
  const productIdAnchor = qs.getString("productIdAnchor");

  return { diagramId, sectionId, isDiagram, productIdAnchor };
};

const DiagramPage = () => {
  useHeader("NoSearchMobile");
  const windowDim = useWindowDimensions();
  const globalContext = React.useContext(GlobalContext);
  const authContext = React.useContext(AuthContext);

  const params = useParams<IParams>();

  const { diagramId, sectionId, isDiagram, productIdAnchor } = DiagramParams();
  const [modalData, setModalText] = React.useState<IDiagramLineItem | null | undefined>(null);

  const { data: machineData, loading: machineDataLoading } = QueryBrand(params.modelId);
  const { data, loading: diagramLoading } = QueryDiagram({ id: diagramId }, !diagramId);

  const [logDiagram] = useMutation<ILogDiagramMutationResult, ILogDiagramMutationVariables>(logDiagramMutation);

  const { data: sectiondata, loading: sectionLoading } = QueryMachineSections(params.modelId, false);
  const sections = sectiondata?.v1.machineSections == null ? [] : sectiondata.v1.machineSections;
  const section = sectionId ? sections.find((s) => s.id === sectionId) : sections.find((s) => s.diagrams.find((d) => d.id === sectionId));
  const diagram = data?.v1.diagrams[0];

  const partsListRef = React.useRef<HTMLDivElement>(null);
  const drawerHandleRef = React.useRef<HTMLDivElement>(null);

  const loading = machineDataLoading || diagramLoading || sectionLoading;

  const diagramLineItems = (diagram?.diagramLineItems ?? []).sort((a, b) => {
    return (a.partDrawingIdentifier ?? "").localeCompare(b.partDrawingIdentifier ?? "", undefined, { numeric: true, sensitivity: "base" });
  });

  useEffect(() => {
    authContext.client.EnsureGuest(true).then(
      () => console.log("created a guest"),
      () => console.log("could not create a guest")
    );
  }, [authContext.client]);

  //run the logDiagram mutation when the user or guest is given an id.
  //this will keep track of when they last visited a specific diagram.
  useEffect(() => {
    if (authContext.status.userInfo?.id && diagramId) {
      logDiagram({ variables: { diagramId: diagramId } });
    }
    //there is a warning for "logDiagram" as a dependency, but it causes an additional render if it is added,
    //so we are leaving it out for that reason
  }, [authContext.status.userInfo?.id, diagramId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDiagram) {
      document.documentElement.style.overflow = "auto";
      window.scrollTo(0, 1);
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.overflowY = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.overflowY = "initial";
    }

    return () => {
      document.documentElement.style.overflow = "auto";
      document.documentElement.style.overflowY = "initial";
    };
  }, [isDiagram, loading]);

  const onNotesClick = (index: number) => {
    setModalText(diagramLineItems[index]);
  };

  const overlayOnClick = () => {
    setModalText(null);
    globalContext.closeOverlay();
    globalContext.setFitmentSideBar("ClOSED");
  };

  const headerData = (() => {
    const parentSection = sections?.find((s) => s.id === section?.parentSectionId?.toString());
    if (parentSection && section?.parentSectionId) {
      //goes back a section
      return {
        title: section.title,
        mobileBackTitle: "Category",
        url: GenerateLink.ForSection(params.modelId, section.parentSectionId.toString(), section.seoName),
      };
    } else if (section && machineData?.v1.machineModel.id) {
      //goes back to model page
      return {
        title: section.title,
        mobileBackTitle: "Category",
        url: GenerateLink.ForDiagramModel(machineData.v1.machineModel.id),
      };
    } else if (machineData?.v1.machineModel.machineManufacturer.id) {
      //goes back to brand page
      return {
        title: "Category",
        mobileBackTitle: "Models",
        url: GenerateLink.ForDiagramBrand(machineData.v1.machineModel.machineManufacturer.id),
      };
    } else {
      //should only happen if network error
      //goes back to diagram home page
      return {
        title: "??",
        mobileBackTitle: "home",
        url: GenerateLink.ForPartsBooks(),
      };
    }
  })();

  const gotoBrandPage = () => {
    if (machineData?.v1.machineModel.machineManufacturer.id != null) {
      return GenerateLink.ForDiagramBrand(machineData.v1.machineModel.machineManufacturer.id);
    } else {
      return "";
    }
  };

  const createMobileCrumbs: () => IbreadCrumbs[] = () => {
    var parentId = section?.id?.toString();
    const startArray = createDesktopCrumbs(true);

    const array: IbreadCrumbs[] = [];
    addToArray(parentId);

    function addToArray(argParentId: string | undefined) {
      if (!argParentId) return;

      const parent = sections.find((s) => s.id === argParentId);
      if (!parent) return;

      array.push({
        name: parent.title,

        url: GenerateLink.ForSection(params.modelId, parent.id.toString(), parent.seoName),
      });

      addToArray(parent.parentSectionId?.toString());
    }

    const doneArray = startArray.concat(array.reverse());
    doneArray[doneArray.length - 1].url = null;
    return doneArray;
  };

  const createDesktopCrumbs: (useModel: boolean) => IbreadCrumbs[] = (useModel: boolean) => {
    const array: IbreadCrumbs[] = [
      { name: "Brands", url: GenerateLink.ForPartsBooks() },
      {
        name: machineData?.v1.machineModel.machineManufacturer.name ?? "brand",
        url: gotoBrandPage(),
      },
    ];
    if (useModel && machineData?.v1.machineModel.id) {
      array.push({
        name: machineData.v1.machineModel.name,
        url: GenerateLink.ForDiagramModel(machineData.v1.machineModel.id),
      });
    } else {
      array.push({
        name: machineData?.v1.machineModel.name ?? "Model",
        url: null,
      });
    }

    return array;
  };

  return (
    <div className={isDiagram ? styles["diagram-body"] : styles["nav-body"]} id="thebody">
      {modalData ? <NotesModal onExit={overlayOnClick} data={modalData} /> : null}
      <Navbar>
        <AutoCompleteContainer />
      </Navbar>
      <div className={styles["main"] + " " + (isDiagram ? styles["diagramOut"] : styles["navOut"])}>
        <div className={styles["desktop"] + " " + styles["bread"]}>
          <GenericBreadCrumbs crumbs={createDesktopCrumbs(false)} />
        </div>
        <div className={styles["mobile"] + " " + styles["bread"]}>
          <GenericBreadCrumbs crumbs={createMobileCrumbs()} />
        </div>
        <div className={styles["header"]}>
          <div className={styles["mobile"]}>
            <BackButton to={headerData.url} text={headerData.mobileBackTitle} />
          </div>
          <div className={styles["desktop"]}>
            <BackButton to={gotoBrandPage()} text={machineData?.v1.machineModel.machineManufacturer.name} />
          </div>
          <div className={styles["header-text"]}>{headerData.title}</div>
          <div className={styles["bookMark"]}>
            <BookMark diagramId={diagramId} />
          </div>
        </div>
        <div className={styles["navigation"]}>
          <DiagramSectionNav Items={sections} />
        </div>
        {diagramLoading ? (
          <Loading />
        ) : (
          <ImageAndHandle windowDim={windowDim} drawerHandleRef={drawerHandleRef} loading={loading} url={diagram?.imageUrl} />
        )}
        <PartsList
          partsListRef={partsListRef}
          items={diagramLineItems}
          onNotesClick={onNotesClick}
          productIdAnchor={productIdAnchor}
          diagramLoading={loading}
        />
      </div>
    </div>
  );
};

const NotesModal = (props: { data: IDiagramLineItem; onExit: () => void }) => {
  const partType = props.data?.products[0]?.categories[0]?.category?.name;
  const partNumber = props.data?.partNumber;
  return (
    <Modal open={!!props.data} onClose={props.onExit}>
      <ModalHeader onClose={props.onExit}>Notes</ModalHeader>
      <div className={styles["partNotes"]}>
        <div className={styles["partinfo"]}>
          {partNumber && <span>Part Number: {props.data?.partNumber}</span>}
          <br />
          {partType && <span>Part Type: {partType}</span>}
        </div>
        <div>{props.data?.comment}</div>
      </div>
    </Modal>
  );
};

const ImageAndHandle = (props: {
  url?: string;
  loading: boolean;
  drawerHandleRef: React.RefObject<HTMLDivElement>;
  windowDim: {
    width: number;
    height: number;
  };
}) => {
  const windowDim = props.windowDim;
  const startHandleRef = React.useRef(250);
  const [animate, setAnimate] = React.useState(false);
  const [oldWindowDim, setWidth] = React.useState(windowDim);
  const [imageHeight, setImageHeight] = React.useState(windowDim.width > 992 ? -1 : 250);
  const [isMouseDown, setIsMouseDown] = React.useState(false);
  const startYRef = React.useRef(300);
  const imageHolderRef = React.useRef<HTMLDivElement>(null);
  const didMount = React.useRef(false);
  const drawerHandleRef = props.drawerHandleRef;

  const recalculateImageHeight = useCallback(() => {
    //get current viewable area. this value shifts with the iOS dynamic address bar.
    const windowInnerHeight = window.innerHeight;
    const drawerHandleBoundingClientRect = drawerHandleRef.current?.getBoundingClientRect();
    const imageHolderBoundingClientRect = imageHolderRef.current?.getBoundingClientRect();

    if (drawerHandleBoundingClientRect === undefined || imageHolderBoundingClientRect === undefined) return;
    //this moves the position of the drawerHandleRef to the top of the viewport by way of the size of the imageHolderRef.
    else if (drawerHandleBoundingClientRect.bottom > windowInnerHeight) {
      //get the top most part, on the "y" axis of the document, of the image holder ref to calculate what the image height should be.
      const imageHeight = Math.abs(windowInnerHeight - imageHolderBoundingClientRect.top - drawerHandleBoundingClientRect.height);
      //the distance from the top of the image holder to the bottom of the drawer handle is the height of the image.
      setImageHeight(imageHeight);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useLayoutEffect(() => {
    // Add event listener to track window size changes
    window.addEventListener("resize", recalculateImageHeight);
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", recalculateImageHeight);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (didMount.current) recalculateImageHeight();
    else didMount.current = true;
  }, [imageHeight, recalculateImageHeight]);

  useEffect(() => {
    const diff = 2;
    if (windowDim.width > oldWindowDim.width + diff || windowDim.width < oldWindowDim.width - diff) {
      setImageHeight(windowDim.width > 992 ? -1 : 250);
      setWidth(windowDim);
    }
  }, [windowDim, oldWindowDim, imageHeight]);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isMouseDown) {
        var diff = e.clientY - startYRef.current;
        var tempHandle = startHandleRef.current + diff;
        setImageHeight(tempHandle < 0 ? 0 : tempHandle);
      }
    };

    const handleMouseUp = (e: MouseEvent) => {
      setAnimate(true);
      setIsMouseDown(false);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isMouseDown, imageHeight]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnimate(false);
    setIsMouseDown(true);
    startHandleRef.current = imageHeight;
    startYRef.current = e.clientY;
  };

  const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setAnimate(false);
    startHandleRef.current = imageHeight;
    startYRef.current = e.touches[0].clientY;
  };

  const onTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    var diff = e.touches[0].clientY - startYRef.current;
    var tempHandle = startHandleRef.current + diff;
    setImageHeight(tempHandle < 0 ? 0 : tempHandle);
  };

  const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    setAnimate(true);
  };

  return (
    <>
      <div ref={imageHolderRef} className={styles["imageHolder"]}>
        <ImageZoomer
          loading={props.loading}
          image={props.url}
          animate={animate}
          height={imageHeight === -1 ? `100%` : `${imageHeight}px`}
        />
      </div>
      <div
        ref={drawerHandleRef}
        className={styles["partsDrawerTop"]}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onMouseDown={onMouseDown}
      >
        <div className={styles["partsDrawerHandle"]}></div>
      </div>
    </>
  );
};

export default DiagramPage;
