import { GraphQLClient, GraphQLContext, IGraphQLContext } from "@shane32/graphql";
import { AuthContainer, Authentication } from "@zboxglobal/zboxauth";
import "react-app-polyfill/ie11"; //must be first line in index.tsx
import * as ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { BraintreeContainer } from "./contexts/BraintreeContext";
import { CartContainer } from "./contexts/CartContext";
import { FleetContainer } from "./contexts/FleetContext";
import { GlobalContextController } from "./contexts/GlobalContext";
import { PendingOperationsContext } from "./contexts/PendingOperationsContext";
import { RefContextStore } from "./contexts/RefContextStore";
import { RulerController } from "./contexts/RulerContext";
import { AdminBaseUrl, ApiBaseUrl, GraphQlBaseUrl, InitializeAuthListener } from "./globalVariables";
import "./index.css";

//polyfills
if (!Array.prototype.forEach) Array.prototype.forEach = Array.prototype.map; // eslint-disable-line no-extend-native
if (!String.prototype.startsWith)
  // eslint-disable-next-line no-extend-native
  String.prototype.startsWith = function (search) {
    return this.substring(0, search.length) === search;
  };
if (!Array.prototype.find)
  // eslint-disable-next-line no-extend-native
  Array.prototype.find = function (predicate: any) {
    return this.filter(predicate)[0];
  };
if (!Object.values)
  // eslint-disable-next-line no-extend-native
  Object.values = function (obj: any) {
    return Object.keys(obj).map((key) => obj[key]);
  };
if (!Promise.prototype.finally)
  //note: this implementation of 'finally' masks the error instead of returning it
  // eslint-disable-next-line no-extend-native
  Promise.prototype.finally = function (action) {
    return this.then(action, action);
  };

const auth = new Authentication({
  baseUrl: ApiBaseUrl + "api/jwtauth",
  adminUrl: AdminBaseUrl + "api/jwtauth",
  clientId: "42aca6a5-e7e1-4fd0-a2a4-b8b4c09dd946",
});
auth.TryAutoLogin();

const client = new GraphQLClient({
  url: ApiBaseUrl + GraphQlBaseUrl,
  asForm: true,
  transformRequest: (config) => {
    return auth.UpdateToken().then(() => {
      const token = auth.GetAccessToken(); //null if not logged in
      if (token) {
        return {
          ...config,
          url: ApiBaseUrl + GraphQlBaseUrl + "?authorization=" + encodeURIComponent(token),
        };
      } else {
        return config;
      }
    });
  },
});
const clientNoAuth = new GraphQLClient({
  url: ApiBaseUrl + GraphQlBaseUrl,
  asForm: true,
});
const context: IGraphQLContext = {
  client: client,
  guest: clientNoAuth,
};

InitializeAuthListener(auth, client);

ReactDOM.render(
  <GraphQLContext.Provider value={context}>
    <AuthContainer client={auth}>
      <PendingOperationsContext.Provider
        value={{
          anyPendingOperations: () =>
            auth.GetPendingOperations() > 0 || client.GetPendingRequests() > 0 || clientNoAuth.GetPendingRequests() > 0,
        }}
      >
        <RefContextStore>
          <CartContainer>
            <FleetContainer>
              <BraintreeContainer>
                <BrowserRouter>
                  <RulerController>
                    <GlobalContextController>
                      <App />
                    </GlobalContextController>
                  </RulerController>
                </BrowserRouter>
              </BraintreeContainer>
            </FleetContainer>
          </CartContainer>
        </RefContextStore>
      </PendingOperationsContext.Provider>
    </AuthContainer>
  </GraphQLContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
