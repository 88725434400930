import { Link } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import styles from "./NotFound.module.scss";

const NotFound = () => {
  return (
    <div className={styles.container}>
      <div className={styles.crane} />
      <div>
        <p>Error 404 - Page not found.</p>
        <Link to={GenerateLink.ForSearchProduct()}>
          <button className="anchor-btn">Take me home.</button>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
