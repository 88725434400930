import { ICheckoutPage } from "../contexts/CheckoutContext";
import QueryString from "./querystring";

export default class GenerateLink {
  static ForHome = () => "/";
  static ForAllCategory = () => "/category";
  static ForCategory(id: string, seoCategory: string | null) {
    if (!seoCategory) seoCategory = "category";
    return `/${seoCategory}/c/${encodeURIComponent(id + "")}`;
  }
  static ForSearchCategory(id: string, seoCategory: string | null) {
    if (seoCategory === undefined) seoCategory = "search";
    return `/${seoCategory}/s/c/${encodeURIComponent(id + "")}`;
  }
  static ForProduct(id: string, seoProduct: string | null, searchString?: string) {
    if (!seoProduct) seoProduct = "product";
    return `/${seoProduct}/p/${encodeURIComponent(id + "")}${searchString ? `?search=${encodeURIComponent(searchString)}` : ""}`;
  }
  static ForSearchZboxProducts = () => `/search?${process.env.REACT_APP_ALGOLIA_MAIN_INDEX}[refinementList][brand][0]=Zbox`;
  static ForSearchProduct = () => `/search`;
  static ForSearch = (query: string) => `/${encodeURI(`search?${process.env.REACT_APP_ALGOLIA_MAIN_INDEX}[query]=${query}`)}`;
  static ForMobileAutoCompleteSearch = (search?: string) => (search ? `/searchSuggest/${search}` : `/searchSuggest`);
  static ForSignIn = (redirectPath?: string) => ({
    pathname: "/signin",
    search:
      !redirectPath && window.location.pathname === "/signin"
        ? window.location.search
        : QueryString.stringify({
            p: redirectPath || window.location.pathname + window.location.search,
          }),
  });
  static ForAccount = () => "/account";
  static ForTeam = () => "/team";
  static ForCareer = (id: string) => `/career/${id}`;
  static ForAbout = () => "/about";
  static ForSellWithUs = () => "/sellwithus";
  static ForVendor = () => "/vendor";
  static ForAccountSecurity = () => "/account/security";
  static ForSecurityEdit(linkType: "Name" | "Email" | "Password" | "Phone" | "Standard") {
    switch (linkType) {
      case "Name":
        return "/account/security/edit/name";
      case "Email":
        return "/account/security/edit/emailaddress";
      case "Password":
        return "/account/security/edit/password";
      case "Phone":
        return "/account/security/edit/phonenumber";
      case "Standard":
      default:
        return "/account/security/edit";
    }
  }
  static ForMyFleet = () => "/MyFleet";
  static ForOrders = () => "/account/orders";
  static ForFilteredOrders = (filter: string) => `/account/orders/${filter}`;
  static ForOrderDetails = (id: string) => `/account/order/${id}`;
  static ForMachinePage = (machineModelId: string) => `/m/${machineModelId}`;

  static ForMyMachinePage = (machineModelId: string, garageId: string) => `/m/${machineModelId}/mm/${garageId}`;

  static ForDiagramBrand = (manufacturerId: string) => `/partsbooks/brand/${manufacturerId}`;
  static ForDiagramModel = (modelId: string) => `/partsbooks/m/${modelId}`;
  static ForDiagram = (modelId: string, diagramId: string, seoDiagram: string) =>
    `/${seoDiagram}/m/${modelId}/${diagramId}/diagram?diagramId=${diagramId}`;
  static ForDiagramSection = (
    modelId: string,
    sectionId: string,
    diagramId: string | null,
    seoDiagram: string | null,
    productIdAnchor: string | null
  ) =>
    `/${seoDiagram}/m/${modelId}?sectionId=${sectionId}${diagramId ? `&diagramId=${diagramId}` : ""}${
      productIdAnchor ? `&productIdAnchor=${productIdAnchor}` : ""
    }`;
  static ForSection = (modelId: string, sectionId: string, seoDiagram: string) => `/${seoDiagram}/m/${modelId}?sectionId=${sectionId}`;
  static ForPartsBooks = () => "/partsbooks";

  static ForInventoryWebApp = (sku: string) => `${process.env.REACT_APP_INVENTORY_WEB_APP_URL}/inventory/SummaryBySku?sku=${sku}`;

  static ForMachineEditPage = (id: string) => `/m/${id}/edit`;
  static ForRma = (id?: string) => (id !== undefined ? `/account/rma/${id}` : "/account/rma");
  static ForCancelOrder = (id?: string) => (id !== undefined ? `/account/cancel/${id}` : "/account/cancel");
  static ForTrackPackage = (id: string) => `/account/track/${id}`;
  static ForLeaveFeedback = (id?: string) => (id !== undefined ? `/account/feedback/${id}` : "/account/feedback");
  static ForWriteReview = (id: string) => `/account/reviews?productId=${encodeURIComponent(id)}`;
  static ForAllCustomerReviews = (id: string) => `/product/reviews?productId=${encodeURIComponent(id)}`;
  static ForEmailNotifications = () => "/account/notifications";
  static ForPaymentMethods = (id?: string, isDelete?: boolean) =>
    id ? (isDelete ? `/account/paymentmethods/${id}/delete` : `/account/paymentmethods/${id}`) : "/account/paymentmethods";
  static ForPaymentMethodAdd = () => "/account/paymentmethods/0/add";
  static ForAddressBookAdd = () => "/account/addressbook/0/add";
  static ForAddressBook = (id?: string, isDelete?: boolean) =>
    id ? (isDelete ? `/account/addressbook/${id}/delete` : `/account/addressbook/${id}`) : "/account/addressbook";
  static ForCheckout(linkType: ICheckoutPage, id?: string, email?: string, addressId?: string, paymentId?: string) {
    let url: string;
    switch (linkType) {
      case "SIGNIN":
        url = "/checkout/signin";
        break;
      case "CHANGE_EMAIL":
        url = "/checkout/changeemail";
        break;
      case "ORDER_REVIEW":
        url = "/checkout/orderreview";
        break;
      case "PAYMENT_DETAILS_ADD":
        url = "/checkout/payment/add";
        break;
      case "PAYMENT_DETAILS_EDIT":
        url = `/checkout/payment/edit/${id}`;
        break;
      case "PAYMENT_LIST":
        url = "/checkout/payment";
        break;
      case "SHIPPING_DETAILS_ADD":
        url = "/checkout/shipping/add";
        break;
      case "SHIPPING_DETAILS_EDIT":
        url = `/checkout/shipping/edit/${id}`;
        break;
      case "SHIPPING_LIST":
        url = "/checkout/shipping";
        break;
      case "THANK_YOU":
        return `/checkout/thankyou/${id}`;
      case "INITIALIZER":
      default:
        url = "/checkout/initializer";
        break;
    }
    return (
      url +
      QueryString.stringify({
        e: email,
        a: addressId,
        p: paymentId,
      })
    );
  }
  static ForFleet = (isAdd?: boolean) => (isAdd ? `/fleet/add` : `/fleet`);
  static ForFleetRig(id: string, linkType?: "DELETE" | "SELL" | "EDIT") {
    let pathName: string;
    if (id !== undefined) {
      linkType === "DELETE"
        ? (pathName = `/fleet/rig/${id}/delete`)
        : linkType === "EDIT"
        ? (pathName = `/fleet/rig/${id}/edit`)
        : linkType === "SELL"
        ? (pathName = `/fleet/rig/:rigId/sell`)
        : (pathName = `/fleet/rig/${id}`);
    } else {
      pathName = `/fleet`;
    }
    return pathName;
  }
  static ForFleetRigListings = () => "/fleet/rigslistings";
  static ForCart = () => "/cart";
  static ForHelp = () => "/help";
  static ForHelpOrders = () => "/help/orders";
  static ForHelpPayment = () => "/help/paymentsettings";
  static ForHelpReturns = () => "/help/returnsandrefunds";
  static ForHelpShipping = () => "/help/shippingpolicies";
  static ForHelpSitePolicies = () => "/help/sitepolicies";
  static ForHelpOther = () => "/help/other";
  static ForHelpContact = () => "/help/contactus";
  static ForHelpFaq = () => "/help/faq";
  static ForDocumentation = (page?: string) => `/documentation${page ? `/${page}` : ""}`;
  static ForUnimpersonate = () => "/admin/impersonate/none";
  static ForReturns = () => "/extreturns";
}
