import { NavLink } from "react-router-dom";
import GenerateLink from "../../helpers/GenerateLink";
import styles from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <span>About Us</span>
        <NavLink to={GenerateLink.ForAbout()} className="b1m">
          About Zbox
        </NavLink>
        <NavLink to={GenerateLink.ForTeam()} className="b1m">
          Meet The Team
        </NavLink>
        <NavLink to={GenerateLink.ForHelpContact()} className="b1m">
          Contact Us
        </NavLink>
      </div>
      <div className={styles.column}>
        <span>Policies</span>
        <NavLink to={GenerateLink.ForHelpSitePolicies()} className="b1m">
          Privacy Policy
        </NavLink>
        <NavLink to={GenerateLink.ForHelpReturns()} className="b1m">
          Return Policy
        </NavLink>
        {/*
                    <Link to={GenerateLink.ForHelp()} className="b1m">Seller Policy</Link>
                    <Link to={GenerateLink.ForHelpSitePolicies()} className="b1m">Terms of Service</Link>
                */}
      </div>
      <div className={styles.column}>
        <span>Sellers</span>
        <NavLink to={GenerateLink.ForSellWithUs()} className="b1m">
          Sell With Us
        </NavLink>
        <NavLink to={GenerateLink.ForVendor()} className="b1m">
          Our Process
        </NavLink>
        {/*
                    <div className="b1m" style={{opacity: 0.2}}>{BuildDate.toLocaleDateString()}</div>
                    <div className="b1m" style={{ opacity: 0.2 }}>{BuildDate.toLocaleTimeString()}</div>
                    <Link to='' className="b1m">Placeholder</Link>
                    <Link to='' className="b1m">Placeholder</Link>
                */}
      </div>
      <div className={styles["media-boxes"]}>
        <a href="https://www.facebook.com/zboxparts" target="_blank" rel="noopener noreferrer" className="image-facebook">
          <span>Facebook</span>
        </a>
        <a href="https://www.linkedin.com/company/zbox.com" target="_blank" rel="noopener noreferrer" className="image-linkedin">
          <span>LinkedIn</span>
        </a>
        <a
          href="https://www.glassdoor.com/Overview/Working-at-Zbox-com-EI_IE2003458.11,19.htm"
          target="_blank"
          rel="noopener noreferrer"
          className="image-glassdoor"
        >
          <span>Glassdoor</span>
        </a>
      </div>
    </div>
  );
};

export default Footer;
