import * as React from "react";

export interface ICheckboxGroup {
  name?: string;
  values?: string[];
  required?: boolean;
  onChange?: (checked: boolean, value: string) => void;
  removeValidation?: (checked: boolean, value: string) => void;
  displayValidation?: boolean;
}

export const CheckboxContainerContext = React.createContext({} as ICheckboxGroup);
