import { gql } from "@shane32/graphql";
import { IPriceAndAvailability } from "./ProductPageQuery";
import { IShoppingCartShippingMethod } from "./ShoppingCartShippingMethodsQuery";

export const ShoppingCartPartialQuery = (returnShippingMethods?: boolean) => `{
        destination {
          city
          countryId
          postalCode
          provinceId
        }
        errors
        estimatedItemTaxRate
        estimatedShippingTaxRate
        itemCount
        poNumber
        warnings
        productGroups {
          shippingProfileId
          vendorId
          warehouseId
          backordered
          items {
            id
            quantity
            reference
            backordered
            product {
              id
              manufacturerPartNumber
              name
              seoName
              picture {
                thumbnail(size: 100) {
                  url
                }
              }
              vendor {
                name
              }
              manufacturers {
                manufacturer {
                  name
                }
              }
              priceAndAvailability {
                allowedQuantities
                backorderAvailabilityRange
                canNotify
                oldPrice
                orderMaximumQuantity
                orderMinimumQuantity
                preorderAvailabilityStartDateTime
                price
                status
                stock
              }
              sku
              isTaxExempt
            }
          }
          ${
            returnShippingMethods
              ? `shippingMethods (countryId: $countryId, provinceId: $stateProvinceId, postalCode: $postalCode) {
            eta
            id
            name
            price
            selected
          }`
              : ``
          }
        }
        savedForLaterItems {
          id
          product {
              id
              manufacturerPartNumber
              name
              seoName
              picture {
                thumbnail(size: 100) {
                  url
                }
              }
              vendor {
                name
              }
              manufacturers {
                manufacturer {
                  name
                }
              }
              priceAndAvailability {
                allowedQuantities
                backorderAvailabilityRange
                canNotify
                oldPrice
                orderMaximumQuantity
                orderMinimumQuantity
                preorderAvailabilityStartDateTime
                price
                status
                stock
              }
              sku
              isTaxExempt
          }
        }
      }`;

export const ShoppingCartQuery = gql`
{
  v1 {
    my {
      shoppingCart ${ShoppingCartPartialQuery()}
    }
  }
}`;

export interface IShoppingCartProduct {
  id: string;
  manufacturerPartNumber: string | null;
  name: string;
  seoName: string | null;
  picture: {
    thumbnail: {
      url: string;
    };
  };
  vendor: {
    name: string;
  };
  manufacturers: {
    manufacturer: {
      name: string;
    };
  }[];
  priceAndAvailability: IPriceAndAvailability;
  sku: string | null;
  isTaxExempt: boolean;
}

export interface IShoppingCartItem {
  id: string;
  quantity: number;
  reference: string | null;
  backordered: boolean;
  product: IShoppingCartProduct;
}

export interface IShoppingCartShipment {
  shippingProfileId: string;
  vendorId: string;
  warehouseId: string;
  backordered: boolean;
  items: IShoppingCartItem[];
}

export type IShoppingCartShipmentWithShippingMethods = IShoppingCartShipment & {
  shippingMethods: IShoppingCartShippingMethod[];
};

export interface IShoppingCart {
  destination: IShoppingCartDestination;
  errors: string[];
  estimatedItemTaxRate: number | null;
  estimatedShippingTaxRate: number | null;
  itemCount: number;
  poNumber: string | null;
  warnings: string[];
  productGroups: IShoppingCartShipment[];
  savedForLaterItems: IShoppingCartItem[];
}

export type IShoppingCartWithShippingMethods = Omit<IShoppingCart, "productGroups"> & {
  productGroups: IShoppingCartShipmentWithShippingMethods[];
};

export interface IShoppingCartDestination {
  city: string | null;
  countryId: string;
  postalCode: string | null;
  provinceId: string | null;
}

export interface IShoppingCartQuery {
  v1: {
    my: {
      shoppingCart: IShoppingCart;
    } | null;
  };
}
